button{
    padding: 0;
    border: none;
    background: none;
}

@property --gradient-ang {
    syntax: '<angle>';
    inherits: false;
    initial-value: 10deg;
}

@property --gradient-color-1 {
    syntax: '<color>';
    inherits: false;
    initial-value: #1D5E84;
}

@property --gradient-color-2 {
    syntax: '<color>';
    inherits: false;
    initial-value: #3D88B4;
}

@property --gradient-color-3 {
    syntax: '<color>';
    inherits: false;
    initial-value: #4BE29A;
}

.btn{
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.24px;
    overflow: hidden;
    
    &--primary{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 72px;
        padding: 23px 50px;
        background: linear-gradient(10deg, #1D5E84 9.69%, #3D88B4 45.44%, #4BE29A 113.68%);
        background: linear-gradient(var(--gradient-ang), var(--gradient-color-1) 9.69%, var(--gradient-color-2) 45.44%, var(--gradient-color-3) 113.68%);
        transition:--gradient-ang 0.2s, --gradient-color-1 0.2s, --gradient-color-2 0.2s, --gradient-color-3 0.2s, box-shadow 0.2s;
        white-space: normal;
        color: $white;
        border-radius: 72px;
        border: none;
        overflow: inherit;
        
        @media (max-width: 992px) {
            padding: 17px 35px;
            font-size: 16px;
            font-weight: 500;
        }

        i{
            width: 25px;
            min-width: 25px;
            height: 25px;
        }

        // background color fallback for older browsers
        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $nordlys-darker-cta;
            z-index: -1;
            border-radius: 1000px;
        }

        &:hover:not(:disabled){
            color: $white;
            box-shadow: 0 0 0pt 2px $green-light-1 inset;
        }

        &:disabled{
            background: $gray-light-3;
            pointer-events: none;
            opacity: 1;
        }

        &:active{
            --gradient-ang: 25deg;
            --gradient-color-1: #0D4C70;
            --gradient-color-2: #146797;
            --gradient-color-3: #16CA73;
        }

        &:focus{
            box-shadow: none;
        }

        &:focus-visible{
            outline: 2px solid black !important;
        }
    }

    &--secondary{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        border: 1px solid $gray-light-3;
        border-radius: 100px;

        &-sm{
            padding: 5px 15px;
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
        }
    }

    &--blue{
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        background-color: $cyan-dark;
        border-radius: 100px;
        padding: 16px 34px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.17px;

        @media (max-width: 992px) {
            padding: 12px 16px;
        }

        &:hover{
            color: $white;
        }
    }

    &--padding-high{
        padding-left: 106px;
        padding-right: 106px;
    }

    &--icon-right{
        i{
            margin-left: 10px;
        }
    }

    &--icon-left{
        i{
            margin-right: 10px;
        }
    }

    &--loading:not(:disabled){
        color: transparent;
        pointer-events: none;

        &:hover{
            color: transparent;
            
            &::after{
                border: none;
            }
        }

        i{
            opacity: 0;
        }

        &::after{
            content: "";
            display: block;
            width: 26px;
            height: 26px;
            background-image: url('./../images/icons/spinner-white.svg');
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            top: calc(50% - 13px);
            left: calc(50% - 13px);
            z-index: 1;
            border: none;

            -webkit-animation: spinnerRotation 2s linear infinite;
            -moz-animation: spinnerRotation 2s linear infinite;
            -ms-animation: spinnerRotation 2s linear infinite;
            -o-animation: spinnerRotation 2s linear infinite;
            animation: spinnerRotation 2s linear infinite;
        }

        @-webkit-keyframes spinnerRotation { /* Safari and Chrome */
            from {
              -webkit-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            
            to {
              -webkit-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
        }

        @keyframes spinnerRotation {
            from {
                -ms-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            to {
                -ms-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
    }

    &--arrow-animation{
        i{
            transition: 0.2s;
        }

        &:hover{
            i{
                -ms-transform: translate(10px,0px); /* IE 9 */
                transform: translate(10px,0px); /* Standard syntax */
            }
        }
    }
}

// Gradient background fallback for inputs
input{
    &.btn--primary{
        background: linear-gradient(10deg, #1D5E84 9.69%, #3D88B4 45.44%, #4BE29A 113.68%);

        &:active{
            background: linear-gradient(25deg, #0D4C70 9.69%, #146797 45.44%, #16CA73 113.68%);
        }
    }
}


// Link with arrow
.arrow-link{
    display: inline-flex;
    align-items: center;
    font-weight: 400;

    i{
        min-width: 16px;
        margin-left: 10px;
        
        &.icon--arrow-right-green-lg{
            min-width: 36px;
        }
    }

    &--gray{
        color: $gray-light-1;
        
        &:hover{
            color: $gray-light-1;
        }
    }

    &--lg{
        font-size: 24px;
        line-height: 29px;
    }

    &--md{
        font-size: 20px;
        line-height: 24px;
    }

    &--sm{
        font-size: 18px;
        line-height: 24px;
    }

    &--icon-left{
        i{
            margin-left: 0;
            margin-right: 10px;
        }
    }

    &--animation-right{
        i{
            transition: 0.2s;
        }

        &:hover{
            i{
                -ms-transform: translate(10px,0px); /* IE 9 */
                transform: translate(10px,0px); /* Standard syntax */
            }
        }
    }

    &--animation-top-right{
        i{
            transition: 0.2s;
        }

        &:hover{
            i{
                -ms-transform: translate(5px,-5px); /* IE 9 */
                transform: translate(5px,-5px); /* Standard syntax */
            }
        }
    }
}

// CTA Link
.cta-link{
    font-weight: 400;
    text-decoration: underline;

    &--sm{
        font-size: 20px;
        line-height: 24px;

        @media (max-width: 992px) {
            font-size: 15px;
        }
    }
}

.clear-filter__wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .btn-clear-filter{
        display: flex;
        display: -webkit-box;
        -webkit-box-orient: horizontal;
        flex-direction: row;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        color: $cyan-dark;

        i{
            margin-right: 10px;
        }
    }
}

.open-mobile-overlay{
    display: none;

    @media (max-width: 992px) {
        display: block;
        margin-bottom: 30px;
        margin-left: auto;
        font-size: 16px;
        font-weight: 600;
        color: $cyan-dark;
    }
}

.go-back-mobile{
    display: none;

    @media (max-width: 992px) {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;
        color: $gray-light-1;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;

        .icon{
            margin-right: 8px;
        }
    }
}