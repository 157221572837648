.text-and-media{
    margin-bottom: 112px;

    @media (max-width: 992px) {
        margin-bottom: 80px;
    }

    .tm__item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 147px;

        @media (max-width: 992px) {
            flex-direction: column;
            justify-content: inherit;
            align-items: inherit;
            margin-bottom: 80px;
        }

        &:last-child{
            margin-bottom: 0;
        }

        .figure-wrapper{
            width: 61%;
            
            @media (max-width: 1440px) {
                width: 50%;
            }

            @media (max-width: 992px) {
                margin-bottom: 35px;
                width: 100%;
            }

            figure{
                border-top-left-radius: 40px;
                border-bottom-left-radius: 40px;

                @media (max-width: 992px) {
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                }

                img{
                    
                }
            }
        }

        header{
            width: 30.5%;

            @media (max-width: 1440px) {
                width: 44%;
            }

            @media (max-width: 992px) {
                width: 100%;
                text-align: right;
            }

            h2{
                margin-bottom: 40px;

                @media (max-width: 1440px) {
                    margin-bottom: 30px;
                    font-size: 54px;
                    line-height: 64px;
                }

                @media (max-width: 992px) {
                    margin-bottom: 26px;
                    font-size: 40px;
                    line-height: 44px;
                    letter-spacing: -0.96px;
                }
            }

            h5{
                margin-bottom: 40px;

                @media (max-width: 1440px) {
                    margin-bottom: 30px;
                    line-height: 38px;
                }

                @media (max-width: 992px) {
                    margin-bottom: 25px;
                    font-size: 20px;
                    line-height: normal;
                }
            }

            a.arrow-link{
                @media (max-width: 992px) {
                    font-size: 16px;
                }
            }
        }

        &--img-right{
            flex-direction: row-reverse;

            @media (max-width: 992px) {
                flex-direction: column;
            }

            .figure-wrapper{
                figure{
                    border-top-right-radius: 40px;
                    border-bottom-right-radius: 40px;
                    border-bottom-left-radius: 0px;
                    border-top-left-radius: 0px;
                }
            }

            header{
                text-align: left;
            }
        }
    }
}