// Popup menu
ul.popup-menu{
    display: none;
    position: absolute;
    width: 252px;
    top: 100%;
    right: 0px;
    background: white;
    text-align: left;
    box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid $gray-light-3;
    z-index: 1;

    &.active{
      display: flex;
      flex-direction: column;
    }

    li{
        font-size: 18px;
        letter-spacing: 0.35px;
        font-weight: 500;

        a{
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 18px;
            letter-spacing: 0.35px;
            font-weight: 500;
            padding: 16px 36px;

            &:hover{
                background-color: rgba($cyan, .1);
            }

            &.color-red{
                &:hover{
                    color: $red;
                }
            }

            i{
              margin-left: 8px;
            }
        }
    }
}

// Custom select
.custom-select {
  width: 100%;
  position: relative;

  select{
    display: none;
  }
  
  .selected-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 17px 0px;
    color: $cyan-dark;
    cursor: pointer;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    border-bottom: 1px solid $gray-light-3;
    z-index: 99;

    &::after{
      content: "";
      width: 17px;
      min-width: 17px;
      height: 10px;
      background-image: url("./../images/icons/chevron-down-black.svg");
      background-size: contain;
      background-repeat: no-repeat;
      transition: 0.1s;
    }

    &.arrowanim.selected-item:after {
      transform: rotate(180deg);
    }
  }

  // .all-items:not(.mobile-overlay.active) {
  // I had to remove the ":not" pseudo-class above to fix a firefox bug (mobile) 

  .all-items{
    position: absolute;
    min-width: 230px;
    max-width: 430px;
    max-height: 400px;
    overflow: auto;
    top: calc(100% - 1px);
    right: 0px;
    background: white;
    text-align: left;
    box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid $gray-light-3;
    z-index: 1;
    
    &.all-items-hide {
      display: none;
    }

    .item {
      position: relative;
      padding: 11px 50px;
      font-size: 18px;
      letter-spacing: 0.35px;
      font-weight: 400;
      cursor: pointer;

      &:hover{
        background-color: rgba($cyan, .1);
      }

      &:first-of-type{
        display: none;
      }
      
      &:nth-of-type(2){
        padding: 16px 50px 11px;
      }

      &:last-child{
        margin-bottom: 0;
        padding: 11px 50px 16px;
      }

      &.selected{
        &::before{
          content: "";
          width: 11px;
          height: 11px;
          display: block;
          position: absolute;
          background: $nordlys-ok;
          border-radius: 100%;
          left: 24px;
          top: calc(50% - 5px);
        }
      }
    }
  }

  &.disabled{
    pointer-events: none;
    
    .selected-item{
      color: $gray-light-2;
    }
  }

  &.no-placeholder{
    .all-items{
      .items__wrapper{
        .item{
          &:first-of-type{
            display: block;
          }
        }
      }
    }
  }
}

// Mobile overlay
.mobile-overlay{
  @media (max-width: 992px) {
    display: none;
  }

  &.active{
    @media (max-width: 992px) {
      display: block;
      position: fixed;
      top: 0px;
      left: 0;
      bottom: 0;
      width: 100vw;
      min-width: 100%;
      max-width: 100%;
      max-height: 100%;
      border: none;
      box-shadow: none;
      background: transparent;
      overflow: auto;
      transition: transform .8s cubic-bezier(0.42, 0, 0.15, 1.4);
      background: rgba(255, 255, 255, 0.85);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      opacity: 1;
      z-index: 10;

      animation-name: popup-slideUp;
      animation-duration: 0.5s;
    }
  }

  &.popup-slideDown{
    // Animations keyframes in dropdown.scss
    animation-name: popup-slideDown;
    animation-duration: 0.4s;
  }

  .row{
    @media (max-width: 992px) {
      display: block;
      height: 100%;
      margin: 0 !important;
      padding: 0 30px 120px;
      overflow: auto;
    }

    .mobile-overlay__header{
      display: none;
  
      @media (max-width: 992px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 47px 0 40px !important;
        
        border-bottom: 1px solid $gray-light-3;
      }
  
      .close-filter{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;

        i{
          width: 20px;
          margin-right: 20px;
        }
      }
  
      .clear{
        font-size: 16px;
        font-weight: 600;
        color: $red;
      }
    }

    .filter-dropdown{
      @media (max-width: 992px) {
        max-width: 100%;
        padding: 0 !important;
        margin-bottom: 0;
      }

      .custom-select{
        .selected-item{
          @media (max-width: 992px) {
            padding: 20px 0;
          }

          &.arrowanim{
            @media (max-width: 992px) {
              border-bottom: none;
            }
          }
        }


        .all-items{
          @media (max-width: 992px) {
            position: relative;
            width: 100%;
            max-width: 100%;
            max-height: inherit;
            padding-bottom: 12px;
            overflow: inherit;
            border: none;
            background: transparent;
            box-shadow: none;
            border-bottom: 1px solid $gray-light-3;
          }

          .item{
            @media (max-width: 992px) {
              padding: 16px 0px 16px 30px;
            }

            &::before{
              @media (max-width: 992px) {
                left: 3px;
              }
            }
          }
        }
      }
    }
  }
}

.all-items{
  &.mobile-overlay{
    .items__wrapper{
      @media (max-width: 992px) {
        display: block;
        height: 100%;
        margin: 0 !important;
        padding: 0 30px 120px;
        overflow: auto;
      }
      
      .btn-close{
        display: none;
    
        @media (max-width: 992px) {
          display: block;
          margin-top: 66px;
          margin-bottom: 27px;
        }
      }
    
      .item{
        @media (max-width: 992px) {
          position: relative;
          padding: 20px 0 20px 30px;
          border-bottom: 1px solid $gray-light-3;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
        }
        
        &.selected{
          @media (max-width: 992px) {
            &::before{
              content: "";
              width: 11px;
              height: 11px;
              display: block;
              position: absolute;
              background: $nordlys-ok;
              border-radius: 100%;
              left: 5px;
              top: calc(50% - 5px);
            }
          }
        }
    
        &:first-of-type{
          @media (max-width: 992px) {
            padding-top: 0;
            color: $cyan-dark;
            font-size: 24px;
            line-height: 34px;
            letter-spacing: -0.5px;
            font-weight: 400;
            pointer-events: none;
          }
        }
      }
    }

    &.active{
      &.all-items-hide{
        @media (max-width: 992px) {
          display: block;

          // Added 'display:block' to cancel the ".all-items-hide" effect
          // the visibility here is controlled by the class ".active"
        }
      }
    }
  }
}

.mobile-overlay{
  .row , .items__wrapper{
    .mobile-overlay__footer{
      display: none;
  
      @media (max-width: 992px) {
        position: fixed;
        flex-direction: row;
        justify-content: center;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        margin-top: auto;
        padding: 20px 0;
        background-color: $white;
        z-index: 1;
      }
      
      &::before{
        @media (max-width: 992px) {
          content: "";
          background: linear-gradient(0deg, #DFDFDF 0%, rgba(255, 255, 255, 0) 33.21%);
          display: block;
          position: absolute;
          width: 100%;
          height: 143px;
          bottom: 100%;
          pointer-events: none;
        }
      }
  
      .cancel{
        margin-left: 26px;
        font-size: 15px;
        font-weight: 400;
        text-decoration: underline;
      }
    }
  }
}

// Animations
@keyframes popup-slideUp {
  from {
      top: 100%;
      opacity: 0.8;
  }
  
  to {
      top: 0;
      opacity: 1;
  }
}

@keyframes popup-slideDown {
  from {
      top: 0;
      opacity: 1;
  }

  to {
      top: 100%;
      opacity: 0;
  }
}