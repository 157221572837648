.partner-page{
    margin-bottom: 136px;

    @media (max-width: 992px) {
        margin-bottom: 80px;
    }

    .page__header{
        margin-top: 136px;
        margin-bottom: 40px;
        padding-bottom: 87px;
        border-bottom: 1px solid $gray-light-3;

        .header-figure{
            text-align: center;

            img{
                width: 100%;
                max-width: 340px;
                max-height: 200px;
                object-fit: contain;
            }
        }

        .tags{
            margin-top: 50px;

            .tag{
                margin-right: 48px;
                margin-bottom: 10px;

                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }

    &__info{
        padding-bottom: 46px;
        margin-bottom: 52px;
        border-bottom: 1px solid $gray-light-3;
    }

    &__about-text{
        margin-bottom: 176px;
    }

    &__capabilities{
        padding-bottom: 176px;

        .content{
            height: 100%;
            margin-left: 60px;
            padding-left: 60px;
            border-left: 1px solid $gray-light-3;
    
            h5{
                margin-bottom: 47px;
            }
    
            .capability{
                margin-bottom: 58px;

                &:last-child{
                    margin-bottom: 0;
                }

                .tags{
                    margin-bottom: 26px;

                    .tag{
    
                    }
                }
    
                .arrow-link{
                    display: block;
                    margin-bottom: 16px;
                }
            }
        }
    }

    &__technologies{
        margin-bottom: 67px;

        .technologies-header{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 40px;
            padding-bottom: 40px;
            border-bottom: 1px solid $gray-light-3;
    
            @media (max-width: 992px) {
                padding-bottom: 34px;
                margin-bottom: 30px;
            }
    
            h3{
                padding-right: 20px;

                @media (max-width: 992px) {
                    padding-right: 20px;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: normal;
                }
            }
    
            .arrow-link{
                white-space: nowrap;

                @media (max-width: 992px) {
                    font-size: 14px;
                }
    
                i{
                    @media (max-width: 992px) {
                        min-width: 16px;
                        width: 16px;
                        height: 18px;
                        background-image: url("./../images/icons/arrow-right-green.svg");
                    }
                }
            }
        }
    }

    &__contact-persons{
        .contact-persons__header{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 40px;
            padding-bottom: 40px;
            border-bottom: 1px solid $gray-light-3;
    
            @media (max-width: 992px) {
                padding-bottom: 34px;
                margin-bottom: 30px;
            }
    
            h3{
                padding-right: 20px;

                @media (max-width: 992px) {
                    padding-right: 20px;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }

        .contact__wrapper{
            margin-bottom: 40px;

            .tag{
                margin-bottom: 18px;
            }

            p.capability{
                margin-bottom: 15px;
            }

            .contact-card{
                header{
                    display: none;
                }
            }
        }
    }
}