// Import Manrope font
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;900&display=swap');

// Titles
h1, h2, h3, h4, h5, h6{
    margin: 0;
}

h1{
    font-size: 80px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -2px;
}

h2{
    font-size: 64px;
    font-weight: 400;
    line-height: 68px;
    letter-spacing: -1.5px;
}

h3{
    font-size: 48px;
    line-height: 52px;
    font-weight: 400;
    letter-spacing: -1px;

    &.line-separator{
        position: relative;
        display: table;
        margin-top: 95px;
        margin-bottom: 35px;
        
        @media (max-width: 992px) {
            margin-top: 56px;
            margin-bottom: 32px;
            font-size: 24px;
            line-height: 34px;
            letter-spacing: -0.5px;
        }

        &::before{
            content: "";
            display: block;
            margin-bottom: 60px;
            width: 100%;
            height: 10px;
            background: $grad-blue;

            @media (max-width: 992px) {
                margin-bottom: 28px;
                height: 5px;
            }
        }

        &--orange{
            &::before{
                background: $grad-orange;
            }
        }
    }
}

h4{
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -0.5px;
    font-weight: 400;
}

h5{
    font-size: 32px;
    line-height: 38px;
    font-weight: 400;
}

h6{
    font-size: 28px;
    line-height: 32px;
    font-weight: 500;
}

// Ingress
.ingress-text p,
.ingress{
    font-size: 28px;
    font-weight: 400;
    line-height: 42px;

    @media (max-width: 992px) {
        font-size: 18px;
        line-height: 28px;
    }

    a{
        color: #036092;
        text-decoration: underline;
    }
}

// Subtitles
.subtitle-3{
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}

// Paragraphs
p{
    margin: 0;
}

.p1{
    font-size: 22px;
    line-height: 40px;
    font-weight: 400;
}

.p2{
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

// Underline animation
a{
    position: relative;
    display: inline;
    hyphens: none;
    
    &.underline-animation{
        position: relative;
        display: inline-flex;
        padding-bottom: 2px;
    
        &::after{
          content: "";
          position: absolute;
          height: 1px;
          top: calc(100% + 1px);
          background: $nordlys-ok;
          left: 1px;
          width: 0px;
          -webkit-transition: all 500ms ease-in-out;
          transition: all 500ms ease-in-out;
        }
    
        &:hover{
    
          &::after{
            width: 100%;
          }
        }
    }
}

.hover-underline{
    &:hover{
        text-decoration: underline;
    }
}