span.filter-title{
    margin-right: 24px;

    @media (max-width: 992px) {
        margin-right: 10px;
    }
}

button{
    &.filter-tech , &.filter-markets{
        margin-right: 24px;
        padding-right: 54px;
        text-align: left;
        letter-spacing: -2px;

        @media (max-width: 992px) {
            margin-right: 10px;
            padding-right: 23px;
            letter-spacing: 0;
        }

        &:not(.active){
            text-transform: lowercase;
        }

        span{
            position: relative;
            color: $gray-light-2;

            &::before{
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 4px;
                background-color: $gray-light-2;
                z-index: -1;

                @media (max-width: 992px) {
                    height: 2px;
                }
            }

            &::after{
                content: "";
                position: absolute;
                right: -54px;
                bottom: 18px;
                display: block;
                width: 32px;
                height: 54px;
                background-image: url('./../images/icons/chevron-right-gray.svg');
                background-repeat: no-repeat;
                background-size: contain;
                vertical-align: middle;
                opacity: 0.8;

                @media (max-width: 992px) {
                    bottom: 8px;
                    right: -34px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        &:hover{
            span{
                background: $nordlys-darker-cta;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                &::before{
                    background: $nordlys-darker-cta;
                }

                &::after{
                    background-image: url('./../images/icons/chevron-right-gradient.svg');
                }
            }
        }

        &.active{
            span{
                background: $nordlys-darker-cta;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                &::before{
                    background: $nordlys-darker-cta;
                }

                &::after{
                    background-image: url('./../images/icons/chevron-right-gradient.svg');
                }
            }
        }
    }
}

.filter-drawer{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    height: 100vh;
    background-color: white;
    box-shadow: -10px 0px 50px rgba(0, 0, 0, 0);
    transition: 0.8s;
    z-index: 10;

    &.active{
        right: 0;
        box-shadow: -10px 0px 50px rgba(0, 0, 0, 0.25);
    }

    .btn-close{
        position: absolute;
        top: 60px;
        left: 60px;

        @media (max-width: 992px) {
            left: 35px;
        }

        i{
            width: 30px;
            height: 30px;

            @media (max-width: 992px) {
                width: 26px;
                height: 26px;
            }
        }
    }

    .content{
        padding: 0px 80px 0px 183px;
        overflow: auto;

        @media (max-width: 992px) {
            padding: 0 32px 0 87px;
        }
    }

    &--tech{
        width: 100%;
        max-width: 810px;
        right: -810px;

        ul{
            padding-top: 40px;
            padding-bottom: 40px;

            li{
                margin-bottom: 40px;

                &:last-child{
                    margin-bottom: 0;
                }

                button{
                    position: relative;
                    font-size: 40px;

                    @media (max-width: 992px) {
                        font-size: 24px;
                        line-height: 35px;
                        letter-spacing: -0.5px;
                    }

                    &:hover{

                        span{
                            background: $nordlys-darker-cta;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }

                        &::after{
                            content: "";
                            position: absolute;
                            left: 0;
                            bottom: 2px;
                            width: 100%;
                            height: 2.5px;
                            background: $nordlys-darker-cta;
                            z-index: -1;
                        }
                    }
                }
            }
        }
    }

    &--markets{
        max-width: 1300px;
        right: -1300px;

        @media (max-width: 768px) {
            width: 100%;
        }

        .content{
            padding-top: 40px;
            padding-bottom: 40px;

            @media (max-width: 1440px) {
                padding-top: 100px;
            }

            .btn-all{
                position: relative;
                font-size: 40px;
                margin-bottom: 30px;

                &:hover{
                    span{
                        background: $nordlys-darker-cta;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    &::after{
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 1px;
                        width: 100%;
                        height: 2px;
                        background: $nordlys-darker-cta;
                        z-index: -1;
                    }
                }
            }

            .filter-drawer__row{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                @media (max-width: 1300px) {
                    flex-direction: column;
                }

                .filter-drawer__col{
                    width: 259px;

                    @media (max-width: 1300px) {
                        width: 100%;
                    }

                    ul{
                        li{
                            width: 100%;
                            margin-bottom: 4px;
                            padding-right: 40px;

                            button{
                                position: relative;
                                font-size: 40px;
                                white-space: nowrap;

                                &:hover{
                                    span{
                                        background: $nordlys-darker-cta;
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                    }

                                    &::after{
                                        content: "";
                                        position: absolute;
                                        left: 0;
                                        bottom: 1px;
                                        width: 100%;
                                        height: 2px;
                                        background: $nordlys-darker-cta;
                                        z-index: -1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
