.next-events-part{
    // margin-top: 96px;

    @media (max-width: 992px) {
        margin-top: 54px;
    }

    .next-events__header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid $gray-light-3;

        @media (max-width: 992px) {
            padding-bottom: 34px;
            margin-bottom: 30px;
        }

        h3{
            @media (max-width: 992px) {
                padding-right: 20px;
                font-size: 22px;
                font-weight: 500;
                line-height: normal;
            }
        }

        .arrow-link{
            @media (max-width: 992px) {
                font-size: 14px;
            }

            i{
                @media (max-width: 992px) {
                    min-width: 16px;
                    width: 16px;
                    height: 18px;
                    background-image: url("./../images/icons/arrow-right-green.svg");
                }
            }
        }
    }

    .next-events__slider{
        position: relative;
        margin-left: calc((100vw - 1680px + 280px)/2);
        max-width: calc(1680px + ((100vw - 1680px - 280px)/2));
        margin-bottom: 124px;

        @media (max-width: 1680px) {
            max-width: calc(100vw - 140px);
            margin-left: 140px;
        }

        @media (max-width: 1200px) {
            max-width: calc(100vw - 30px);
            margin-left: 30px;
        }

        @media (max-width: 992px) {
            margin-bottom: 80px;
        }

        .slick-list{
            padding:0 4% 0 0;

            @media (max-width: 768px) {
                padding:0 8% 0 0;
            }

            .slick-track{
                display: flex;
                flex-direction: row;
                align-items: stretch;
                margin: 0;
            }
        }

        .slide{
            display: flex;
            height: auto;
            margin-right: 60px;

            @media (max-width: 992px) {
                margin-right: 20px;
            }

            a{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;

                &::after{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 10px;
                    margin-top: auto;
                    background: $grad-blue;
                }

                .event-date{
                    margin-bottom: 15px;

                    @media (max-width: 992px) {
                        margin-bottom: 10px;
                    }

                    div{
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 28px;

                        @media (max-width: 992px) {
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }
                }

                h6.event-name{
                    margin-bottom: 25px;
                    hyphens: none;                        -moz-hyphens: none !important;
                    -ms-hyphens: none !important;
                    -webkit-hyphens: none !important;

                    @media (max-width: 992px) {
                        margin-bottom: 18px;
                        font-size: 18px;
                        line-height: 21px;
                    }
                }

                .tags{
									display: flex;
									flex-direction: row;
									flex-wrap: wrap;
									padding-bottom: 24px;

									@media (max-width: 992px) {
										padding-bottom: 0;
									}

                    .tag{
                        margin-right: 16px;
                        margin-bottom: 16px;

                        @media (max-width: 992px) {
                            padding: 4px 10px;
                            font-size: 11px;
                        }

												&:last-child{
													margin-right: 0;
												}
                    }
                }
            }

        }
    }

    &.notSlider{
        .next-events__slider{
            display: flex;
            flex-direction: row;
            max-width: 1680px;
            padding-left: 140px;
            padding-right: 140px;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: 1200px) {
                padding-left: 30px;
                padding-right: 30px;
            }

            @media (max-width: 768px) {
                flex-direction: column;
            }

            .slide{
                width: 33%;

                @media (max-width: 1200px) {
                    margin-right: 20px;
                }

                @media (max-width: 768px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 40px;
                }

                &:last-child{
                    margin-right: 0;

                    @media (max-width: 768px) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &--image{
        .next-events__slider{
            .slick-list{
                .slick-track{
                    .slide{
                        a{
                            &::after{
                                display: none;
                            }

                            figure{
                                width: 100%;
                                margin-bottom: 30px;
                                height: 253px;

                                img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-top-right-radius: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
