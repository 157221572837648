.tech-solutions__wrapper{
    margin-bottom: 63px;
    display: flex;
    
    @media (max-width: 992px) {
        margin-bottom: 35px;
    }

    .tech-solutions{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 52px 42px 45px;
        border: 1px solid $gray-light-3;
        border-top-right-radius: 40px;
    
        @media (max-width: 992px) {
            padding: 25px 25px 35px;
        }

        > a{
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    
        .tag{
            margin-bottom: 15px;
            font-size: 16px;
    
            @media (max-width: 992px) {
                margin-bottom: 10px;
                font-size: 13px;
            }
        }
    
        .capability{
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 22px;
            font-weight: 500;
    
            @media (max-width: 992px) {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 20px;
            }
        }
    
        .separator{
            width: 100%;
            height: 11px;
            margin-bottom: 25px;
            background: linear-gradient(90deg, #006FAB 0%, #72E3AD 100%);
    
            @media (max-width: 992px) {
                margin-bottom: 20px;
            }
        }
    
        .solution{
            margin-bottom: 25px;
            font-size: 25px;
            line-height: 29px;
            font-weight: 400;
    
            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 23px;
            }
        }
    
        .trl{
            margin-top: auto;
            pointer-events: none;

            p{
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                text-transform: uppercase;
                color: black;
                opacity: 0.5;
            }
    
            .rangeslider{
                height: 4px;
                margin-top: 10px;
                box-shadow: none;
                background-color: $so-so-gray;
    
                .rangeslider__fill{
                    background: linear-gradient(146.95deg, #3D88B4 14.21%, #4BE29A 86.87%);
                }
    
                .rangeslider__handle{
                    top: -13px;
                    width: 30px;
                    height: 30px;
                    box-shadow: none;
                    border: none;
                    background: linear-gradient(146.95deg, #3D88B4 14.21%, #4BE29A 86.87%);
    
                    .val{
                        margin-top: 1px;
                        margin-right: 2px;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 28px;
                        text-align: center;
                        color: $white;
                    }
    
                    &::after{
                        display: none;
                    }
                }
            }
    
        }
    
        figure{
            margin-top: 38px;
            padding-top: 17px;
            border-top: 1px solid $gray-light-3;
    
            @media (max-width: 992px) {
                display: none;
            }
    
            img{
                width: 100%;
                max-width: 240px;
                height: 70px;
                object-fit: contain;
            }
        }
    }

    &--list{
        @media (min-width: 993px) {
            display: block;
            margin-bottom: 40px;
            
            .tech-solutions{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 0 35px;

                >a{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    width: 100%;
                }

                .tag-capability{
                    width: 25%;
                    padding: 30px 60px 0 0;
        
                    .capability{
                        margin-bottom: 40px;
                    }
                }
        
                .separator-solution{
                    display: flex;
                    flex-direction: column-reverse;
                    width: 50%;
                    padding-right: 60px;
        
                    .separator{
                        margin-bottom: 0;
                    }
        
                    .solution{
                        margin-bottom: 27px;
                    }
                }
        
                .trl{
                    display: none;
                }
        
                figure{
                    width: 25%;
                    margin: auto 0;
                    padding-top: 0;
                    border: none;
                    text-align: right;
        
                    img{
                        max-width: 190px;
                    }
                }
            }
        }
    }
}