.contact-card{
    max-width: 430px;

    @media (max-width: 992px) {
        margin-top: 10px;
    }

    header{
        margin-bottom: 30px;

        @media (max-width: 992px) {
            margin-bottom: 24px;
        }

        h6{
            @media (max-width: 992px) {
                font-size: 24px;
                line-height: 35px;
                font-weight: 400;
            }
        }
    }

    .content{
        display: flex;
        flex-direction: row;

        @media (max-width: 992px) {
            flex-direction: column;
        }
        
        .img__wrapper{
            margin-right: 30px;
            width: 130px;
            min-width: 130px;
            height: 130px;

            @media (max-width: 992px) {
                margin-right: 0;
                margin-bottom: 24px;
                width: 200px;
                height: 200px;
                min-width: 200px;
            }
            
            figure{
                img{
                    width: 100%;

                    @media (max-width: 992px) {
                        border-top-right-radius: 40px;
                    }
                }
            }
        }
    
        .text-content{
    
            p{
                font-size: 18px;
                line-height: 24px;
                font-weight: 400;

                @media (max-width: 992px) {
                    font-size: 16px;
                    line-height: 26px;
                }
    
                &.name{
                    margin-bottom: 2px;
                    font-weight: 600;
                }
    
                &.info{
                    margin-bottom: 15px;
                }
            }
        }
    }

}