.technologies-solutions-part {
	// margin-top: 135px;
	margin-bottom: 136px;
	padding-block: 108px 115px;
	background: linear-gradient(177.76deg, #D4F6E6 -21.81%, rgba(212, 246, 230, 0) 100.57%);

	@media (max-width: 992px) {
		// margin-top: 82px;
		margin-bottom: 90px;
		padding-block: 48px 34px;
	}

	h2 {
		margin-bottom: 72px;

		@media (max-width: 992px) {
			margin-bottom: 30px;
			font-size: 40px;
			line-height: 44px;
		}
	}

	.content-line {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		&:not(:last-of-type) {
			margin-bottom: 48px;

			@media (max-width: 992px) {
				margin-bottom: 20px;
			}
		}

		.item {
			width: 100%;
			border: 1px solid #E2E4EA;
			border-radius: 2px;
			background-color: #fff;

			@media (max-width: 992px) {
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}


			a {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 21px 33px 21px 31px;

				@media (max-width: 992px) {
					padding: 14px 16px 14px 18px;
				}

				.tech-name {
					font-size: 30px;
					line-height: 30px;

					@media (max-width: 992px) {
						font-size: 20px;
						line-height: 23px;
					}
				}

				img {
					display: block;
					margin-right: 24px;
					height: 58px;
					width: auto;
				}

				i {
					margin-left: auto;
					transition: transform 200ms ease-in-out;
				}

				&:hover {
					i {
						transform: translateX(10px);
					}
				}
			}
		}

		@media (min-width: 993px) {
			&.double {
				.item {
					width: 48%;
				}
			}

			&.triple {
				.item {
					width: 30.4444%;
				}
			}
		}
	}





	// .item{
	//     margin-top: -1px;
	//     border-top: 1px solid $gray-light-3;
	//     border-bottom: 1px solid $gray-light-3;

	//     a{
	//         display: flex;
	//         flex-direction: row;
	//         align-items: center;
	//         padding: 30px 20px 30px 0;

	//         @media (max-width: 992px) {
	//             padding: 25px 10px 25px 0;
	//         }

	//         img{
	//             width: 56px;
	//             height: 70px;
	//             max-height: 70px;
	//             margin-right: 20px;

	//             @media (max-width: 992px) {
	//                 width: 45px;
	//                 height: 58px;
	//                 min-height: 58px;
	//                 margin-right: 12px;
	//             }
	//         }

	//         .tech-name{
	//             font-size: 20px;
	//             font-weight: 400;
	//             line-height: 28.2px;
	//             padding-right: 10px;

	//             @media (max-width: 992px) {
	//                 font-size: 20px;
	//                 line-height: 23.4px;
	//             }
	//         }

	//         i{
	//             margin-left: auto;
	//             transition: 0.2s;
	//         }

	//         &:hover{
	//             i{
	//                 -ms-transform: translate(10px,0px); /* IE 9 */
	//                 transform: translate(10px,0px); /* Standard syntax */
	//             }
	//         }
	//     }
	// }
}
