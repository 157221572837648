.link-highlight{
    display: flex;
    flex-direction: row;
    margin-bottom: 112px;
    padding: 55px 60px 45px;
    background-color: $green-light-3;

    @media (max-width: 992px) {
        flex-direction: column;
        margin-bottom: 64px;
        padding: 32px;
    }

    header{
        min-width: 38%;
        margin-right: 40px;

        @media (max-width: 992px) {
            min-width: 100%;
            margin-right: 0;
        }

        h3{
            @media (max-width: 992px) {
                margin-bottom: 32px;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: -0.5px;
            }
        }
    }

    .content{
        a.arrow-link{
            margin-top: 30px;
        }
    }

    &--no-text{
        flex-wrap: wrap;

        header{
            width: 100%;

            h3{
                @media (max-width: 992px) {
                    margin-bottom: 16px;
                }
            }
        }

        .content{
            a.arrow-link{
                @media (max-width: 992px) {
                    margin-top: 0;
                }
            }
        }
    }
}