// Market Intelligence page
.market-intelligence{
    .page__header{
        margin-top: 140px;
        margin-bottom: 108px;

        @media (max-width: 992px) {
            margin-top: 46px;
            margin-bottom: 60px;
        }

        h1{
            margin-bottom: 62px;
            padding-bottom: 83px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                margin-bottom: 36px;
                padding-bottom: 20px;
                font-size: 36px;
                line-height: 46px;
                letter-spacing: -0.5px;
            }
        }

        .ingress{
            @media (max-width: 992px) {
                margin-bottom: 55px;
            }
        }

        .header__image{
            margin-top: 64px;

            @media (max-width: 992px) {
                margin-top: 54px;
            }

            figure{
                @media (max-width: 992px) {
                    padding-top: calc((200 / 315) * 100%);
                }
            }
        }
    }

    .opportunities-part{
        .opportunities__header{
            @media (max-width: 992px) {
                align-items: center;
            }
            
            h4{
                font-size: 48px;
                line-height: 52px;
                letter-spacing: -1px;

                @media (max-width: 992px) {
                    font-size: 22px;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }
}

// Reports and Opportunities pages
.market-intelligence-reports , .market-intelligence-opportunities{
    margin-bottom: 160px;

    @media (max-width: 992px) {
        margin-bottom: 120px;
    }

		.header__separator {
				margin-bottom: 40px;
				padding-bottom: 60px;
				border-bottom: 1px solid $gray-light-3;

				@media (max-width: 992px) {
					margin-bottom: 25px;
					padding-bottom: 15px;
				}
		}

    .page__header{
        margin-top: 136px;
        margin-bottom: 90px;

        @media (max-width: 992px) {
            margin-top: 46px;
            margin-bottom: 80px;
        }

        h1{
            line-height: 130%;

            @media (max-width: 992px) {
                font-size: 36px;
                line-height: 46px;
                letter-spacing: -0.5px;
            }
        }
    }

    .filter__wrapper{
        margin-bottom: 118px;

        @media (max-width: 992px) {
            margin-bottom: 0;
        }

        .filter-dropdown{
            .custom-select{
                .all-items{
                    width: 100%;
                }
            }
        }

        .filter-result{
            margin-top: 48px;

            @media (max-width: 992px) {
                display: none;
            }

            p{
                color: #6D6D6D;
                font-size: 28px;
                line-height: 32px;
            }
        }

        .filter-view{
            align-items: flex-end;
        }
    }

    .market-intelligence-part{
        .market-intelligence__header{
            display: none;
        }

        .market-intelligence__slider{
            &--list{
                flex-wrap: wrap;
                margin-bottom: -1px;
                
                .slide{
                    position: relative;
                    width: 100% !important;
                    margin-right: 0;
                    padding: 32px 32px 32px 22px;
                    border-bottom: 1px solid $gray-light-3;
                    cursor: pointer;

                    &:first-child{
                        border-top: 1px solid $gray-light-3;
                    }

                    &:hover{
                        background-color: transparentize($green-light-3, 0.6);
    
                        &::before{
                            -ms-transform: translate(10px,0px); /* IE 9 */
                            transform: translate(10px,0px); /* Standard syntax */
                        }
                    }

                    .tag{
                        margin-bottom: 32px;
                        background-color: $white;
                    }

                    h6{
                        margin-bottom: 0 !important;
                    }

                    p{
                        display: none;
                    }

                    .arrow-link{
                        display: none;
                    }

                    &::after{
                        display: none;
                    }

                    &::before{
                        content: "";
                        position: absolute;
                        top: calc(50% - 30px);
                        right: 20px;
                        display: block;
                        width: 60px;
                        height: 60px;
                        background-image: url('./../images/icons/arrow-right-green.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        transition: 0.2s;
                    }
                }
            }

            .slide{
                .report-name{
                    @media (max-width: 992px) {
                        margin-bottom: 16px;
                        font-size: 24px;
                        line-height: 35px;
                        font-weight: 400;
                        letter-spacing: -0.5px;
                    }
                }
            }
        }
        
        &.notSlider{
            .market-intelligence__slider{
                .slide{
                    width: 50%;

                    @media (max-width: 768px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    
    .market-card__wrapper{
        margin-bottom: 76px;
    
        @media (max-width: 992px) {
            margin-bottom: 36px;
        }

        .market-card{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
            padding-bottom: 46px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                padding-bottom: 16px;
            }
        
            .tags{
                margin-bottom: 10px;

                .tag{
                    margin-bottom: 10px;
                    margin-right: 5px;
                }
            }
        
            h6{
                margin-bottom: 20px;

                @media (max-width: 992px) {
                    margin-bottom: 15px;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        
            .arrow-link{
                margin-top: auto;

                @media (max-width: 992px) {
                    font-size: 14px;
                    line-height: 18px;
                }

                .icon{
                    @media (max-width: 992px) {
                        min-width: 26px;
                        width: 26px;
                        height: 18px;
                    }
                }
            }
        }

        &--list{
            margin-bottom: 0;
            cursor: pointer;

            &:first-child{
                .market-card{
                    border-top: 1px solid $gray-light-3;
                }
            }

            &:last-child{
                margin-bottom: 64px;
            }

            &:hover{
                .market-card{
                    background-color: transparentize($green-light-3, 0.6);

                    &::after{
                        -ms-transform: translate(10px,0px); /* IE 9 */
                        transform: translate(10px,0px); /* Standard syntax */
                    }
                }
            }

            .market-card{
                position: relative;
                padding: 32px 32px 32px 22px;

                &::after{
                    content: "";
                    position: absolute;
                    top: calc(50% - 30px);
                    right: 20px;
                    display: block;
                    width: 60px;
                    height: 60px;
                    background-image: url('./../images/icons/arrow-right-green.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    transition: 0.2s;
                }

                .tags{
                    .tag{
                        margin-bottom: 22px;
                        background-color: $white;
                    }
                }

                h6{
                    margin-bottom: 0;
                }

                .arrow-link{
                    display: none;
                }
            }
        }
    }
}

.market-intelligence-reports{
    .filter__wrapper{
        .filter-view{
            @media (max-width: 992px) {
                display: flex;
                margin-top: 28px;
            }

            .btn-view{
                @media (max-width: 992px) {
                    display: none;
                }
            }
        }
    }
}

.market-intelligence-opportunities{
    .open-mobile-overlay{
        @media (max-width: 992px) {
            margin-left: 0;
        }
    }

    .project-list{
        .market-card__wrapper{
            &:first-child{
                .market-card{
                    @media (max-width: 992px) {
                        padding-top: 36px;
                        border-top: 1px solid $gray-light-3;
                    }
                }
            }

            .market-card{
                p{
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 400;
    
                    &.priority{
                        margin-bottom: 8px;
                    }
    
                    &.company{
                        margin-bottom: 20px;
                    }
                }
    
                h6{
                    margin-bottom: 8px;
                }
            }

            &--list{
                .market-card{
                    flex-direction: row;
                    flex-wrap: wrap;

                    .content-group{
                        display: flex;
                        flex-direction: column-reverse;

                        &:nth-child(1){
                            width: 21%;
                            margin-right: 60px;
                        }

                        &:nth-child(2){
                            width: calc(80% - 124px);
                        }

                        .priority{
                            margin-bottom: 0px;
                        }

                        .tags{
                            margin-bottom: 0;

                            .tag{
                                margin-top: 16px;
                                margin-bottom: 0;
                            }
                        }

                        h6{
                            margin-bottom: 0;
                        }

                        .company{
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }
}

// Opportunity page
.market-intelligence-opportunity{
    margin-bottom: 160px;

    @media (max-width: 992px) {
        margin-bottom: 96px;
    }

    .page__header{
        margin-top: 136px;
        margin-bottom: 35px;
        padding-bottom: 56px;
        border-bottom: 1px solid $gray-light-3;

        @media (max-width: 992px) {
            margin-top: 24px;
            margin-bottom: 24px;
            padding-bottom: 12px;
        }

        h1{
            margin-bottom: 30px;

            @media (max-width: 992px) {
                margin-bottom: 24px;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: -0.5px;
            }
        }

        .tags{
            .tag{
                margin-right: 48px;
                margin-bottom: 16px;

                @media (max-width: 992px) {
                    margin-right: 16px;    
                }

                &:last-child{
                    margin-right: 0;
                }
            }
        }

    }

    .page__ingress{
        margin-bottom: 60px;
        padding-bottom: 50px;
        border-bottom: 1px solid $gray-light-3;

        @media (max-width: 992px) {
            margin-bottom: 24px;
            padding-bottom: 24px;
        }

        .ingress-text{
            p{
                font-size: 28px;
                font-weight: 400;
                line-height: 42px;
            
                @media (max-width: 992px) {
                    font-size: 18px;
                    line-height: 28px;
                }

                a{
                    color: #036092;
                    text-decoration: underline;
                }
            }
        }

        .download-button{
            display: flex;
            justify-content: flex-end;

            @media (max-width: 992px) {
                margin-top: 24px;
                justify-content: flex-start;
            }
        }
    }

    &__content{
        .content__wrapper{
            display: block;
        }

        aside{
            @media (max-width: 992px) {
                order: -1;
                margin-bottom: 38px;
            }

            .aside__content{
                max-width: 430px;
                min-height: 700px;
                margin-left: auto;
                padding-left: 60px;
                border-left: 1px solid $gray-light-3;

                @media (max-width: 992px) {
                    max-width: 100%;
                    margin-left: 0;
                    padding-left: 0;
                    border: none;
                    min-height: inherit;
                }
    
                .contact-card{
                    margin-bottom: 32px;

                    @media (max-width: 992px) {
                        margin-bottom: 24px;
                    }
                }
    
                .opportunity-info{                
                    .item{
                        margin-bottom: 60px;

                        @media (max-width: 992px) {
                            margin-bottom: 24px;
                        }
    
                        &:last-child{
                            margin-bottom: 0;
                        }
    
                        h6{
                            margin-bottom: 15px;
                            font-size: 21px;
                            line-height: 26px;
                            font-weight: 400;
                            
                            @media (max-width: 992px) {
                                margin-bottom: 6px;
                                font-size: 16px;
                                line-height: 26px;
                            }
                        }
    
                        p{
                            font-size: 28px;
                            line-height: 26px;

                            @media (max-width: 992px) {
                                font-size: 20px;
                                line-height: normal;
                            }
                        }
                    }
                }
            }
        }
    }
}