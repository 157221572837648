.search-results {
	margin-bottom: 83px;

	@media (max-width: 992px) {
		margin-bottom: 70px;
	}

	.breadcrumbs__wrapper {
		margin-top: 24px;
	}

	&__separator {
		border: none;
		border-bottom: 1px solid $gray-light-3;
		margin: 36px 0 40px;

		@media (max-width: 992px) {
			margin: 70px 0 24px;
		}
	}

	&__header {
		margin-top: 136px;
		margin-bottom: 80px;

		@media (max-width: 992px) {
			margin-top: 40px;
			margin-bottom: 32px;
		}

		h1 {
			@media (max-width: 992px) {
				font-size: 36px;
				line-height: 46px;
			}
		}
	}

	.search-form__wrapper {
		margin-bottom: 67px;


		@media (max-width: 992px) {
			margin-bottom: 24px;
		}
	}

	&__tags {
		margin-bottom: 40px;

		@media (max-width: 992px) {
			margin-bottom: 24px;
		}

		h6 {
			margin-bottom: 50px;
			color: $gray-light-1;

			@media (max-width: 992px) {
				margin-bottom: 24px;
				font-size: 18px;
				line-height: 28px;
				font-weight: 400;
			}
		}

		&>.tag-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;
			gap: 32px;
			padding-bottom: 52px;
			border-bottom: 1px solid $gray-light-3;

			@media (max-width: 992px) {
				gap: 24px 16px;
				padding-bottom: 32px;
			}

			a.tag {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 4px;
				background-color: $green-light-3;
				border: none;
				font-size: 16px;
				line-height: 20px;
				padding: 10px 10px 10px 16px;

				@media (max-width: 992px) {
					padding: 8px 12px 8px 15px;
				}

				&:hover i {
					transform: translateY(5px);
				}

				i {
					transition: transform 150ms ease-in-out;

					@media (max-width: 992px) {
						min-width: 18px;
						width: 18px;
						height: 18px;
					}
				}
			}
		}

		// ul{
		//     border-bottom: 1px solid $gray-light-3;

		//     @media (max-width: 992px) {
		//         padding-bottom: 32px;
		//     }

		//     li.tag{
		//         margin-right: 35px;
		//         margin-bottom: 50px;
		//         padding: 10px 20px;
		//         font-size: 18px;
		//         line-height: 24px;
		//         font-weight: 400;

		//         &:last-child{
		//             margin-right: 0;
		//         }

		//         @media (max-width: 992px) {
		//             margin-right: 16px;
		//             margin-bottom: 24px;
		//             padding: 8px 15px;
		//             font-size: 16px;
		//             line-height: 20px;
		//         }
		//     }
		// }
	}

	.technologies__cards {
		margin-bottom: 40px;
		border-bottom: 1px solid $gray-light-3;

		@media (max-width: 992px) {
			margin-bottom: 24px;
		}

		>header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 50px;

			@media (max-width: 992px) {
				margin-bottom: 24px;
			}

			h4 {
				padding-right: 30px;

				@media (max-width: 992px) {
					font-size: 24px;
					line-height: 34px;
					letter-spacing: -0.5px;
				}
			}

			.arrow-link {
				@media (max-width: 992px) {
					display: none;
				}
			}
		}

		.view-all-mobile {
			display: none;

			@media (max-width: 992px) {
				display: block;
				margin-bottom: 56px;
				text-align: right;
			}
		}
	}

	.report-list {
		.report-list__header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 50px;
			padding-bottom: 0;
			border-bottom: none;

			@media (max-width: 992px) {
				margin-bottom: 24px;
			}

			h3 {
				font-size: 36px;
				line-height: 46px;
				letter-spacing: -0.5px;
				font-weight: 400;

				@media (max-width: 992px) {
					font-size: 24px;
					line-height: 34px;
					letter-spacing: -0.5px;
				}
			}

			.arrow-link {
				@media (max-width: 768px) {
					display: none;
				}
			}
		}

		.report__wrapper {
			display: flex;
			margin-bottom: 64px;

			@media (max-width: 768px) {
				margin-bottom: 24px;
			}

			.report {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 100%;
				height: auto;

				&::after {
					content: "";
					display: block;
					width: 100%;
					height: 10px;
					margin-top: 40px;
					background: $grad-orange;

					@media (max-width: 992px) {
						margin-top: 0;
						height: 7px;
					}
				}

				.tag {
					margin-bottom: 20px;

					@media (max-width: 992px) {
						margin-bottom: 13px;
						padding: 4px 10px;
						font-size: 11px;
					}
				}

				h6.report-name {
					margin-bottom: 20px;
					hyphens: none;
					-moz-hyphens: none !important;
					-ms-hyphens: none !important;
					-webkit-hyphens: none !important;

					@media (max-width: 992px) {
						margin-bottom: 13px;
						font-size: 18px;
						line-height: 21px;
					}
				}

				p {
					margin-bottom: 42px;
					font-size: 24px;
					line-height: 34px;
				}

				a.arrow-link {
					margin-top: auto;

					@media (max-width: 992px) {
						margin-bottom: 17px;
						font-size: 14px;

						i {
							@media (max-width: 992px) {
								min-width: 18px;
								width: 18px;
								height: 18px;
								margin-top: 4px;
							}
						}
					}
				}
			}
		}

		.btn-view-all {
			display: none;

			@media (max-width: 768px) {
				display: block;
				text-align: right;
			}
		}
	}

	.opportunities-list {
		&__header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 50px;

			@media (max-width: 992px) {
				margin-bottom: 24px;
			}

			h4 {
				@media (max-width: 992px) {
					font-size: 24px;
					line-height: 34px;
					letter-spacing: -0.5px;
				}
			}

			.arrow-link {
				@media (max-width: 768px) {
					display: none;
				}
			}
		}

		.opportunity__wrapper {
			display: flex;
			margin-bottom: 64px;

			@media (max-width: 768px) {
				margin-bottom: 24px;
			}

			.opportunity {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 100%;
				height: auto;

				&::after {
					content: "";
					display: block;
					width: 100%;
					height: 10px;
					margin-top: 40px;
					background: $grad-blue;

					@media (max-width: 992px) {
						margin-top: 0;
						height: 7px;
					}
				}

				ul.tags {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;

					@media (max-width: 992px) {
						margin-bottom: 6px;
						font-size: 11px;
					}

					li {
						margin-right: 10px;
						margin-bottom: 20px;

						@media (max-width: 992px) {
							margin-bottom: 10px;
						}
					}
				}

				h6.opportunity-name {
					margin-bottom: 20px;
					hyphens: none;
					-moz-hyphens: none !important;
					-ms-hyphens: none !important;
					-webkit-hyphens: none !important;

					@media (max-width: 992px) {
						margin-bottom: 13px;
						font-size: 18px;
						line-height: 21px;
					}
				}

				p {
					margin-bottom: 20px;
					font-size: 22px;
					font-weight: 400;
					line-height: 28px;

					@media (max-width: 992px) {
						margin-bottom: 16px;
					}
				}

				a.arrow-link {
					margin-top: auto;

					@media (max-width: 992px) {
						margin-bottom: 16px;
						font-size: 14px;

						i {
							@media (max-width: 992px) {
								min-width: 18px;
								width: 18px;
								height: 18px;
								margin-top: 4px;
							}
						}
					}
				}
			}
		}

		.btn-view-all {
			display: none;

			@media (max-width: 768px) {
				display: block;
				text-align: right;
			}
		}
	}

	.event-list {
		@media (max-width: 992px) {
			margin-top: 30px;
		}

		.event-list__header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 50px;
			padding-bottom: 0;
			border-bottom: none;

			@media (max-width: 992px) {
				margin-bottom: 24px;
			}

			h4 {
				@media (max-width: 992px) {
					font-size: 24px;
					line-height: 34px;
					letter-spacing: -0.5px;
				}
			}

			.arrow-link {
				@media (max-width: 768px) {
					display: none;
				}
			}
		}

		.event-wrapper {
			display: flex;
			margin-bottom: 64px;

			@media (max-width: 768px) {
				margin-bottom: 24px;
			}

			.event {
				display: flex;
				width: 100%;
				height: auto;

				@media (max-width: 768px) {
					padding-bottom: 24px;
					border-bottom: 1px solid $gray-light-3;
				}

				a {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					width: 100%;

					&::after {
						content: "";
						display: block;
						width: 100%;
						height: 10px;
						margin-top: auto;
						background: $grad-blue;
					}

					figure {
						width: 100%;
						margin-bottom: 30px;
						height: 253px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-top-right-radius: 40px;
						}
					}

					.event-date {
						margin-bottom: 15px;

						@media (max-width: 992px) {
							margin-bottom: 10px;
						}

						div {
							font-size: 22px;
							font-weight: 400;
							line-height: 28px;

							@media (max-width: 992px) {
								font-size: 14px;
								line-height: 18px;
							}
						}
					}

					h6.event-name {
						margin-bottom: 25px;
						hyphens: none;
						-moz-hyphens: none !important;
						-ms-hyphens: none !important;
						-webkit-hyphens: none !important;

						@media (max-width: 992px) {
							margin-bottom: 18px;
							font-size: 18px;
							line-height: 21px;
						}
					}

					.tags {
						.tag {
							margin-right: 20px;
							margin-bottom: 40px;

							@media (max-width: 992px) {
								margin-right: 10px;
								margin-bottom: 24px;
								padding: 4px 10px;
								font-size: 11px;
							}
						}
					}
				}
			}
		}

		.btn-view-all {
			display: none;

			@media (max-width: 768px) {
				display: block;
				text-align: right;
			}
		}
	}

	&__partners {
		header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 80px;

			@media (max-width: 992px) {
				margin-bottom: 24px;
			}

			h4 {
				padding-right: 30px;

				@media (max-width: 992px) {
					font-size: 24px;
					line-height: 34px;
					letter-spacing: -0.5px;
				}
			}

			.arrow-link {
				@media (max-width: 768px) {
					display: none;
				}
			}
		}

		.col-md-3 {
			margin-bottom: 64px;

			@media (max-width: 992px) {
				margin-bottom: 40px;
			}

			.partner-logo {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				height: 100%;

				figure {
					img {
						width: 100%;
						max-height: 140px;
						object-fit: contain;

						@media (max-width: 992px) {
							max-height: 80px;
						}
					}
				}
			}
		}


		.btn-view-all {
			display: none;

			@media (max-width: 768px) {
				display: block;
				margin-top: 40px;
				text-align: right;
			}
		}
	}

	&__employees {
		header.employees__header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 80px;

			@media (max-width: 992px) {
				margin-bottom: 24px;
			}

			h4 {
				padding-right: 30px;

				@media (max-width: 992px) {
					font-size: 24px;
					line-height: 34px;
					letter-spacing: -0.5px;
				}
			}

			.arrow-link {
				@media (max-width: 768px) {
					display: none;
				}
			}
		}

		.employee-card {
			// margin-bottom: 0;

			@media (max-width: 992px) {
				margin-bottom: 40px;
			}

			@media (max-width: 768px) {
				margin-bottom: 24px;
				padding-bottom: 24px;
				border-bottom: 1px solid $gray-light-3;
			}

			a {
				.img__wrapper {
					@media (max-width: 768px) {
						display: none;
					}
				}

				.text-content {
					p {
						&.employee-name {
							@media (max-width: 768px) {
								margin-bottom: 8px;
								font-size: 18px;
								line-height: 24px;
								font-weight: 500;
							}
						}

						&.employee-role {
							@media (max-width: 768px) {
								margin-bottom: 8px;
								font-size: 14px;
								line-height: 18px;
							}
						}

						&.employee-email,
						&.employee-phone {
							@media (max-width: 768px) {
								font-size: 18px;
								line-height: 24px;
								font-weight: 500;
							}
						}
					}
				}
			}
		}

		.btn-view-all {
			display: none;

			@media (max-width: 768px) {
				display: block;
				text-align: right;
			}
		}
	}

	&__other {
		.other__wrapper {
			margin-bottom: 48px;

			@media (max-width: 992px) {
				margin-bottom: 46px;
			}
		}

		header.other__header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 46px;

			@media (max-width: 992px) {
				margin-bottom: 28px;
			}

			h4 {
				padding-right: 30px;

				@media (max-width: 992px) {
					font-size: 24px;
					line-height: 34px;
					letter-spacing: -0.5px;
				}
			}

			.arrow-link {
				@media (max-width: 768px) {
					display: none;
				}
			}
		}
	}

	.other-item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		height: 100%;

		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 10px;
			margin-top: 40px;
			background: $grad-blue;

			@media (max-width: 992px) {
				margin-top: auto;
				height: 7px;
			}
		}

		ul.tags {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			@media (max-width: 992px) {
				margin-bottom: 6px;
				font-size: 11px;
			}

			li {
				margin-right: 10px;
				margin-bottom: 20px;

				@media (max-width: 992px) {
					margin-bottom: 10px;
				}
			}
		}

		h6.other-name {
			margin-bottom: 20px;
			hyphens: none;
			-moz-hyphens: none !important;
			-ms-hyphens: none !important;
			-webkit-hyphens: none !important;

			@media (max-width: 992px) {
				margin-bottom: 13px;
				font-size: 18px;
				line-height: 21px;
			}

			&+p {
				margin-top: -12px;
			}
		}

		p {
			margin-bottom: 20px;
			font-size: 22px;
			line-height: 28px;
			font-weight: 400;

			@media (max-width: 992px) {
				margin-bottom: 16px;
				font-size: 18px;
				line-height: 24px;
				font-weight: 400;
			}
		}

		a.arrow-link {
			margin-bottom: auto;

			@media (max-width: 992px) {
				margin-bottom: 16px;
				font-size: 14px;

				i {
					@media (max-width: 992px) {
						min-width: 18px;
						width: 18px;
						height: 18px;
						margin-top: 4px;
					}
				}
			}
		}
	}
}
