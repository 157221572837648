html, body {
    font-size: 22px; // html-font-size
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    color: $gray;
    hyphens: none;
    -moz-hyphens: none !important;
    -ms-hyphens: none !important;
    -webkit-hyphens: none !important;
    word-wrap: break-word;
}

.page{
    &.header-is-fixed{
        padding-top: 216px;

        @media (max-width: 992px) {
            margin-top: 118px;
        }
    }
}

a{
    color: $gray;

    &:hover{
        color: $gray;
        text-decoration: none;
    }
}

ul , ol{
    margin: 0;
    padding: 0;
    list-style: none;
}

figure{
    margin: 0;

    &.hover-zoom{
        position: relative;
        overflow: hidden;

        img{
            display: block;
            height: 100%;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 100%;
            transition: all 700ms ease;
            transform: scale(1);

            &:hover{
                transform: scale(1.08);
            }
        }
    }
}

.tag{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    border: 1px solid $gray-light-3;
    border-radius: 100px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;

    &--lg{
        padding: 10px 20px;
    }

    @media (max-width: 992px) {
        font-size: 16px;
        line-height: 20px;
    }
}

.download-button{
    .content{
        display: inline-block;

        button{
            i{
                width: 22px;
                min-width: 22px;
                height: 22px;
            }
        }

        .file-size{
            margin-top: 13px;
            font-size: 18px;
            font-weight: 400;
            text-align: center;
        }
    }
}

.rounded-right{
    border-radius: 0 40px 40px 0;

    @media (max-width: 992px) {
        border-radius: 0 20px 20px 0;
    }
}


.filter-view{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
		gap: 22px;
		flex-wrap: wrap;
    // align-items: flex-end;

    @media (max-width: 992px) {
        display: none;
    }

    button.btn-view{
        position: relative;
        width: 32px;
        height: 32px;
        background-color: $white;

        i{
            width: 32px;
            height: 32px;

            &.icon {
              font-style: normal;
            }
        }

        &.active{
            &::before{
                content: "";
                background: $nordlys-ok;
                left: -2px;
                top: -2px;
                width: 36px;
                height: 36px;
                display: block;
                position: absolute;
                z-index: -1;
            }
        }
    }

    .clear-filter__wrapper{
				order: 1;

        .btn-clear-filter{
            white-space: nowrap;
        }
    }
}

.copy-on-click{
    position: relative;

    .copy-box{
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        width: 196px;
        height: 41px;
        color: $blue;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #B8F0D5;
        line-height: 23px;
        z-index: 10;

        @media (max-width: 768px) {
            top: 22px;
            width: 125px;
        }

        // hover spacing
        &::before{
            content: "";
            display: block;
            position: absolute;
            top: -15px;
            width: 100%;
            height: 15px;
            background-color: transparent;
        }
    }
}

.color-blue{ color: $blue; }
.color-cyan-dark{ color: $cyan-dark; }
.color-cyan{ color: $cyan; }
.color-gray{ color: $gray; }
.color-gray-light-1{ color: $gray-light-1; }
.color-gray-light-2{ color: $gray-light-2; }
.color-gray-light-3{ color: $gray-light-3; }
.color-so-so-gray{ color: $so-so-gray; }
.color-red{ color: $red; }
.color-neutral-dark{ color: $neutral-dark; }
.color-neutral-light{ color: $neutral-light; }
.color-white{ color: $white; }
.color-gold-dark{ color: $gold-dark; }
