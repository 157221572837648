.accordion-group{
    .accordion{
        position: relative;
        margin-top: -1px;
        padding-top: 44px;
        padding-bottom: 44px;
        border-top: 1px solid $gray-light-3;
        border-bottom: 1px solid $gray-light-3;

        >button{
            position: absolute;
            top: 45px;
            right: 34px;

            @media (max-width: 992px) {
                right: 0;
            }

            i{
                width: 45px;
                height: 45px;
                transition: 0.2s;

                @media (max-width: 992px) {
                    width: 26px;
                    height: 26px;
                }
            }
        }

        .accordion__content{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-right: 122px;

            @media (max-width: 1680px) {
                padding-right: 12%;

                @media (max-width: 992px) {
                    padding-right: 40px;
                }
            }

            .text{
                width: 86.7%;

                @media (max-width: 992px) {
                    width: 100%;
                }

                h3{
                    margin-bottom: 28px;

                    @media (max-width: 992px) {
                        margin-bottom: 24px;
                        font-size: 24px;
                        line-height: 35px;
                        letter-spacing: -0.5px;
                    }
                }

                p{
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 40px;

                    &:last-child{
                        margin-bottom: 0;
                    }

                    @media (max-width: 992px) {
                        margin-bottom: 24px;
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }
        }

        .hidden-content{
            display: none;
            margin-top: 60px;

            @media (max-width: 992px) {
                margin-top: 24px;
            }

						.accordion-text ul{
							list-style: disc;
							padding-left: 32px;
						}

            p{
                font-size: 22px;
                font-weight: 400;
                line-height: 40px;

                &:last-child{
                    margin-bottom: 0;
                }

                @media (max-width: 992px) {
                    margin-bottom: 24px;
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            ul.company-info{
                margin-top: 50px;

                @media (max-width: 992px) {
                    margin-top: 24px;
                }

                li{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 15px;

                    @media (max-width: 992px) {
                        font-size: 16px;
                        line-height: 26px;

                        &:last-child{
                            margin-bottom: 0;
                        }
                    }

                    i{
                        width: 22px;
                        height: 22px;
                        min-width: 22px;
                        margin-right: 21px;

                        @media (max-width: 992px) {
                            margin-right: 16px;
                        }
                    }
                }
            }
        }

        &.active{
            >button{
                i{
                    -webkit-transform: rotateZ(-45deg);
                    transform: rotateZ(-45deg);
                }
            }
        }

        &--img{
            .accordion__content{
                .text{
                    width: 66.7%;

                    @media (max-width: 992px) {
                        width: 100%;
                    }

                    h3{
                        margin-bottom: 21px;
                        font-size: 36px;
                        line-height: 46px;
                        letter-spacing: -0.5px;

                        @media (max-width: 992px) {
                            margin-bottom: 24px;
                            font-size: 24px;
                            line-height: 34px;
                        }
                    }
                }

                figure{
                    align-items: flex-end;
                    margin-left: 20px;
                    padding-bottom: 12px;

                    @media (max-width: 992px) {
                        display: none;
                    }

                    img{
                        max-width: 220px;
                        max-height: 135px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
