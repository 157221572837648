.about-us{
    margin-bottom: 160px;

    @media (max-width: 992px) {
        margin-bottom: 96px;
    }

    .breadcrumbs__wrapper{
        margin-top: 24px;
        margin-bottom: 136px;

        @media (max-width: 992px) {
            margin-bottom: 32px;
        }
    }

    .aside__wrapper{
        @media (max-width: 992px) {
            margin-bottom: 32px;
        }
    }

    .about-us__header{
        margin-bottom: 50px;
        padding-bottom: 50px;
        border-bottom: 1px solid $gray-light-3;

        @media (max-width: 992px) {
            display: none;
        }

        &--mobile{
            display: none;

            @media (max-width: 992px) {
                display: block;
                margin-bottom: 28px;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }

    .about-us__ingress{
        margin-bottom: 50px;

        @media (max-width: 992px) {
            margin-bottom: 32px;
        }

        h3{
            max-width: 973px;
            margin-bottom: 50px;

            @media (max-width: 992px) {
                margin-top: 10px;
                margin-bottom: 28px;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: -0.5px;
            }
        }

        p.ingress,
        .ingress-text p{
            margin-bottom: 40px;
            max-width: 973px;
            font-size: 28px;
            font-weight: 400;
            line-height: 42px;

            a{
                color: #036092;
                text-decoration: underline;
            }


            @media (max-width: 992px) {
                margin-bottom: 24px;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }

        &--download{
            display: flex;
            flex-direction: row;

            @media (max-width: 992px) {
                flex-direction: column;
                margin-bottom: 42px;
                padding-top: 24px;
                padding-bottom: 24px;
                border-top: 1px solid $gray-light-3;
            }

            .download-button{
                margin-left: 115px;

                @media (max-width: 1440px) {
                    margin-left: 60px;
                }

                @media (max-width: 992px) {
                    margin-top: 24px;
                    margin-left: 0;
                }
            }
        }
    }

    .article__htmlarea{
        > p , ul , ol{
            max-width: 973px;
        }
    }


    &__about-us{
        .article__htmlarea{
            > p{
                max-width: 789px;
            }
        }
    }

    &__background{
        .article__htmlarea{
            > p{
                max-width: 789px;
            }

            .accordion-group{
                .accordion{
                    padding-top: 44px;
                    padding-bottom: 28px;
                }
            }
        }
    }

    &__alliances{
        .about-us__header{
            @media (max-width: 992px) {
                display: block;
                margin-top: 10px;
                margin-bottom: 28px;
                padding-bottom: 0;
                border-bottom: none;
            }

            h1{
                @media (max-width: 992px) {
                    font-size: 24px;
                    line-height: 34px;
                    letter-spacing: -0.5px;
                }
            }
        }

        .about-us__ingress{
            @media (max-width: 992px) {
                margin-bottom: 65px;
            }
        }

        .article__htmlarea{
            .accordion-group{
                .accordion{
                    padding-top: 44px;
                    padding-bottom: 28px;
                }
            }
        }
    }

    &__partners{
        .about-us__header{
            margin-bottom: 60px;
            padding-bottom: 0;
            border-bottom: none;

            @media (max-width: 992px) {
                display: block;
                margin-top: 10px;
                margin-bottom: 42px;
                padding-bottom: 0;
                border-bottom: none;
            }

            h1{
                @media (max-width: 992px) {
                    font-size: 24px;
                    line-height: 34px;
                    letter-spacing: -0.5px;
                }
            }
        }
    }

    &__media{
        .about-us__header{
            @media (max-width: 992px) {
                display: block;
                margin-top: 10px;
                margin-bottom: 42px;
                padding-bottom: 0;
                border-bottom: none;
            }

            h1{
                @media (max-width: 992px) {
                    font-size: 24px;
                    line-height: 34px;
                    letter-spacing: -0.5px;
                }
            }
        }

        .about-us__ingress{
            margin-bottom: 100px;
            padding-bottom: 50px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                margin-bottom: 64px;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }

    &__articles{
        .about-us__header{
            @media (max-width: 992px) {
                display: block;
                margin-top: 10px;
                margin-bottom: 28px;
                padding-bottom: 0;
                border-bottom: none;
            }

            h1{
                @media (max-width: 992px) {
                    font-size: 24px;
                    line-height: 34px;
                    letter-spacing: -0.5px;
                }
            }

						&--desktop{
								@media (max-width: 992px) {
									display: none
								}
						}
        }

        .about-us__ingress{
            margin-bottom: 60px;
            padding-bottom: 50px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                margin-bottom: 42px;
                padding-bottom: 24px;
            }
        }

        .article__htmlarea{
            > p , ul , ol{
                &.intertitle{
                    margin-bottom: 0;
                    color: $cyan-dark;
                    font-weight: 700;
                }
            }
        }
    }

    &__terms{
        .about-us__header{
            @media (max-width: 992px) {
                display: block;
                margin-top: 10px;
                margin-bottom: 28px;
                padding-bottom: 0;
                border-bottom: none;
            }

            h1{
                @media (max-width: 992px) {
                    font-size: 24px;
                    line-height: 34px;
                    letter-spacing: -0.5px;
                }
            }
        }

        .about-us__ingress{
            margin-bottom: 60px;
            padding-bottom: 50px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                margin-bottom: 42px;
                padding-bottom: 24px;
            }
        }

        .article__htmlarea{
            > p , ul , ol{
                &.intertitle{
                    margin-bottom: 0;
                    font-weight: 700;
                }
            }
        }

    }

    &__privacy{
        .about-us__header{
            @media (max-width: 992px) {
                display: block;
                margin-top: 10px;
                margin-bottom: 28px;
                padding-bottom: 0;
                border-bottom: none;
            }

            h1{
                @media (max-width: 992px) {
                    font-size: 24px;
                    line-height: 34px;
                    letter-spacing: -0.5px;
                }
            }
        }

        .about-us__ingress{
            margin-bottom: 60px;
            padding-bottom: 50px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                margin-bottom: 42px;
                padding-bottom: 24px;
            }
        }

        .article__htmlarea{
            h6{
                margin-bottom: 10px;
            }
        }
    }
}
