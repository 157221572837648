.event-page{
    margin-bottom: 160px;

    @media (max-width: 992px) {
        margin-bottom: 80px;
    }

    .page__header{
        margin-top: 136px;
        margin-bottom: 44px;
        padding-bottom: 68px;
        border-bottom: 1px solid $gray-light-3;

        @media (max-width: 992px) {
            margin-top: 46px;
            margin-bottom: 30px;
            padding-bottom: 26px;
        }

        h1{
            margin-bottom: 48px;

            @media (max-width: 992px) {
                margin-bottom: 34px;
                font-size: 36px;
                line-height: 46px;
                letter-spacing: -0.5px;
            }
        }

        .invitation-tag{
            margin-bottom: 33px;
        }

        .tags{
            margin-bottom: 56px;

            @media (max-width: 992px) {
                margin-bottom: 32px;   
            }

            .tag{
                margin-right: 40px;

                @media (max-width: 992px) {
                    margin-right: 24px;
                    padding: 8px 15px;
                }

                &:last-child{
                    margin-right: 0;
                }
            }
        }

        .img__wrapper{
            margin-bottom: 65px;

            @media (max-width: 992px) {
                margin-bottom: 38px;
            }

            figure{
                @media (max-width: 768px) {
                    width: calc(100% + 60px);
                    margin-left: -60px;
                    padding-top: calc((250 / 345) * 100%);
                }
            }
        }

        .ingress{
            font-size: 32px;
            line-height: 44px;

            @media (max-width: 992px) {
                margin-bottom: 34px;
                font-size: 18px;
                line-height: 28px;
            }
        }

        .btn-share__wrapper{
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            @media (max-width: 992px) {
                justify-content: flex-start;
            }

            .btn-share{
                @media (max-width: 992px) {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 400;
                }

                i{
                    width: 24px;
                    height: 24px;
                    margin-right: 38px;
                    vertical-align: middle;

                    @media (max-width: 992px) {
                        width: 18px;
                        height: 18px;
                        margin-right: 16px;
                    }
                }

                .copy-box{
                    right: 0;
                    left: inherit;
                }
            }
        }
    }

    .event-calendar__wrapepr{
        .event-calendar{
            margin-bottom: 70px;
            padding-bottom: 55px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                margin-bottom: 40px;
                padding-bottom: 28px;
            }

            .content{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-left: 62px;

                @media (max-width: 992px) {
                    margin-bottom: 54px;
                    padding-left: 36px;
                }

                .date , .location{
                    position: relative;
                    width: 100%;
                    margin-bottom: 18px;
                    font-size: 28px;
                    line-height: 38px;

                    @media (max-width: 992px) {
                        margin-bottom: 24px;
                        font-size: 18px;
                        line-height: 28px;
                    }

                    >i{
                        position: absolute;
                        top: 8px;
                        left: -60px;
                        width: 24px;
                        min-width: 24px;
                        height: 24px;
                        
                        @media (max-width: 992px) {
                            left: -36px;
                            width: 18px;
                            min-width: 18px;
                            height: 18px;
                        }

                        &.icon--pin-gradient{
                            height: 28px;
                        }

                    }

                    .arrow-link{
                        margin-left: 20px;

                        @media (max-width: 992px) {
                            display: flex;
                            margin-top: 8px;
                            margin-left: 0;
                        }
                    }
                }

                .other-info{
                    width: calc(50% - 30px);
                    margin-top: 50px;
                    margin-right: 30px;

                    @media (max-width: 992px) {
                        width: 100%;
                        margin-top: 24px;
                        margin-right: 0;
                    }

                    p{
                        @media (max-width: 992px) {
                            font-size: 18px;
                            line-height: 24px;
                        }

                        &.title{
                            margin-bottom: 10px;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 32px;

                            @media (max-width: 992px) {
                                font-size: 18px;
                                line-height: 24px;
                            }
                        }

                        a{
                            text-decoration: underline;
                        }
                    }
                }
            }

            .join-button__wrapper{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                width: 100%;
                max-width: 330px;
                height: 100%;
                margin-left: auto;

                @media (max-width: 992px) {
                    flex-direction: column-reverse;
                    align-items: flex-start;
                    width: 245px;
                    margin-left: 40px;
                }

                .spots{
                    margin: 0 auto 22px auto;
                    font-size: 24px;
                    text-align: center;
                    font-weight: 400;

                    @media (max-width: 992px) {
                        margin-top: 16px;
                        margin-bottom: 0;
                        font-size: 16px;
                        line-height: 26px;
                    }

                    span{
                        margin-right: 5px;
                        color: #6390a4;
                    }
                }

                .btn{
                    max-width: 100%;
                    white-space: nowrap;
                }
            }
        }
    }

    .event-about__wrapper{
        margin-bottom: 64px;

        .event-about{
            .collaborators__wrapper{
                h3{
                    margin: 40px auto;

                    @media (max-width: 991px) {
                        margin-top: 56px;
                        margin-bottom: 32px;
                        font-size: 24px;
                        line-height: 35px;
                    }
                }

                .collaborators{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
    
                    @media (max-width: 992px) {
                        margin-bottom: 0px;
                    }   
    
                    
                    &__logo{
                        width: calc(33% - 30px);
                        max-height: 120px;
                        margin-bottom: 60px;
    
                        @media (max-width: 992px) {
                            width: calc(50% - 20px);
                            margin-bottom: 30px;
                            min-height: 100px;
                        }
    
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }

        .event__staff{
            margin-left: 60px;
            border-left: 1px solid $gray-light-3;

            @media (max-width: 1600px) {
                margin-left: 0;
            }

            @media (max-width: 1200px) {
                margin-left: 10px;
            }

            @media (max-width: 991px) {
                margin-left: 0;
                border-left: none;
            }

            .contact-title{
                max-width: 430px;
                margin-bottom: 30px;
                margin-left: auto;
                padding-left: 60px;

                @media (max-width: 1200px) {
                    padding-left: 30px;
                }

                @media (max-width: 991px) {
                    margin-left: 0;
                    margin-bottom: 24px;
                    padding-left: 0;
                    font-size: 24px;
                    line-height: 35px;
                    font-weight: 400;
                }
            }

            .contact-card{
                margin-bottom: 65px;
                margin-left: auto;
                padding-left: 60px;

                @media (max-width: 1200px) {
                    padding-left: 30px;
                }

                @media (max-width: 991px) {
                    margin-bottom: 46px;
                    margin-left: 0;
                    padding-left: 0;
                }

                &:last-child{
                    margin-bottom: 0;
                }

                header{
                    display: none;
                }
            }
        }
    }
}