.macro-table{
	&--featured-links{
			margin-bottom: 95px;

			&:last-of-type{
					margin-bottom: 0;
			}

			h3{
					&.line-separator{
							margin-top: 0;
					}
			}

			.macro-table__ingress{

				ul {
					list-style: disc;
					padding-left: 32px;
				}

				p{
					font-size: 28px;
					font-weight: 400;
					line-height: 42px;
					max-width: 912px;
					margin-bottom: 28px;

					@media (max-width: 992px) {
						margin-bottom: 48px;
						font-size: 16px;
						line-height: 26px;
					}

					a{
						color: $cyan-dark;
						text-decoration: underline;
					}
				}
			}

			ul.download-list{
					border-top: 1px solid $gray-light-3;

					li{
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							padding: 20px 0;
							border-bottom: 1px solid $gray-light-3;

							@media (max-width: 992px) {
									flex-direction: column;
							}

							.media-info{
									padding-right: 20px;

									.name{
											font-size: 24px;
											line-height: normal;
											font-weight: 400;

											@media (max-width: 992px) {
													font-size: 18px;
													line-height: 24px;
											}
									}

									.description{
											font-size: 24px;
											line-height: normal;
											font-weight: 400;

											@media (max-width: 992px) {
													margin-bottom: 8px;
													font-size: 18px;
													line-height: 24px;
											}

											&::before{
													content: "–";
													margin-right: 5px;
											}
									}

									.creation-date{
											margin-top: 7px;
											font-size: 18px;

											@media (max-width: 992px) {
													color: $gray-light-1;
													font-size: 14px;
													line-height: 18px;
											}
									}
							}

							.media-links{
									display: flex;
									flex-direction: row;
									align-items: center;

									@media (max-width: 992px) {
											justify-content: space-between;
											margin-top: 24px;
									}

									a{
											margin-right: 76px;
											color: $cyan-dark;
											font-size: 18px;
											font-weight: 600;

											@media (max-width: 992px) {
													width: 50%;
													margin-right: 20px;
													font-size: 16px;
													line-height: 24px;
											}

											&:last-child{
													margin-right: 20px;

													@media (max-width: 992px) {
															margin-right: 0;
													}
											}
									}
							}
					}
			}
	}

	&--colour-block{
			margin-bottom: 95px;

			.colour-table{
					tbody{
							tr{
									&:nth-child(odd){
											background-color: transparentize($green-light-3, 0.5)
									}

									td{
											padding: 30px;
											vertical-align: baseline;

											@media (max-width: 992px) {
													padding: 20px;
											}

											&:first-child{
													p{
															font-weight: 700;
													}
											}

											&:last-child{
													padding-right: 60px;

													@media (max-width: 992px) {
															padding-right: 20px;
															padding-left: 0;
													}
											}

											p{
													margin-bottom: 20px;
													font-size: 22px;
													line-height: 32px;

													@media (max-width: 992px) {
															font-size: 16px;
															line-height: 26px;
													}

													&:last-child{
															margin-bottom: 0;
													}
											}
									}
							}
					}
			}
	}

	.macro-table-content ul{
		list-style: disc;
		padding-left: 32px;
	}
}
