.fullscreen-modal{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 99;

    &.active{
        display: block;
    }

    &__wrapper{
        pointer-events: all;
        transform: translate(0, 216px);
        width: 100%;
        height: calc(100% - 216px);
        padding-bottom: 35px;
        background-color: $blue;
        overflow-y: auto;
    
        @media (max-width: 1440px) {
            height: calc(100% - 176px);
            transform: translate(0, 176px);
        }
        
        @media (max-width: 992px) {
            height: calc(100% - 119px);
            transform: translate(0, 119px);
        }

        .fullscreen-modal__header{
            margin-bottom: 35px;
            padding-top: 57px;
    
            @media (max-width: 992px) {
                padding-top: 16px;
            }
    
            h3{
                color: $white;
                
                @media (max-width: 992px) {
                    font-size: 36px;
                    line-height: 46px;
                }
            }
        }
    }

    .btn-close{
        position: fixed;
        top: 60px;
        width: 24px;
        height: 30px;
        background-color: $blue;
        pointer-events: all;

        @media (max-width: 1440px) {
            top: 40px;
        }

        @media (max-width: 992px) {
            right: 30px;
        }
    }

    .btn-go-back{
        @media (max-width: 992px) {
            font-size: 18px;
            line-height: 24px;
        }
    }
}