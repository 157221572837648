// Image aspect ratio
.img {
    position: relative;
    width: 100%;
    overflow: hidden;
  
    img, > iframe, video {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      object-fit: cover;
      width: 100%;
      transform: translate(-50%, -50%);
    }
  
    video {
      height: 100%;
      width: auto;
    }
  
    &.aspectRatio--1x1 {
      padding-top: calc((1 / 1) * 100%);
    }
  
    &.aspectRatio--849x515 {
      padding-top: calc((515 / 849) * 100%);
    }
  
    &.aspectRatio--357x224 {
      padding-top: calc((224 / 357) * 100%);
    }
  
    &.aspectRatio--1400x608 {
      padding-top: calc((608 / 1400) * 100%);
    }
  
    &.aspectRatio--427x227 {
      padding-top: calc((227 / 427) * 100%);
    }
  
    &.aspectRatio--1400x493 {
      padding-top: calc((469 / 1400) * 100%);
    }
  
  }
  