.site-footer{
    background: linear-gradient(46.99deg, #003249 -8.39%, #3D88B4 46.76%, #4BE29A 114.39%);
    padding: 82px 0 80px;

    @media (max-width: 992px) {
        padding: 50px 0 90px;
        background: linear-gradient(74deg, #003249 -8.39%, #3D88B4 46.76%, #4BE29A 114.39%);
    }

    *{
        color: $white;
    }

    a{
        &:hover{
            color: $white;
        }
    }

    .site-footer__logo{
        display: inline-block;
        margin-bottom: 75px;

        @media (max-width: 992px) {
            margin-bottom: 35px;
        }

        img{
            width: 283px;

            @media (max-width: 768px) {
                width: 226px;
            }
        }
    }

    .site-footer__col-1 , .site-footer__col-2{
        margin-bottom: 156px;
        
        @media (max-width: 992px) {
            margin-bottom: 60px;
        }
        
        @media (max-width: 768px) {
            margin-bottom: 63px;
        }

        .site-footer__header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 33px;
            padding-bottom: 25px;
            border-bottom: 1px solid $white;
            
            @media (max-width: 768px) {
                padding-bottom: 12px;
                margin-bottom: 30px;
            }

            h6{
                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }

            a{
                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }
        }

        .col-1-content{
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media (max-width: 768px) {
                flex-wrap: wrap;
            }

            .contact-info{
                width: 29.5%;
                padding-right: 30px;

                @media (max-width: 768px) {
                    width: calc(50% - 15px);
                    margin-bottom: 43px;
                    padding-right: 0;
                    
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                @media (max-width: 500px) {
                    width: 100%;
                }

                p{
                    @media (max-width: 768px) {
                        font-size: 16px;
                        line-height: 130%;
                    }
                }

                .email{
                    position: relative;
                    display: inline-block;
                    color: $white;
                    cursor: pointer;

                    @media (max-width: 768px) {
                        display: block;
                        font-size: 16px;
                        line-height: 130%;
                    }
                }

                .city{
                    margin-bottom: 20px;

                    @media (max-width: 768px) {
                        margin-bottom: 17px;
                    }
                }

                .adress{
                    margin-bottom: 6px;
                }

                a.map-link{
                    margin-bottom: 20px;
                    color: $white;
                    
                    @media (max-width: 768px) {
                        margin-bottom: 17px;
                        font-size: 16px;
                        line-height: 130%;
                    }

                    i{
                        @media (max-width: 768px) {
                            width: 11px;
                            height: 11px;
                        }
                    }
                }
                
                a.employee-link{
                    margin-top: 20px;
                    color: $white;

                    @media (max-width: 768px) {
                        margin-top: 15px;
                        font-size: 16px;
                        line-height: 130%;
                    }

                    &.city{
                        display: flex;
                        margin-top: 0;
                        font-size: 18px;
                        line-height: 24px;
                        font-weight: 600;

                        @media (max-width: 768px) {
                            margin-top: 0;
                            font-size: 16px;
                            line-height: 130%;
                        }
                    }
                }
            }
        }

        .col-2-content{
            display: flex;
            flex-direction: column;

            .social-link{
                margin-bottom: 20px;

                @media (max-width: 768px) {
                    margin-bottom: 28px;
                    font-size: 16px;
                    line-height: 130%;
                }

                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }

    .site-footer__bottom{
        .row{
            @media (max-width: 768px) {
                flex-direction: column-reverse;
            }

            p, a{
                @media (max-width: 768px) {
                    font-size: 16px;
                    line-height: 130%;
                }
            }

            .site-footer__privacy{
                @media (max-width: 768px) {
                    margin-bottom: 17px;
                }
            }
        }
    }
}