.article__htmlarea {
    .image-wrapper,
    .editor-align-justify {
        margin-bottom: 50px;

        @media (max-width: 992px) {
            margin-bottom: 32px;
        }

        figure {
            position: relative;

            img {
                width: 100%;
                border-radius: 0 40px 40px 0;

                @media (max-width: 992px) {
                    border-radius: 0 20px 20px 0;
                }
            }

            figcaption {
                margin-top: 18px;
                padding-left: 12px;
                color: $gray-light-1;
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;

                @media (max-width: 992px) {
                    margin-top: 15px;
                    padding-left: 0;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                }
            }
        }
    }

    > p {
        margin-bottom: 40px;
        font-size: 22px;
        font-weight: 400;
        line-height: 40px;

        @media (max-width: 992px) {
            margin-bottom: 24px;
            font-size: 16px;
            line-height: 26px;
        }

        a {
            &:not(.arrow-link) {
                color: $cyan-dark;
                text-decoration: underline;
            }
        }

        &.ingress {
            font-size: 28px;
            font-weight: 400;
            line-height: 42px;

            a {
                color: #036092;
                text-decoration: underline;
            }

            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }

    > h5 {
        margin-bottom: 18px;
    }

    > h3 {
        margin-top: 80px;
        margin-bottom: 40px;

        &:first-child {
            margin-top: 0;
        }

        @media (max-width: 992px) {
            margin-top: 56px;
            margin-bottom: 32px;
            font-size: 32px;
            line-height: 34px;
            letter-spacing: -0.5px;
        }
    }

    > h6 {
        @media (max-width: 992px) {
            font-size: 24px;
        }
    }

    > ul {
        margin-bottom: 40px;
        list-style: none;
        padding-left: 22px;

        li {
            margin-bottom: 10px;
            padding-left: 10px;

            @media (max-width: 992px) {
                margin-bottom: 14px;
                padding-left: 0;
                font-size: 16px;
                line-height: 26px;
            }

            &:before {
                content: "•";
                display: inline-block;
                position: absolute;
                margin: 0;
                left: 35px;
                @media (max-width: 1200px) {
                    left: 10px;
                }
            }

            a {
                color: $cyan-dark;
                text-decoration: underline;
            }
        }
    }

    > ol {
        margin-bottom: 40px;
        list-style: auto;
        padding-left: 22px;

        li {
            margin-bottom: 10px;
            padding-left: 10px;

            @media (max-width: 992px) {
                margin-bottom: 14px;
                font-size: 16px;
                line-height: 26px;
            }

            a {
                color: $cyan-dark;
                text-decoration: underline;
            }
        }
    }

    .download-button {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }

        @media (max-width: 992px) {
            margin-top: 47px;
            margin-bottom: 80px;
        }
    }
}
