// Resets
label{
    margin: 0;
}

// Norwep form elements
form.norwep-form{
    margin: 0;
    
    .input-group{
        margin-bottom: 30px;

        @media (max-width: 992px) {
            margin-bottom: 15px;
        }

        label{
            display: block;
            margin-bottom: 14px;
            color: $cyan-dark;
            font-size: 18px;
            line-height: 18px;
            font-weight: 600;
            
            @media (max-width: 992px) {
                margin-bottom: 12px;
                font-size: 16px;
            }
        }

        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="password"],
        input[type="phone"],
        textarea {
            display: block;
            width: 100%;
            padding: 10px 23px;
            font-size: 24px;
            line-height: 44px;
            font-weight: 400;
            border: 1px solid $gray-light-3;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0;

            @media (max-width: 992px) {
                padding: 7px 16px;
                font-size: 17px;
                line-height: 31.5px;
            }

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $gray-light-2;
                opacity: 1; /* Firefox */
            }
        
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $gray-light-2;
            }
        
            &::-ms-input-placeholder { /* Microsoft Edge */
                color: $gray-light-2;
            }
        }

        textarea{
            line-height: 35px;
        }

        // Custom checkbox
        input[type="checkbox"]{
            position: absolute; // take it out of document flow
            opacity: 0; // hide it
          
            & + label {
                position: relative;
                margin-bottom: 17px;
                padding: 0 0 0 40px;
                cursor: pointer;
                color: inherit;
                font-size: 22px;
                line-height: 28px;
                font-weight: 400;

                &:last-child{
                    margin-bottom: 0;
                }

                @media (max-width: 992px) {
                    font-size: 16px;
                    line-height: normal;
                }

                a{
                    color: $cyan-dark;
                    text-decoration: underline;
                }
            }
          
            // Box.
            & + label:before {
                content: '';
                position: absolute;
                top: 4px;
                left: 0;
                vertical-align: text-top;
                width: 23px;
                height: 23px;
                margin-right: 17px;
                border: 1px solid $gray-light-3;

                @media (max-width: 992px) {
                    top: 0;
                }
            }
           
            // Box focus
            &:focus-visible + label:before {
              box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
            }
            
            // Disabled state label.
            &:disabled + label {
              color: #b8b8b8;
              cursor: auto;
            }
          
            // Disabled box.
            &:disabled + label:before {
              box-shadow: none;
              background: #ddd;
            }
          
            // Checkmark image
            &:checked + label:after {
                content: '';
                position: absolute;
                left: 5px;
                top: 11px;
                width: 15px;
                height: 12px;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url("./../images/icons/check-gradient.svg");

                @media (max-width: 992px) {
                    top: 7px;
                }
            }
        }

        // Remove arrows from input NUMBER
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }

        &.disabled{
            pointer-events: none;
            
            label{
                color: $gray-light-1;
            }
        }

        &--checkbox{
            margin-bottom: 17px;

            &.invalid{
                input[type=checkbox]{
                    + label{
                        &::before{
                            border-color: $red;
                        }
                    }
                }
            }

            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    .error-message{
        margin-top: 45px;
        color: $red;

        @media (max-width: 992px) {
            margin-top: 30px;
            font-size: 16px;
            line-height: 20px;
        }
    }
}

// Search form
.search-form{
    position: relative;

    .gradient-bg{
        position: absolute;
        width: calc(100% + 2px);
        height: 76px;
        margin-top: -1px;
        margin-left: -1px;
        border-radius: 100px;
        background: $nordlys-darker-cta;
        z-index: -1;

        @media (max-width: 992px) {
            height: 50px;
        }
    }

    .search-input{
        width: 100%;
        height: 74px;
        background-color: $white;
        padding: 25px 100px 25px 50px;
        border: none;
        border-radius: 100px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;

        @media (max-width: 992px) {
            height: 48px;
            padding: 15px 50px 15px 17px;
            font-size: 13px;
            line-height: 6.6px;
        }
        
        &:focus{
            outline: none;
        }
    }
    
    .search-submit{
        position: absolute;
        right: 28px;
        bottom: 12px;
        height: 52px;
        border: none;
        background-color: transparent;
        background-position: center left;
        transition: 0.2s;
        width: 55px;
        background-size: auto;
        
        @media (max-width: 992px) {
            bottom: 0px;
            right: 15px;
            height: 48px;
            width: 30px;
            min-width: 30px;
            background-size: contain;
        }

        &:hover{
            @media (min-width: 992px) {
                background-position: 10px center;
            }
        }
    }
}

form.dynamic-label{
    .form-group {
        position:relative;
        margin-bottom: 50px;
      
        & + .form-group {
          margin-top: 50px;
          
          @media (max-width: 992px) {
              margin-top: 0px;
          }
        }

        @media (max-width: 992px) {
            margin-bottom: 32px;
        }
    }

    .form-label {
        position: absolute;
        left: 0;
        bottom: 30px;
        color: $gray;
        opacity: 0.4;
        background-color: transparent;
        z-index: 10;
        transition: transform 150ms ease-out, font-size 150ms ease-out;
        font-size: 21px;
        line-height: 88.2%;

        @media (max-width: 992px) {
            bottom: 16px;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .focused .form-label {
        transform: translateY(-225%);
        font-size: .75em;

        @media (max-width: 992px) {
            transform: translateY(-100%);
            font-size: .65em;
        }
    }

    .form-input {
        position: relative;
        padding: 25px 0px 15px 15px;
        width: 100%;
        outline: 0;
        border: 0;
        border-bottom: 1px solid $green-light-2;
        background-color: transparent;
        color: $gray;
        font-size: 21px;
        line-height: 88.2%;
        font-weight: 400;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0;

        @media (max-width: 992px) {
            padding: 25px 0px 10px 10px;
            font-size: 18px;
        }
    }

    // Remove arrows from input NUMBER
    /* Chrome, Safari, Edge, Opera */
    .form-input::-webkit-outer-spin-button,
    .form-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    .form-input[type=number] {
        -moz-appearance: textfield;
    }

    &--dark{
        .form-label {
            color: $white;
        }
        
        .form-input {
            color: $white;
        }

        .submit__wrapper{
            .btn-cancel{
                color: $white;
            }

            .error-message{
                color: $gold-dark;
            }
        }
    }

    .submit__wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (max-width: 992px) {
            margin-top: 18px;
        }

        input[type="submit"]{

        }
        
        .btn-cancel{
            margin-left: 60px;
            font-size: 21px;
            font-weight: 400;
            text-decoration: underline;

            @media (max-width: 992px) {
                margin-left: 40px;
                font-size: 18px;
            }
        }

        .error-message{
            width: 100%;
            margin-top: 30px;

            @media (max-width: 992px) {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    &--dark{
        .form-group{
            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus {
                border: 1px solid $cyan;
                border-top: 1px solid $blue;
                border-right: 1px solid $blue;
                border-left: 1px solid $blue;
                -webkit-text-fill-color: rgb(255, 255, 255);
                -webkit-box-shadow: 0 0 0px 1000px $blue inset;
                transition: background-color 5000s ease-in-out 0s;
            }
        }
    }
}