.anti-corruption{
    margin-bottom: 160px;

    @media (max-width: 992px) {
        margin-bottom: 96px;
    }

    .breadcrumbs__wrapper{
        margin-top: 24px;
        margin-bottom: 136px;

        @media (max-width: 992px) {
            margin-bottom: 32px;
        }
    }

    &__header{
        margin-bottom: 50px;
        padding-bottom: 50px;
        border-bottom: 1px solid $gray-light-3;
                
        @media (max-width: 992px) {
            display: none;
        }

        &--mobile{
            display: none;

            @media (max-width: 992px) {
                display: block;
                margin-bottom: 28px;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }

    &__ingress{
        margin-bottom: 50px;
        
        h3{
            max-width: 973px;
            margin-bottom: 50px;
        }

        p.ingress,
        .ingress__text p{
            margin-bottom: 40px;
            max-width: 973px;

            &:last-child{
                margin-bottom: 0;
            }

            a{
                color: $cyan-dark;
                text-decoration: underline;
            }
        }

        &--download{
            display: flex;
            flex-direction: row;

            .download-button{
                margin-left: 115px;

                @media (max-width: 1440px) {
                    margin-left: 60px;
                }
            }
        }
    }

    .article__htmlarea{
        > p , ul , ol{
            max-width: 973px;
        }
    }

    .link-highlight{
        margin-top: 110px;
    }

    &__anti-corruption{
        
    }

    &__integrity{
        .anti-corruption__ingress{
            padding-bottom: 50px;
            border-bottom: 1px solid $gray-light-3;
        }

        form.report-irregularity{
            margin-top: 16px;

            .input-group{
                &.file-upload{
                    p{
                        margin-bottom: 10px;

                        @media (max-width: 992px) {
                            font-size: 16px;
                            line-height: normal;
                        }
                    }

                    label{
                        display: inline-flex;
                        margin: 0;
                        color: white;
                        font-size: 16px;
                        font-weight: 500;

                        @media (max-width: 992px) {
                            padding: 10px 20px;
                        }
                    }

                    input[type="file"]{
                        position: absolute;
                        width: 0px;
                        height: 0;
                        opacity: 0;
                        visibility: hidden;
                    }

                    .file-name{
                        margin-left: 10px;
                        vertical-align: middle;

                        @media (max-width: 992px) {
                            font-size: 16px;
                            line-height: normal;
                        }
                    }
                }

                input[type="submit"]{
                    margin-top: 80px;

                    @media (max-width: 992px) {
                        margin-top: 42px;
                    }
                }
            }
        }
    }

    &__faq , &__studies{
        .anti-corruption__ingress{
            padding-bottom: 50px;
            border-bottom: 1px solid $gray-light-3;
        }

        .article__htmlarea{
            > p , ul , ol{
                &.intertitle{
                    margin-bottom: 0;
                    color: $cyan-dark;
                    font-weight: 700;
                }
            }

            h3{
                margin-top: 80px;

                &:first-child{
                    margin-top: 0;
                }
            }
        }
    }

    &__advice{
        .anti-corruption__ingress{
            padding-bottom: 50px;
            border-bottom: 1px solid $gray-light-3;
        }

        .contact-card{
            margin-top: 80px;
            
            @media (max-width: 992px) {
                margin-top: 42px;
            }
        }
    }

    &__checks{
        .anti-corruption__ingress{
            padding-bottom: 50px;
            border-bottom: 1px solid $gray-light-3;
        }

        .contact-card{
            margin-top: 80px;

            @media (max-width: 992px) {
                margin-top: 42px;
            }
        }
    }

    &__resources{
        .anti-corruption__ingress{
            padding-bottom: 50px;
            border-bottom: 1px solid $gray-light-3;
        }

        .article__htmlarea{
            .intertitle{
                margin-top: 64px;
                margin-bottom: 40px;
                color: $cyan-dark;
                font-size: 22px;
                font-weight: 700;

                &:first-child{
                    margin-top: 0;
                }
            }
        }
    }
}