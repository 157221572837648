.event-join{
    margin-bottom: 214px;

    @media (max-width: 992px) {
        margin-bottom: 80px;
    }

    .page__header{
        margin-top: 136px;
        margin-bottom: 47px;
        
        @media (max-width: 992px) {
            margin-top: 46px;
            margin-bottom: 30px;
        }
        
        h1{
            padding-bottom: 94px;
            border-bottom: 1px solid $gray-light-3;
            
            @media (max-width: 992px) {
                padding-bottom: 32px;
                font-size: 36px;
                line-height: 46px;
                letter-spacing: -0.5px;
            }
        }
    }

    &__description{
        .article__htmlarea{
            margin-bottom: 66px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                margin-bottom: 47px;
            }

            >h3{
                margin-bottom: 47px;

                @media (max-width: 992px) {
                    margin-bottom: 32px;
                }
            }

            >p{
                margin-bottom: 47px;

                @media (max-width: 992px) {
                    margin-bottom: 24px;
                }
            }
        }
    }

    &__form{
        .norwep-form{
            .input-group{
                &--checkbox{
                    margin-top: 47px;
                    margin-bottom: 30px;
                }
            }

            .privacy-alert{
                max-width: 764px;

                @media (max-width: 992px) {
                    font-size: 16px;
                }

                a{
                    color: $cyan-dark;
                    text-decoration: underline;
                }
            }

            .submit__wrapper{
                display: flex;
                flex-direction: row;
                margin-top: 67px;

                @media (max-width: 992px) {
                    margin-top: 42px;
                }

                .btn-cancel{
                    margin-left: 68px;
                    font-size: 20px;
                    text-decoration: underline;
                    font-weight: 400;
                    color: $gray;

                    @media (max-width: 992px) {
                        margin-left: 40px;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    &--thanks{
        margin-bottom: 198px;

        @media (max-width: 992px) {
            margin-bottom: 78px;
        }        

        .page__header{
            margin-bottom: 77px;

            @media (max-width: 992px) {
                margin-bottom: 30px;
            }

            h1{
                margin-bottom: 0;
                padding-bottom: 0;
                border: none;
            }
        }

        .event-join__description{            
            .article__htmlarea{
                margin-bottom: 107px;
                padding-top: 58px;
                padding-bottom: 0;
                border-top: 1px solid $gray-light-3;
                border-bottom: none;

                @media (max-width: 992px) {
                    padding-top: 30px;
                    margin-bottom: 47px;
                }
            }

            .btn--event-page{
                display: inline-block;
            }
        }
    }
}