.aside__wrapper{
    padding-right: 6%;

    @media (max-width: 1200px) {
        padding-right: 6% !important;
    }

    @media (max-width: 991px) {
        padding-right: 10px !important;
    }

    @media (max-width: 992px) {
        margin-bottom: 32px;
    }

    aside{
        .aside__user-info{
            margin-bottom: 83px;

            @media (max-width: 991px) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-bottom: 22px;
            }
            
            .notification-bell{
                display: inline-block;
                vertical-align: middle;
                margin-right: 30px;

                @media (max-width: 991px) {
                    margin-right: 24px;
                }
                
                i{
                    position: relative;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    
                    &::after{
                        display: none;
                        content: '';
                        position: absolute;
                        left: 16px;
                        top: -5px;
                        width: 16px;
                        height: 16px;
                        background-color: #FF6760;
                        border: 2px solid #F6F8FB;
                        border-radius: 100%;
                    }
                }
                
                &.active{
                    i{
                        &::after{
                            display: block;
                        }
                    }
                }
            }
            
            .permission-tag{
                display: inline-block;
                padding: 5px 15px;
                border-radius: 30px;
                border: 1px solid $gray-light-3;
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;

                @media (max-width: 991px) {
                    margin-top: 15px;
                    margin-left: 46px;
                    order: 3;
                }
            }
            
            .user-info{
                margin-top: 24px;
                
                @media (max-width: 991px) {
                    width: calc(100% - 48px);
                    margin-top: 0;
                    margin-bottom: 0;
                }

                .user-name{
                    margin-bottom: 7px;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 30px;

                    @media (max-width: 991px) {
                        margin-bottom: 2px;
                        font-size: 18px;
                        line-height: 23px;
                    }
                }

                .role{
                    @media (max-width: 991px) {
                        font-size: 13px;
                        line-height: 16px;
                    }
                }
            }

        }

        nav{
            position: relative;
            
            button.btn-current-page{
                display: none;

                @media (max-width: 991px) {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 20px 0 20px 9px;
                    color: $cyan-dark;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 600;
                }

                i{
                    width: 21px;
                    height: 12px;
                    margin-left: 10px;
                    transition: 0.1s;
                }

                &::before{
                    content: "";
                    position: absolute;
                    display: block;
                    top: 0px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: $nordlys-darker-cta;
                }

                &::after{
                    content: "";
                    position: absolute;
                    display: block;
                    bottom: 0px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: $nordlys-darker-cta;
                }

                &.active{
                    i{
                        -webkit-transform: rotateZ(180deg);
                        transform: rotateZ(180deg);
                    }
                }
            }

            ul{
                @media (max-width: 991px) {
                    display: none;
                    position: absolute;
                    min-width: 252px;
                    width: 100%;
                    top: calc(100% - 1px);
                    right: 0px;
                    margin-bottom: 0;
                    background: white;
                    text-align: left;
                    box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.1);
                    border: 1px solid $gray-light-3;
                    z-index: 1;
                }

                li{
                    margin-bottom: 30px;

                    @media (max-width: 991px) {
                        margin-bottom: 0;
                        font-size: 18px;
                        letter-spacing: 0.35px;
                        font-weight: 500;
                    }

                    a{
                        display: inline-block;
                        color: $gray-light-1;
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 500;

                        @media (max-width: 991px) {
                            display: block;
                            color: $gray;
                            font-size: 18px;
                            letter-spacing: 0.35px;
                            font-weight: 500;
                            padding: 13px 36px;
                        }

                        &:hover{
                            @media (max-width: 991px) {
                                color: $cyan-dark;
                                background-color: rgba($cyan, .1);
                            }
                        }

                        &.active{
                            position: relative;
                            padding-bottom: 5px;
                            color: $cyan-dark;

                            @media (max-width: 991px) {
                                padding: 13px 36px;
                                background-color: rgba($cyan, .1);
                            }

                            &::after{
                                content: '';
                                display: block;
                                width: 100%;
                                height: 1px;
                                margin-top: 5px;
                                background: $nordlys-ok;

                                @media (max-width: 991px) {
                                    display: none;
                                }
                            }
                        }
                    }

                    &.log-out{
                        margin-top: 80px;

                        @media (max-width: 991px) {
                            margin-top: 0;
                        }

                        .arrow-link{
                            color: $gray;

                            i{
                                width: 12px;
                                height: 12px;
                                margin-top: 3px;
                            }
                        }
                    }
                }
            }
        }
    }
}