.pdf-page {
	padding: 55px 115px 115px 115px;

	&.cover {
		padding: 115px;
	}

	&>* {
		width: 100%;
	}
}

@media print {.pdf-page {page-break-after: always;}}

.cover-title {
	margin-bottom: 200px;
	color: #003249;

	h1 {
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 16px;
	}

	p {
		font-size: 1.1rem;
	}
}

table.cover-label {
	margin-bottom: 18px;

	tr {
		td {
			width: 50%;

			&:first-child {
				text-transform: uppercase;
				font-weight: 700;
			}

			&.date {
				text-align: right;
			}
		}
	}
}

table.cover-table {
	border: 3px solid #003249;

	tr {

		td {
			padding: 12px;

			&:first-child {
				width: 26%;
				text-transform: uppercase;
				text-align: right;
			}

			&:nth-child(2) {
				text-transform: capitalize;
				width: 74%;
			}
		}
	}
}

figure.result-logo {
	height: 5rem;
	margin-bottom: 3rem;

	img {
		display: block;
		height: 100%;
		width: auto;
	}
}

.result-name {
	margin-bottom: 1.2rem;
	font-size: 1.15rem;
	font-weight: 700;
}

section:not(:last-of-type) {
	margin-bottom: 1.2rem;
}

section p.label {
	margin-bottom: .4rem;
	font-weight: 700;
}

.result-date {
	font-weight: 700;

	span {
		&:first-child {
			text-decoration: underline;
			text-underline-offset: 4px;
		}
	}
}

.result-info {
	span.label {
		font-weight: 700;
	}
}

.result-description {
	ul {
		list-style: disc;
		padding-left: 1rem;
	}
}

.result-contact {
	.name, .function {
		font-weight: 700;
	}
}
