// Grid config
@import "./base/grid-config";

//import bootstrap
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/utilities/spacing";
@import "node_modules/bootstrap/scss/utilities/display";
@import "node_modules/bootstrap/scss/utilities/flex";
// @import "node_modules/bootstrap/scss/alert";
// @import "node_modules/bootstrap/scss/badge";
// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/button-group";
// @import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/carousel";
// @import "node_modules/bootstrap/scss/close";
// @import "node_modules/bootstrap/scss/code";
// @import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/dropdown";
// @import "node_modules/bootstrap/scss/forms";
// @import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/input-group";
// @import "node_modules/bootstrap/scss/jumbotron";
// @import "node_modules/bootstrap/scss/list-group";
// @import "node_modules/bootstrap/scss/media";
// @import "node_modules/bootstrap/scss/modal";
// @import "node_modules/bootstrap/scss/nav";
// @import "node_modules/bootstrap/scss/navbar";
// @import "node_modules/bootstrap/scss/pagination";
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/print";
// @import "node_modules/bootstrap/scss/progress";
// @import "node_modules/bootstrap/scss/root";
// @import "node_modules/bootstrap/scss/spinners";
// @import "node_modules/bootstrap/scss/tables";
// @import "node_modules/bootstrap/scss/toasts";
// @import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/transitions";
//@import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/utilities";

// Base
@import "base/color";
@import "base/typography";
@import "base/global";
@import "base/buttons";
@import "base/icons";
@import "base/image-aspect-ratio";
@import "base/article";


// Pages
@import "pages/my-page";
@import "pages/about-us";
@import "pages/anti-corruption";
@import "pages/partnership";
@import "pages/contact";
@import "pages/newsletter-page";
@import "pages/404";
@import "pages/search-results";
@import "pages/market-intelligence-page";
@import "pages/events-overview";
@import "pages/event";
@import "pages/event-join";
@import "pages/market";
@import "pages/technologies-solutions";
@import "pages/partners-overview";
@import "pages/partner";
@import "pages/pdf-search-results";


// Components
@import "components/footer";
@import "components/header";
@import "components/text-and-media";
@import "components/forms";
@import "components/aside";
@import "components/breadcrumbs";
@import "components/tech-solutions-card";
@import "components/dropdown";
@import "components/pagination";

@import "components/next-events-part";
@import "components/market-intelligence-part";
@import "components/opportunities-part";
@import "components/technologies-solutions-part";
@import "components/search-banner-part";
@import "components/link-highlight";

@import "components/accordion";
@import "components/contact-card";
@import "components/employee-card";
@import "components/filter-drawer";
@import "components/macro-table";
@import "components/cooperating-partners";
@import "components/fullscreen-modal";
@import "components/pdf-download-button";
@import "components/new-share-button";

.hide-boder-bottom {
	border-bottom: none !important;
}

.frag-intro-and-attachment {
	justify-content: space-between;
}

#report-irregularity-submit-button {
	margin-top: 80px;
}

#pdfobject-container {
	height: 603px;
}

.market-layout-event-overview {
	margin-bottom: 0px !important;
}

.techs-and-solutions-cluster-without-intro-remove-margin {
	margin-bottom: 0px !important;
}

.techs-and-solutions-cluster-without-intro-remove-border {
	border-top: none !important;
}

.dashboard-technologies-and-solutions-status-filter-item:hover {
	cursor: pointer;
}

.hide-border-top {
	border-top: none !important;
}

a.app-cookie-line-button:hover {
	color: #0056b3;
}
