// Blues
$blue: #003249;
$cyan-dark: #036092;
$cyan: #00A5CF;

// Green
$green-light-1: #8EE8BD;
$green-light-2: #B8F0D5;
$green-light-3: #D4F6E6;

// Grays
$gray: #2F2F2F;
$gray-light-1: #6D6D6D;
$gray-light-2: #969696;
$gray-light-3: #C0C0C0;
$so-so-gray: #E2E4EA;

// Red
$red: #FB4844;

$gold-dark: #FF994C;

// Neutral
$neutral-dark: #978C8F;
$neutral-light: #CBC5C7;
$white: #FFFFFF;

// Gradientes
$nordlys-darker-cta: linear-gradient(10deg, #1D5E84 9.69%, #3D88B4 45.44%, #4BE29A 113.68%);
$nordlys-ok: linear-gradient(55.92deg, #3D88B4 7.83%, #8EE8BD 94.78%);
$grad-blue: linear-gradient(90deg, #006FAB 0%, #72E3AD 100%);
$grad-orange: linear-gradient(90deg, #FE5F55 0%, #FFC482 100%);