.newsletter-page{
    .breadcrumbs__wrapper{
        margin-top: 24px;
    }

    &__header{
        margin-top: 136px;
        margin-bottom: 115px;
    }

    .norwep-form{
        margin-bottom: 170px;

        .input-group{
            &--checkbox{
                margin-top: 43px;
            }
        }

        .privacy-text{
            margin-top: 43px;

            p{
                a{
                    color: $cyan-dark;
                    text-decoration: underline;
                }
            }
        }

        .submit__wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 42px;
            margin-bottom: 0;

            .cta-link.cancel{
                margin-left: 68px;
            }
        }
    }

    &__image{
        margin-top: 136px;

        .img__wrapper{
            max-width: 505px;
            margin-left: auto;

            figure{
                img{
                    border-radius: 0 40px 40px 0;
                }
            }
        }
    }

}
