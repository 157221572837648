.partners-overview{
    margin-bottom: 168px;

    @media (max-width: 992px) {
        margin-bottom: 80px;
    }

    .page__header{
        margin-top: 136px;
        margin-bottom: 108px;

        @media (max-width: 992px) {
            margin-top: 46px;
            margin-bottom: 64px;
        }

        h1{
            @media (max-width: 992px) {
                font-size: 36px;
                line-height: 46px;
                letter-spacing: -0.5px;
            }
        }
    }

    .filter__wrapper{
        margin-bottom: 108px;

        @media (max-width: 992px) {
            margin-bottom: 50px;
        }

        .filter-dropdown{
            .custom-select{
                .all-items{
                    width: 100%;
                }
            }
        }

        .filter-view{
            align-items: center;

            @media (max-width: 992px) {
                display: flex;
                margin-top: 28px;
            }

            .btn-view{
                @media (max-width: 992px) {
                    display: none;
                }
            }
        }
    }

    .partner-list{
        .partner{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;

            h6{
                display: none;
            }

            figure{
                img{
                    width: 100%;
                    max-height: 145px;
                    max-width: 260px;
                    object-fit: contain;
                }
            }
        }

        .pagination{
            margin-top: 80px;
            padding-top: 50px;
            border-top: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                justify-content: center;
                margin-top: 40px;
                padding-top: 0;
                border-top: none;
            }
        }

        &--list{
            .partner--list{
                margin-bottom: 0;

                &:first-child{
                    a{
                        border-top: 1px solid $gray-light-3;
                    }
                }

                a{
                    width: 100%;
                    padding: 34px 32px 34px 44px;
                    border-bottom: 1px solid $gray-light-3;

                    &:hover{
                        background-color: transparentize($green-light-3, 0.6);

                        &::after{
                            -ms-transform: translate(10px,0px); /* IE 9 */
                            transform: translate(10px,0px); /* Standard syntax */
                        }
                    }

                    figure{
                        display: none;
                    }

                    h6{
                        display: block;
                    }

                    &::after{
                        content: "";
                        position: absolute;
                        top: calc(50% - 30px);
                        right: 20px;
                        display: block;
                        width: 60px;
                        height: 60px;
                        background-image: url('./../images/icons/arrow-right-green.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        transition: 0.2s;
                    }
                }
            }
            
            .pagination{
                padding-top: 0;
                border-top: none;
            }
        }
    }
}