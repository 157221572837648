.page-404{
    margin-bottom: 200px;

    .breadcrumbs__wrapper{
        margin-top: 24px;
    }
    
    &__image{
        margin-top: 47px;
        margin-bottom: 62px;

        figure{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                max-height: 395px;
                width: 100%;
                object-fit: cover;
                border-radius: 0 40px 40px 0;
            }

            h1{
                position: absolute;
                color: $white;
                max-width: 617px;
                text-align: center;
            }
        }
    }

    &__ingress{
        text-align: center;

        .arrow-link{
            margin-top: 27px;
        }
    }
}