.market-page{
    margin-bottom: 180px;

    @media (max-width: 992px) {
        margin-bottom: 90px;
    }

    .page__header{
        margin-top: 140px;
        margin-bottom: 47px;
        padding-bottom: 83px;
        border-bottom: 1px solid $gray-light-3;

        @media (max-width: 992px) {
            margin-top: 0;
            margin-bottom: 32px;
            padding-bottom: 32px;
        }

        h1{
            @media (max-width: 992px) {
                font-size: 36px;
                line-height: 46px;
                letter-spacing: -0.5px;
                font-weight: 400;
            }
        }
    }

    .contact-person__wrapper{
        h6{
            margin-bottom: 30px;
        }

        .contact-card{
            margin-bottom: 70px;

            @media (max-width: 992px) {
                margin-bottom: 40px;
            }

            header{
                display: none;
            }
        }
    }

    .accordion-group{
        .accordion{
            padding-top: 30px;
            padding-bottom: 30px;

            >button{
                @media (max-width: 992px) {
                    top: 34px;
                }
            }

            .accordion__content{
                .text{
                    h5{
                        margin-bottom: 10px;

                        @media (max-width: 992px) {
                            margin-bottom: 5px;
                            font-size: 28px;
                            line-height: 38px;
                        }
                    }

                    h6{
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 36px;
                        
                        @media (max-width: 992px) {
                            font-size: 20px;
                            line-height: 28px;
                        }
                    }
                }
            }

            .hidden-content{
                padding-bottom: 34px;
                
                .content-line{
                    margin-bottom: 30px;
                    padding-bottom: 30px;
                    border-bottom: 1px solid $gray-light-3;

                    &:last-child{
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                    
                    p{
                        @media (max-width: 992px) {
                            margin-bottom: 14px;
                        }

                        &.title{
                            font-weight: 700;
                            color: $cyan-dark;
                        }
                    }
                    
                    .download-button{
                        display: flex;

                        @media (max-width: 992px) {
                            margin-top: 24px;
                        }

                        .content{
                            margin-left: auto;

                            @media (max-width: 992px) {
                                margin-left: 0;
                                margin-right: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}