.partnership{
    margin-bottom: 160px;

    @media (max-width: 992px) {
        margin-bottom: 96px;
    }

    .breadcrumbs__wrapper{
        margin-top: 24px;
        margin-bottom: 136px;

        @media (max-width: 992px) {
            margin-bottom: 32px;
        }
    }

    &__header{
        margin-bottom: 50px;
        padding-bottom: 50px;
        border-bottom: 1px solid $gray-light-3;

        @media (max-width: 992px) {
            display: none;
        }

        &--mobile{
            display: none;

            @media (max-width: 992px) {
                display: block;
                margin-bottom: 28px;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }

    &__ingress{
        margin-bottom: 50px;

        @media (max-width: 992px) {
            margin-bottom: 32px;
        }

        p.ingress,
        .ingress__text p{
            margin-bottom: 40px;
            max-width: 973px;

            &:last-child{
                margin-bottom: 0;
            }

            a{
                color: $cyan-dark;
                text-decoration: underline;
            }
        }
    }

    &__partnership{
        max-width: 975px;

        .partnership__ingress{
            margin-bottom: 74px;
            padding-bottom: 50px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                padding-bottom: 32px;
                margin-bottom: 32px;
            }
        }

        // Part, text-and-media
        .text-and-media{
            &:last-child{
                //margin-bottom: 0;
            }

            .tm__item{
                .figure-wrapper{
                    @media (min-width: 992px) {
                        width: 53%;
                    }
                }

                header{
                    @media (min-width: 992px) {
                        width: 40%;
                    }

                    h2{
                        @media (min-width: 992px) {
                            margin-bottom: 30px;
                            font-size: 48px;
                            line-height: 52px;
                            letter-spacing: -1px;
                        }
                    }

                    h5{
                        @media (min-width: 992px) {
                            margin-bottom: 30px;
                            font-size: 18px;
                            line-height: 24px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    &__become-partner{
        .partnership__download{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 50px;
            padding-bottom: 76px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                flex-direction: column;
                padding-bottom: 42px;
                margin-bottom: 42px;
            }

            &:last-child{
                margin-bottom: 0;
            }

            .text{
							width: 65%;
							margin-right: 60px;

							ul {
								list-style: disc;
								padding-left: 32px;
							}

							@media (max-width: 992px) {
								width: 100%;
								margin-right: 0;
							}

							h3 {
								margin-bottom: 40px;

								@media (max-width: 992px) {
									margin-bottom: 32px;
									font-size: 24px;
									line-height: 34px;
									letter-spacing: -0.5px;
								}
							}

							.ingress{
								@media (max-width: 992px) {
									margin-bottom: 24px;
								}
							}
            }
        }
    }

    &__benefits{
        .article__htmlarea{
            h3{
                &:not(.line-separator){
                    margin-top: 100px;

                    @media (max-width: 992px) {
                        margin-top: 56px;
                    }
                }
            }
        }
    }

    &__norwepcube{
        .article__htmlarea{
            h3{
                &:not(.line-separator){
                    margin-top: 100px;
                }
            }

            > p , ul , ol{
                &.intertitle{
                    margin-bottom: 0;
                    color: $cyan-dark;
                    font-weight: 700;
                }
            }
        }
    }

    &__advisors{
        .article__htmlarea{
            > p , ul , ol, h3{
                max-width: 789px;
            }
        }
    }

}
