.events-overview {
	margin-bottom: 160px;

	.page__header {
		margin-top: 136px;
		margin-bottom: 90px;

		@media (max-width: 992px) {
			margin-top: 46px;
			margin-bottom: 64px;
		}

		h1 {
			line-height: 130%;

			@media (max-width: 992px) {
				font-size: 36px;
				line-height: 46px;
				letter-spacing: -0.5px;
			}
		}
	}

	.open-mobile-overlay {
		@media (max-width: 992px) {
			margin-bottom: 24px;
			margin-left: 0;
			font-size: 18px;
		}
	}

	.clear-filter__wrapper--mobile {
		display: none;

		@media (max-width: 992px) {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 64px;
			padding-top: 24px;
			border-top: 1px solid $gray-light-3;
		}
	}

	.filter__wrapper {
		margin-bottom: 118px;

		@media (max-width: 992px) {
			margin-bottom: 0;
		}

		.filter-dropdown {
			.custom-select {
				.all-items {
					width: 100%;
				}
			}
		}

		.filter-view {
			align-items: center;

			.btn-view {
				@media (max-width: 992px) {
					display: none;
				}
			}
		}
	}

	.highlighted-event {
		margin-bottom: 76px;

		.content {
			padding-bottom: 80px;
			border-bottom: 1px solid $gray-light-3;

			@media (max-width: 768px) {
				padding-bottom: 48px;
			}

			a {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;

				@media (max-width: 768px) {
					flex-direction: column;
				}

				.text-content {
					width: 34%;

					@media (max-width: 1600px) {
						width: 44%;
					}

					@media (max-width: 768px) {
						width: 100%;
					}

					h6 {
						margin-bottom: 22px;
						font-weight: 400;

						@media (max-width: 1440px) {
							margin-bottom: 15px;
							font-size: 18px;
							line-height: 28px;
						}
					}

					.title {
						h2 {
							@media (max-width: 1440px) {
								font-size: 36px;
								line-height: 46px;
								letter-spacing: -0.5px;
							}
						}

						.tags {
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							padding-top: 8px;

							.tag {
								margin-top: 16px;
								margin-right: 16px;
								padding: 10px 20px;

								@media (max-width: 1440px) {
									margin-right: 16px;
									padding: 8px 15px;
									font-size: 16px;
									line-height: 20px;
								}

								&:last-child {
									margin-right: 0;
								}
							}
						}
					}
				}

				figure {
					border-radius: 0 40px 40px 0;
					width: 61%;

					@media (max-width: 1600px) {
						width: 52%;
					}

					@media (max-width: 768px) {
						width: calc(100% + 60px);
						border-radius: 0 20px 20px 0;
						order: -1;
						margin-bottom: 33px;
						margin-left: -60px;
					}
				}
			}
		}

		&--list {
			margin-bottom: 0;

			.content {
				padding: 0;

				a {
					padding: 32px 32px 32px 22px;
					border-top: 1px solid $gray-light-3;

					&:hover {
						background-color: transparentize($green-light-3, 0.6);

						&::after {
							-ms-transform: translate(10px, 0px);
							/* IE 9 */
							transform: translate(10px, 0px);
							/* Standard syntax */
						}
					}

					.text-content {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						width: 100%;

						.invitation-tag {
							display: none;

							&--list {
								display: flex;
								margin-bottom: 16px;
							}
						}

						.date {
							width: 21%;
							margin-right: 60px;
							margin-bottom: 0;
							font-size: 22px;
							font-weight: 400;
							line-height: 28px;

							span {
								display: block;
							}
						}

						.title {
							width: calc(78% - 124px);

							h2 {
								font-size: 28px;
								line-height: 32px;
								font-weight: 500;
								letter-spacing: normal;
							}

							.tags {
								.tag {
									margin-top: 16px;
									margin-right: 20px;
									padding: 5px 15px;
									background-color: $white;
								}
							}
						}
					}

					figure {
						display: none;
					}

					&::after {
						content: "";
						position: absolute;
						top: calc(50% - 30px);
						right: 20px;
						display: block;
						width: 60px;
						height: 60px;
						background-image: url('./../images/icons/arrow-right-green.svg');
						background-repeat: no-repeat;
						background-size: contain;
						transition: 0.2s;
					}
				}
			}
		}
	}

	.events-list {
		.event-card__wrapper {
			display: flex;
			align-items: stretch;
			margin-bottom: 84px;

			@media (max-width: 992px) {
				margin-bottom: 60px;
			}

			.event-card {
				width: 100%;
				height: auto;
				padding-bottom: 30px;
				border-bottom: 1px solid $gray-light-3;

				&__img {
					margin-bottom: 30px;

					figure {
						@media (max-width: 992px) {
							padding-top: calc((200 / 315) * 100%);
						}

						img {
							border-radius: 0 20px 20px 0;
						}
					}
				}

				&__date {
					margin-bottom: 15px;
					font-size: 22px;
					font-weight: 400;
					line-height: 28px;

					@media (max-width: 992px) {
						margin-bottom: 8px;
						font-size: 18px;
						line-height: 28px;
					}
				}

				&__title {
					h6 {
						@media (max-width: 992px) {
							font-size: 24px;
							line-height: 35px;
							letter-spacing: -0.5px;
						}
					}

					.tags {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						padding-top: 12px;

						@media (max-width: 992px) {
							padding-top: 8px;
						}

						.tag {
							margin-top: 16px;
							margin-right: 16px;

							@media (max-width: 992px) {
								margin-top: 16px;
							}

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}
		}

		&--list {
			margin-bottom: 80px;

			.event-card__wrapper {
				margin-bottom: 0;

				.event-card {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					width: 100%;
					padding: 32px 32px 32px 22px;

					&:hover {
						background-color: transparentize($green-light-3, 0.6);

						&::after {
							-ms-transform: translate(10px, 0px);
							/* IE 9 */
							transform: translate(10px, 0px);
							/* Standard syntax */
						}
					}

					&__img {
						display: none;
					}

					.invitation-tag {
						display: none;
					}

					&__date {
						width: 21%;
						margin-right: 60px;
						margin-bottom: 0;
					}

					&__title {
						width: calc(78% - 124px);

						.invitation-tag--list {
							display: flex;
							margin-bottom: 16px;
						}

						.tags {
							.tag {
								margin-top: 16px;
								background-color: $white;
							}
						}
					}

					&::after {
						content: "";
						position: absolute;
						top: calc(50% - 30px);
						right: 20px;
						display: block;
						width: 60px;
						height: 60px;
						background-image: url('./../images/icons/arrow-right-green.svg');
						background-repeat: no-repeat;
						background-size: contain;
						transition: 0.2s;
					}
				}
			}
		}
	}

	.result-wrapper {
		align-items: center;
		margin-top: 40px;
		margin-bottom: 80px;

		@media (max-width: 992px) {
			display: none;
		}
	}
}

.invitation-tag {
	display: flex;
	align-items: center;
	margin-bottom: 28px;
	color: $blue;
	font-size: 17px;
	text-transform: uppercase;
	letter-spacing: 6px;

	@media (max-width: 992px) {
		margin-bottom: 16px;
		font-size: 13px;
	}

	i {
		margin-right: 18px;
	}

	&--list {
		display: none;
	}
}
