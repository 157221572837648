.icon{
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;

    &--x-gray{
        background-image: url('./../images/icons/x-gray.svg');
    }

    &--x-black{
        background-image: url('./../images/icons/x-black.svg');
    }

    &--x-white{
        background-image: url('./../images/icons/x-white.svg');
    }

    &--x-gradient{
        background-image: url('./../images/icons/x-gradient.svg');
    }

    &--plus-white{
        background-image: url('./../images/icons/plus-white.svg');
    }

    &--plus-gradient{
        background-image: url('./../images/icons/plus-gradient.svg');
    }

    &--arrow-right-green{
        background-image: url('./../images/icons/arrow-right-green.svg');
    }

    &--arrow-left-green{
        background-image: url('./../images/icons/arrow-left-green.svg');
    }

    &--arrow-left-green-sm{
        background-image: url('./../images/icons/arrow-right-green-sm.svg');
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }

    &--arrow-right-green-sm{
        background-image: url('./../images/icons/arrow-right-green-sm.svg');
    }

    &--arrow-right-gray-md{
        min-width: 25px;
        width: 25px;
        height: 25px;
        background-image: url('./../images/icons/arrow-right-gray-md.svg');
    }

    &--arrow-right-green-lg{
        min-width: 36px;
        width: 36px;
        height: 25px;
        background-image: url('./../images/icons/arrow-right-green-lg.svg');
    }

    &--arrow-up-right-green{
        background-image: url('./../images/icons/arrow-up-right-green.svg');
    }


    &--arrow-left-black{
        background-image: url('./../images/icons/arrow-left-black.svg');
    }

    &--arrow-down-black-md{
			height: 25px;
			min-width: 25px;
			width: 25px;
        background-image: url('./../images/icons/arrow-down-black.svg');
    }

    &--arrow-up-right-white{
        background-image: url('./../images/icons/arrow-up-right-white.svg');
    }

    &--arrow-right-white{
        background-image: url('./../images/icons/arrow-right-white.svg');
    }

    &--arrow-down-white{
        background-image: url('./../images/icons/arrow-down-white.svg');
    }

    &--bell-gray{
        background-image: url('./../images/icons/bell.svg');
    }

    &--sign-out{
        background-image: url('./../images/icons/sign-out.svg');
    }

    &--sign-out-white{
        background-image: url('./../images/icons/sign-out-white.svg');
    }

    &--sign-out-gray{
        background-image: url('./../images/icons/sign-out-gray.svg');
    }

    &--chevron-right-gray{
        background-image: url('./../images/icons/chevron-right-gray.svg');
    }

    &--chevron-down-black{
        background-image: url('./../images/icons/chevron-down-black.svg');
    }

    &--chevron-down-gradient{
        background-image: url('./../images/icons/chevron-down-gradient.svg');
    }

    &--double-chevron-right-gradient{
        background-image: url('./../images/icons/double-chevron-right-gradient.svg');
    }

    &--check-gradient{
        background-image: url('./../images/icons/check-gradient.svg');
    }

    &--ellipsis-gray{
        background-image: url('./../images/icons/ellipsis-gray.svg');
    }

    &--link-gradient{
        background-image: url('./../images/icons/link-gradient.svg');
    }

    &--search-black{
        background-image: url('./../images/icons/search-black.svg');
    }

    &--search-white{
        background-image: url('./../images/icons/search-white.svg');
    }

    &--menu-black{
        background-image: url('./../images/icons/menu-black.svg');
    }

    &--menu-white{
        background-image: url('./../images/icons/menu-white.svg');
    }

    &--sort-black{
        background-image: url('./../images/icons/sort-black.svg');
    }

    &--grid-black{
        background-image: url('./../images/icons/grid-black.svg');
    }

    &--list-black{
        background-image: url('./../images/icons/list-black.svg');
    }

    &--at-gradient{
        background-image: url('./../images/icons/at-gradient.svg');
    }

    &--globe-gradient{
        background-image: url('./../images/icons/globe-gradient.svg');
    }

    &--phone-gradient{
        background-image: url('./../images/icons/phone-gradient.svg');
    }

    &--pin-gradient{
        background-image: url('./../images/icons/pin-gradient.svg');
    }

    &--envelope-gradient{
        background-image: url('./../images/icons/envelope-gradient.svg');
    }

    &--share-gradient{
        background-image: url('./../images/icons/share-gradient.svg');
    }

    &--clock-gradient{
        background-image: url('./../images/icons/clock-gradient.svg');
    }

}
