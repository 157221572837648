.site-header {
	position: relative;
	width: 100%;
	height: 216px;
	background-color: $white;
	transition: 0.4s;
	z-index: 9;

	&.user-logged {

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 100%;
			width: 100%;
			height: 396px;
			background-repeat: no-repeat;
			background-size: cover;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) -20%, rgba(0, 0, 0, 0) 33.21%);
			pointer-events: none;

			@media (max-width: 992px) {
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0) 13%);
			}
		}

	}



	&.fixed {
		position: fixed;
		top: -216px;

		&::before {
			display: none;
		}

		&.show {
			top: 0;

			&::before {
				display: block;
			}
		}
	}

	@media (max-width: 1440px) {
		height: 176px;
	}

	@media (max-width: 992px) {
		height: inherit;
		padding: 40px 0;
	}

	.logo__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 81px;

		@media (max-width: 1440px) {
			margin-top: 62px;
		}

		@media (max-width: 992px) {
			margin-top: 0;
		}

		a {
			margin-left: -60px;

			@media (max-width: 1200px) {
				margin-left: 0;
			}

			img {
				width: 270px;

				@media (max-width: 1440px) {
					width: 230px;
				}

				@media (max-width: 992px) {
					width: 167px;
				}
			}
		}
	}

	.site-header__content {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin-top: 60px;

		@media (max-width: 1440px) {
			margin-top: 40px;
		}

		@media (max-width: 992px) {
			margin-top: 0;
		}

		.top-menu {
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 32px;

			@media (max-width: 1440px) {
				margin-bottom: 12px;
			}

			@media (max-width: 992px) {
				margin-bottom: 0;
			}

			ul {
				&:first-of-type {
					display: flex;
					flex-direction: row;

					@media (max-width: 992px) {
						display: none;
					}

					li {
						margin-right: 30px;

						a {
							font-size: 16px;
							font-weight: 500;
							line-height: 24px;
						}
					}
				}
			}

			.btn-login,
			.btn-user {
				margin-right: 30px;
				padding: 3px 19px;
				font-size: 16px;
				line-height: 24px;

				&:focus {
					border: none;
					outline: none;
					box-shadow: none;
				}

				@media (max-width: 992px) {
					display: none;
				}
			}

			.btn-user {
				display: none;
			}

			.btn-search {
				margin-right: 30px;
			}
		}

		.bottom-menu {
			display: flex;
			flex-direction: row;

			@media (max-width: 992px) {
				display: none;
			}

			ul {
				display: flex;
				flex-direction: row;
				text-align: right;
				hyphens: none;
				white-space: nowrap;

				li {
					margin-right: 40px;

					@media (max-width: 1440px) {
						margin-right: 30px;
					}

					&:last-child {
						margin-right: 0;
					}

					a {
						font-size: 20px;
						line-height: 24px;
						font-weight: 500;

						&::after {
							height: 2px;
						}
					}
				}
			}
		}
	}

	&--logged-in {
		.site-header__content {
			.top-menu {
				.btn-login {
					display: none;
				}

				.btn-user {
					display: flex;

					@media (max-width: 992px) {
						display: none;
					}
				}

				.popup-menu.user-menu {
					width: 315px;
					top: calc(100% + 5px);
					right: 90px;

					&::before {
						content: "";
						display: block;
						position: absolute;
						top: -10px;
						height: 10px;
						width: 100%;
						background-color: transparent;
					}
				}
			}
		}
	}

	&--fullscreen-modal {
		position: fixed;
		top: 0 !important;
		background-color: $blue;
		transition: none;

		.logo__wrapper {
			a {
				width: 270px;
				height: 62px;
				background-image: url("./../images/logo-white.svg");
				background-repeat: no-repeat;
				background-size: contain;

				@media (max-width: 1440px) {
					width: 230px;
					height: 53px;
				}

				@media (max-width: 992px) {
					width: 167px;
					height: 39px;
				}

				img {
					display: none;
				}
			}
		}

		.site-header__content {
			.top-menu {
				ul {
					li {
						a {
							color: $white;
						}
					}
				}

				.btn-search {
					.icon {
						background-image: url(./../images/icons/search-white.svg);
					}
				}

				.btn-menu {
					pointer-events: none;
					opacity: 0;
				}
			}

			.bottom-menu {
				ul {
					li {
						a {
							color: white;
						}
					}
				}
			}
		}
	}
}

.hidden-menu {
	display: none;
	position: fixed;
	width: 100vw;
	max-height: 100%;
	top: 0;
	padding: 80px 0 100px;
	background-color: $blue;
	color: $white;
	overflow: auto;
	z-index: 11;

	@media (max-width: 1200px) {
		padding: 60px 0;
	}

	@media (max-width: 970px) {
		padding: 40px 0;
	}

	.logo__wrapper {
		margin-bottom: 100px;

		@media (max-width: 992px) {
			margin-bottom: 55px;
		}

		a {
			display: inline-block;
			margin-left: -66px;
			margin-top: 10px;

			@media (max-width: 1440px) {
				margin-top: 0;
			}

			@media (max-width: 1200px) {
				margin-left: 0;
			}

			img {
				width: 270px;

				@media (max-width: 1440px) {
					width: 230px;
				}

				@media (max-width: 992px) {
					width: 167px;
				}
			}
		}

		.btn-close {
			position: absolute;
			top: -20px;
			right: 35px;

			@media (max-width: 1200px) {
				right: 10px;
			}

			@media (max-width: 992px) {
				top: 0;
			}
		}

		.btn-search {
			position: absolute;
			top: -20px;
			right: 82px;

			@media (max-width: 1200px) {
				right: 55px;
			}

			@media (max-width: 992px) {
				top: 0;
			}
		}
	}

	.hidden-menu__header {
		.logo__wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-top: 81px;
			margin-bottom: 180px;

			@media (max-width: 1440px) {
				margin-top: 62px;
			}

			@media (max-width: 992px) {
				margin-top: 0;
				margin-bottom: 50px;
			}

			a {
				margin-left: -60px;
				width: 270px;

				@media (max-width: 1440px) {
					width: 230px;
				}

				@media (max-width: 1200px) {
					margin-left: 0;
				}

				@media (max-width: 992px) {
					width: 167px;
				}


				img {
					width: 100%;
				}
			}
		}

		.site-header__content {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin-top: 60px;

			@media (max-width: 1440px) {
				margin-top: 40px;
			}

			@media (max-width: 992px) {
				margin-top: 0;
			}

			.top-menu {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 32px;

				@media (max-width: 1440px) {
					margin-bottom: 12px;
				}

				@media (max-width: 992px) {
					margin-bottom: 0;
				}

				ul {
					display: flex;
					flex-direction: row;

					@media (max-width: 992px) {
						display: none;
					}

					li {
						margin-right: 30px;

						a {
							color: $white;
							font-size: 16px;
							font-weight: 500;
							line-height: 24px;
						}
					}
				}

				.btn-login {
					margin-right: 30px;
					padding: 3px 19px;
					font-size: 16px;
					line-height: 24px;

					@media (max-width: 992px) {
						display: none;
					}
				}

				.btn-search {
					margin-right: 30px;
				}
			}

			.bottom-menu {
				display: flex;
				flex-direction: row;

				@media (max-width: 992px) {
					display: none;
				}

				ul {
					display: flex;
					flex-direction: row;
					text-align: right;
					hyphens: none;
					white-space: nowrap;

					li {
						margin-right: 40px;

						@media (max-width: 1440px) {
							margin-right: 30px;
						}

						&:last-child {
							margin-right: 0;
						}

						a {
							color: $white;
							font-size: 20px;
							line-height: 24px;
							font-weight: 500;

							&.underline-animation {

								&::after {
									height: 2px;
								}
							}
						}
					}
				}
			}
		}
	}

	&--search {
		.search-form__wrapper {
			form.search-form {
				label {
					display: block;
					margin-bottom: 36px;
					font-size: 48px;
					font-weight: 400;
					line-height: 52px;
					letter-spacing: -1px;
				}
			}
		}

		.popular-searches {
			margin-top: 64px;

			p {
				margin-bottom: 24px;
			}

			ul {
				li {
					padding: 23px 0 15px;
					border-bottom: 1px solid $green-light-3;

					&:first-child {
						border-top: 1px solid $green-light-3;
					}

					a {
						color: $white;
						font-size: 28px;
						line-height: 24px;
						font-weight: 400;
					}
				}
			}
		}
	}

	&--menu {
		.page-list-desktop {
			@media (max-width: 991px) {
				display: none;
			}

			ul {
				li {
					margin-bottom: 44px;

					&:last-child {
						margin-bottom: 0;
					}

					a {
						display: inline;
						color: $white;
						font-size: 48px;
						line-height: 52px;
						letter-spacing: -1px;
						font-weight: 400;
						hyphens: none;

						&::after {
							top: calc(100% + 6px);
							height: 2px;
						}

						@supports (-moz-appearance:none) {
							display: inline-block;
						}
					}
				}
			}
		}

		.menu-content {
			color: $white;

			a {
				color: $white;
			}

			.btn-login__wrapper {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 48px;

				@media (max-width: 992px) {
					margin-bottom: 32px;
				}

				.btn-login {
					display: inline-flex;
					hyphens: none;

					@media (max-width: 992px) {
						padding: 16px 22px;
						font-size: 17px;
					}
				}

				.btn-logout {
					display: none;
				}

				.user-welcome {
					p {
						display: none;
					}

					ul {
						display: none;
					}
				}
			}

			.page-list-mobile {
				display: none;

				@media (max-width: 991px) {
					display: block;
					margin-bottom: 37px;
					padding-top: 30px;
					border-top: 1px solid $gray-light-3;

					ul {
						li {
							margin-bottom: 18px;

							&:last-child {
								margin-bottom: 0;
							}

							a {
								font-size: 24px;
								line-height: 28px;
								font-weight: 400;
							}
						}
					}
				}
			}

			.sitemap {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-bottom: 42px;
				padding: 33px 0px 17px;
				border-top: 1px solid $gray-light-3;
				border-bottom: 1px solid $gray-light-3;

				@media (max-width: 768px) {
					flex-direction: column;
					margin-bottom: 33px;
				}

				li {
					width: calc(50% - 15px);
					margin-bottom: 16px;

					@media (max-width: 768px) {
						width: 100%;
					}

					a {
						position: relative;
						display: inline;
						hyphens: none;
						line-height: 24px;

						@media (max-width: 768px) {
							font-size: 18px;
						}

						&::after {
							height: 1.5px;
						}

						@supports (-moz-appearance:none) {
							display: inline-block;
						}
					}
				}
			}

			.up-to-date-links {
				>p {
					margin-bottom: 5px;
					opacity: 0.5;

					@media (max-width: 768px) {
						font-size: 18px;
					}
				}

				ul {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: space-between;

					@media (max-width: 768px) {
						flex-direction: column;
					}

					li {
						width: calc(50% - 15px);
						margin-bottom: 16px;

						@media (max-width: 768px) {
							width: 100%;
						}

						a {
							display: inline;
							line-height: 24px;

							@media (max-width: 768px) {
								font-size: 18px;
							}

							&::after {
								height: 1.5px;
							}

							@supports (-moz-appearance:none) {
								display: inline-block;
							}
						}
					}
				}
			}
		}

		&.logged-in {
			.menu-content {
				.btn-login__wrapper {
					@media (max-width: 992px) {
						flex-direction: column;
						align-items: flex-start;
						margin-bottom: 46px;
					}

					.btn-login {
						display: none;
					}

					.user-welcome {
						width: 100%;
						display: flex;
						flex-direction: row;
						align-items: flex-end;
						justify-content: space-between;

						@media (max-width: 992px) {
							display: block;
							padding-left: 0;
							max-width: 100%;
						}

						.greetings {
							width: calc(50% - 15px);

							@media (max-width: 992px) {
								width: 100%;
							}

							p {
								display: block;

								&:first-child {
									margin-bottom: 16px;

									@media (max-width: 992px) {
										margin-bottom: 38px;
										font-size: 24px;
										font-weight: 400;
									}
								}

								&.your-dashboard {
									line-height: 24px;

									@media (max-width: 992px) {
										display: none;
									}
								}
							}
						}

						.btn-logout {
							display: block;
							width: calc(50% - 15px);
							color: $white;
							text-align: left;
							font-size: 18px;

							i {
								width: 14px;
								height: 13px;
								margin-left: 10px;
							}

							@media (max-width: 992px) {
								display: none;
							}
						}

						ul {
							display: none;

							@media (max-width: 992px) {
								display: block;

								li {
									margin-bottom: 25px;
									line-height: 24px;

									&:last-child {
										margin-bottom: 0;
									}

									a {
										font-size: 18px;
										font-weight: 400;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&--login {
		padding: 0 0 100px;

		@media (max-width: 992px) {
			padding: 40px 0;
		}

		.login-form__wrapper {
			h3 {
				@media (max-width: 992px) {
					font-size: 36px;
					line-height: 46px;
				}
			}

			h4 {
				margin-top: 36px;
				font-size: 24px;
				line-height: 35px;

				@media (max-width: 992px) {
					margin-top: 12px;
					font-size: 16px;
					line-height: 24px;
				}
			}

			form.dynamic-label {
				margin-top: 70px;

				@media (max-width: 992px) {
					margin-top: 47px;
				}

				.button-group {
					margin-top: 46px;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					align-items: baseline;

					@media (max-width: 992px) {
						margin-bottom: 42px;
					}

					button[type="submit"] {
						margin-right: 25px;
						margin-bottom: 40px;

						@media (max-width: 992px) {
							margin-bottom: 30px;
						}
					}

					button.new-user {
						margin-right: 25px;
						margin-bottom: 40px;

						@media (max-width: 992px) {
							margin-bottom: 30px;
						}

						@media (max-width: 375px) {
							margin-right: 0px;
						}
					}

					button.go-back {
						margin-bottom: 40px;
						color: $white;
						font-size: 21px;
						line-height: 21px;
						font-weight: 400;

						&:hover {
							text-decoration: underline;
						}

						@media (max-width: 992px) {
							margin-right: 50px;
							margin-bottom: 0;
							font-size: 18px;
							line-height: 24px;
							order: 3;
						}
					}

					.btn-forgot-password {
						width: 100%;
						margin-bottom: 22px;
						color: $white;
						font-size: 21px;
						line-height: 21px;
						font-weight: 400;
						text-align: left;

						&:hover {
							text-decoration: underline;
						}

						@media (max-width: 992px) {
							width: inherit;
							margin-bottom: 0;
							font-size: 18px;
							line-height: 24px;
							order: 4;
						}
					}

					.error-message {
						@media (max-width: 992px) {
							width: 100%;
							margin-bottom: 30px;
							font-size: 14px;
							line-height: 18px;
							order: 2;
						}
					}
				}
			}
		}

		.partner-cta {
			.content {
				width: 86%;
				display: flex;
				align-items: flex-start;
				justify-content: center;
				flex-direction: column;

				@media (max-width: 992px) {
					width: 100%;
					padding: 24px 0;
					border-top: 1px solid $white;
					border-bottom: 1px solid $white;
				}

				.norwep-stamp {
					display: block;
					position: absolute;
					top: -100px;
					left: -90px;
					width: 167px;
					height: 167px;
					background-image: url("./../images/norwep-stamp.svg");
					z-index: 1;

					@media (max-width: 992px) {
						display: none;
					}
				}

				figure {
					margin-bottom: 25px;
					border-top-right-radius: 40px;
					border-bottom-right-radius: 40px;

					@media (max-width: 992px) {
						display: none;
					}

					img {
						border-top-right-radius: 40px;
						border-bottom-right-radius: 40px;
					}
				}

				h4 {
					color: $white;
					margin-bottom: 25px;

					@media (max-width: 992px) {
						margin-bottom: 8px;
						font-size: 24px;
						line-height: 34px;
					}
				}

				p {
					color: $white;
					font-size: 22px;
					line-height: 32px;
					font-weight: 400;


					@media (max-width: 992px) {
						font-size: 18px;
						line-height: 28px;
					}
				}

				a.arrow-link {
					margin-top: 25px;
					color: $white;

					@media (max-width: 992px) {
						margin-top: 8px;
						font-size: 14px;
						line-height: 18px;
					}

					i {

						@media (max-width: 992px) {
							min-width: 25px;
							width: 25px;
							height: 18px;
						}
					}
				}
			}
		}

		.forgotten-password__form {
			display: none;
		}

		&.forgotten-password {

			.login-form__wrapper,
			.partner-cta {
				display: none;
			}

			.forgotten-password__form {
				display: block;

				h3 {
					margin-bottom: 23px;
				}

				form.dynamic-label {
					margin-top: 87px;

					input[type="submit"] {
						margin-top: 37px;
					}

					.error-message {
						margin-top: 37px;
					}
				}
			}
		}
	}

	&--register-user {
		padding-top: 0;

		@media (max-width: 992px) {
			padding: 40px 0;
		}

		.hidden-menu__header {
			.logo__wrapper {
				margin-bottom: 135px;

				@media (max-width: 992px) {
					margin-bottom: 80px;
				}
			}
		}

		.register-user__instructions {
			@media (max-width: 992px) {
				margin-bottom: 48px;
			}

			h3 {
				margin-bottom: 30px;

				@media (max-width: 992px) {
					margin-bottom: 12px;
					font-size: 36px;
					line-height: 46px;
				}
			}

			p {
				max-width: 356px;
				margin-bottom: 40px;

				&:last-child {
					margin-bottom: 0;
				}

				@media (max-width: 992px) {
					max-width: 500px;
					margin-bottom: 24px;
					font-size: 16px;
					line-height: 24px;
				}
			}
		}
	}

	// Your Interests
	&--your-interests {
		h3 {
			margin-bottom: 35px;
		}

		p {}

		.norwep-form {
			margin-top: 25px;

			.submit__wrapper {
				margin-top: 45px;

				.error-message {
					color: $gold-dark;
				}
			}
		}
	}

	&--choose-domain,
	&--confirm-registration,
	&--account-created {
		padding-top: 0;
		bottom: 0;

		@media (max-width: 992px) {
			padding: 40px 0;
		}

		&.hidden-menu {
			.hidden-menu__header {
				.logo__wrapper {
					margin-bottom: 130px;

					@media (max-width: 992px) {
						margin-bottom: 50px;
					}
				}
			}
		}

		.choose-domain {
			&__ingress {
				@media (max-width: 992px) {
					margin-bottom: 46px;
				}

				h3 {
					@media (max-width: 992px) {
						font-size: 36px;
						line-height: 46px;
					}
				}
			}

			&__form {
				.form-title {
					margin-bottom: 50px;

					h6 {
						line-height: 1.3;
						font-weight: 400;

						@media (max-width: 992px) {
							font-size: 24px;
							line-height: 34px;
						}

						span {
							font-weight: 600;
						}
					}
				}

				.form-content {
					.dropdown {
						margin-bottom: 66px;

						a.dropdown-toggle {
							display: block;
							position: relative;
							width: 100%;
							background-color: transparent;
							border: none;
							border-bottom: 1px solid $green-light-2;
							border-radius: 0;
							text-align: right;

							&::after {
								content: '';
								position: relative;
								top: 0px;
								left: 0px;
								bottom: 0;
								width: 25px;
								height: 25px;
								background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.3125 9.375L12.5 17.1875L4.6875 9.375' stroke='white' stroke-width='1.5' stroke-linecap='square'/%3E%3C/svg%3E");
								border: none;
							}

							.dropdown-label {
								position: absolute;
								bottom: calc(26px - 8.25px);
							}
						}

						.dropdown-menu {
							position: absolute;
							transform: translate3d(0, 0, 0) !important;
							border-radius: 0;
							width: calc(100% + 8px);
							left: -4px !important;
							top: -8px !important;
							background-color: #fff;
							padding: 12px 0;
							transition: height 300ms ease-in-out;

							.dropdown-item {
								position: relative;
								padding: 12px 24px 12px 50px;

								&.active {
									color: inherit;
									background-color: inherit;

									&::before {
										position: absolute;
										content: '';
										top: calc(50% - 6px);
										left: calc((50px/2) - 6px);
										width: 12px;
										height: 12px;
										background-position: center;
										background-repeat: no-repeat;
										background-size: cover;
										background-image: url("data:image/svg+xml,%3Csvg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5.5' cy='5.92773' r='5.5' fill='url(%23paint0_linear_3714_57822)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3714_57822' x1='-6.98306e-08' y1='9.29244' x2='11' y2='1.85126' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%233D88B4'/%3E%3Cstop offset='1' stop-color='%238EE8BD'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");

									}
								}

								&:hover,
								&.active:hover {
									background-color: rgba($cyan, 0.1);
								}
							}
						}
					}

					.button-group {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						margin-bottom: 42px;

						margin-bottom: 30px;

						.btn {
							&--primary {
								@media (min-width: 993px) {
									font-size: 21px;
								}

								@media (max-width: 992px) {
									max-width: 60%;
								}
							}

							&.btn-cancel {
								padding: 0;
								color: #fff;
								margin-left: 40px;
								text-decoration: underline;
								font-size: 21px;
								font-weight: 300;

								@media (max-width: 992px) {
									font-size: 18px;
								}
							}
						}
					}
				}
			}
		}

		.confirm-registration {
			header {
				margin-bottom: 44px;

				h2 {
					&:first-child {
						color: $cyan;
					}

					@media (max-width: 992px) {
						font-size: 40px;
						line-height: 44px;
					}
				}
			}

			.instructions {
				p {
					&+p {
						margin-top: 42px;

						@media (max-width: 992px) {
							margin-top: 28px;
						}
					}
				}
			}
		}

		.account-created {
			header {
				margin-bottom: 57px;

				@media (max-width: 992px) {
					margin-bottom: 44px;
				}

				h2 {
					span {
						color: $cyan;
					}

					@media (max-width: 992px) {
						font-size: 40px;
						line-height: 44px;
					}
				}
			}

			.confirmation-text {
				margin-bottom: 84px;

				@media (max-width: 992px) {
					margin-bottom: 44px;
				}
			}

			.button-group {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				@media (max-width: 576px) {
					flex-direction: column;
					align-items: flex-start;
				}

				.btn {

					@media (max-width: 576px) {
						width: 100%;
					}

					&:not(:last-child) {
						margin-right: 56px;

						@media (max-width: 992px) {
							margin-right: 24px;
						}

						@media (max-width: 576px) {
							margin-right: 0;
							margin-bottom: 24px;
						}
					}
				}
			}
		}
	}
}

.overlay-background {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: black;
	opacity: 0.5;
	cursor: pointer;
	z-index: 10;
}
