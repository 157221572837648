.my-page{
    margin-top: 76px;
    margin-bottom: 100px;

    @media (max-width: 992px) {
        margin-top: 37px;
        margin-bottom: 90px;
    }

    .breadcrumbs__wrapper{
        @media (max-width: 992px) {
            display: none;
        }
    }

    .aside__wrapper{
        &::after{
            content: '';
            display: block;
            position: absolute;
            width: 1px;
            height: 100%;
            border-right: 1px solid #E2E4EA;
            top: 0;
            right: 16%;
    
            @media (max-width: 991px) {
                display: none;
            }
        }
    }

    &__header{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;

        @media (max-width: 992px) {
            margin-top: 0;
            flex-direction: column;
        }

        .title{
            width: 47%;
            margin-top: 60px;

            @media (max-width: 992px) {
                width: 100%;
                margin-top: 0;
            }

            h2{
                @media (max-width: 992px) {
                    font-size: 40px;
                    line-height: normal;
                }
            }
            
            a.company-page-link{
                margin-top: 35px;
                
                @media (max-width: 992px) {
                    margin-top: 20px;
                }
            }
        }

        figure.header-figure{
            width: 24%;
            text-align: right;

            @media (max-width: 992px) {
                width: inherit;
                max-height: 100px;
                margin-top: 34px;
                margin-bottom: 48px;
                text-align: left;
            }
            
            img{
                width: 100%;
                max-width: 100%;
                
                @media (max-width: 992px) {
                    width: inherit;
                    max-height: 100px;
                    max-width: 270px;
                }
            }
    
            a{
                display: inline-block;
                margin-top: 27px;
                text-decoration: underline;

                @media (max-width: 992px) {
                    display: block;
                }
            }
        }
    }

    .norwep-form{
        .submit__wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 56px;
            margin-bottom: 0;

            @media (max-width: 992px) {
                margin-top: 40px;
            }

            .cta-link.cancel{
                margin-left: 32px;
            }
        }
    }

    &__dashboard{
        .btn--add-tech{
            margin-top: 73px;

            @media (max-width: 992px) {
                margin-top: 48px;
                padding: 17px 26px;
            }

            i{
                width: 21px;
                min-width: 21px;
                height: 21px;
            }
        }

        .tech-solutions__wrapper{
            .row{
                .col-12{
                    >header{
                        margin-bottom: 55px;
                        padding-bottom: 25px;
                        border-bottom: 1px solid #E2E4EA;

                        @media (max-width: 992px) {
                            margin-bottom: 36px;
                            padding-bottom: 0;
                            border-bottom: none;
                        }
        
                        h6{
                            font-size: 24px;
                            line-height: 30px;
                            font-weight: 400;
                        }
                    }
                }

                .tech-solutions{
                    margin-bottom: 35px;
                }
            }
        }

        .see-more-link{
            padding-right: 0 !important;
            text-align: right;

            @media (max-width: 992px) {
                text-align: left;
                padding-left: 0 !important;
            }
        }
    }

    &__my-profile{
        .my-page__header{
            .title{
                @media (max-width: 992px) {
                    display: none;
                }
            }

            figure.header-figure{
                width: 165px;
                
                @media (max-width: 992px) {
                    max-height: inherit;
                    margin-top: 0px;
                    margin-bottom: 28px;
                }
                
                img{
                    @media (max-width: 992px) {
                        width: 120px;
                        height: 120px;
                        max-height: 120px;
                        margin-right: 18px;
                    }
                }

                a{
                    @media (max-width: 992px) {
                        display: inline-block;
                        vertical-align: bottom;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }

        .information-update{
            .information-update__subtitle{
                margin-top: 16px;
                font-size: 18px;
                line-height: 26px;
                font-weight: 400;

                @media (max-width: 992px) {
                    font-size: 16px;
                    line-height: 18.6px;
                }
            }

            h4{
                @media (max-width: 992px) {
                    font-size: 24px;
                    line-height: 33px;
                    letter-spacing: -0.36px;
                }
            }

            .norwep-form{
                margin-top: 30px;

                @media (max-width: 992px) {
                    margin-top: 20px;
                }
            }

            &__item{
                margin-bottom: 44px;
                padding-bottom: 64px;
                border-bottom: 1px solid $gray-light-3;

                @media (max-width: 992px) {
                    margin-bottom: 40px;
                    padding-bottom: 40px;
                }

                &:last-of-type{
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                }

                &.personal-details{
                    margin-top: 10px;

                    @media (max-width: 992px) {
                        margin-top: 0;
                    }
                }

                &.account-password{
                    .account-password__content{
                        .norwep-form{
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            
                            .input__wrapper{
                                width: 53%;

                                @media (max-width: 992px) {
                                    width: 100%;
                                }
                            }

                            .password-instructions{
                                width: 41%;
                                margin-top: 150px;
                                color: $gray-light-1;

                                @media (max-width: 992px) {
                                    width: 100%;
                                    margin-top: 15px;
                                }

                                .title{
                                    margin-bottom: 10px;
                                    padding-left: 30px;
                                    font-size: 18px;
                                    line-height: 18px;
                                    font-weight: 600;

                                    @media (max-width: 992px) {
                                        font-size: 16px;
                                        line-height: 24px;
                                    }
                                }
        
                                ul{
                                    li{
                                        padding-left: 30px;
                                        font-size: 18px;
                                        line-height: 30px;
                                        font-weight: 400;

                                        @media (max-width: 992px) {
                                            margin-bottom: 5px;
                                            font-size: 16px;
                                            line-height: 24px;
                                        }
        
                                        i{
                                            width: 16px;
                                            height: 12px;
                                            margin-right: 13px;
                                            margin-left: -30px;
                                        }
                                    }
                                }
                            }

                            .submit__wrapper{
                                width: 53%;
                                flex-wrap: wrap;

                                @media (max-width: 1440px) {
                                    width: 100%;
                                }

                                @media (max-width: 992px) {
                                    margin-top: 30px;
                                }

                                p{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                &.your-interests{
                    .norwep-form{
                        .input-group{
                            margin-bottom: 0;

                            @media (max-width: 992px) {
                                margin-bottom: 17px;
                            }
                            
                            &:last-child{
                                @media (max-width: 992px) {
                                    margin-bottom: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__company-profile{
        .my-page__header{
            .header-figure{
                a{
                    display: none;
                }
            }
        }

        .company-profile__item{
            .norwep-form{
                .input-group{
                    label{
                        margin-bottom: 10px;
                    }

                    input , textarea{
                        display: none;
                    }

                    p{
                        font-size: 24px;
                        line-height: 35px;
                        font-weight: 400;
                    }

                    &.submit__wrapper{
                        display: none;
                    }
                }
            }

            .norwep-form{
                margin-top: 30px;
            }

            &.company-details{
                margin-top: 42px;
                margin-bottom: 30px;
                padding-top: 65px;
                padding-bottom: 20px;
                border-top: 1px solid $gray-light-3;
                border-bottom: 1px solid $gray-light-3;
            }
        }

        .edit-buttons{
            display: flex;
            flex-direction: row;
            margin-top: 90px;

            flex-wrap: wrap;
            
            .btn{
                margin-right: 48px;
                margin-bottom: 30px;

                &:last-child{
                    margin-right: 0;
                }
            }
        }

        &.edit{
            .my-page__header{
                .header-figure{
                    a{
                        display: block;
                    }
                }
            }

            .company-profile__item{
                &.company-details{
                    margin-top: 10px;
                    padding-top: 0;
                    border-top: none;
                }

                .norwep-form{
                    .input-group{
                        label{
                            margin-bottom: 14px;
                        }

                        input , textarea{
                            display: block;
                        }

                        p{
                            display: none;
                        }
                    }

                    .submit__wrapper{
                        display: flex;
                    }
                }
            }

            .edit-buttons{
                display: none;
            }
        }
    }

    &__company-members{
        .my-page__header{
            margin-bottom: 44px;

            @media (max-width: 992px) {
                display: none;
            }

            .title{
                width: 100%;
            }
        }

        .invite-members{
            margin-bottom: 107px;

            @media (max-width: 992px) {
                margin-bottom: 90px;
            }
            
            h4{
                margin-bottom: 20px;

                @media (max-width: 992px) {
                    margin-bottom: 12px;
                    font-size: 24px;
                    line-height: 33px;
                }
            }

            p{
                font-size: 18px;
                line-height: 26px;
                font-weight: 400;

                @media (max-width: 992px) {
                    font-size: 15px;
                    line-height: 25px;
                }
            }

            .norwep-form{
                margin-top: 30px;

                @media (max-width: 992px) {
                    margin-top: 25px;
                }

                .input-group{
                    margin-bottom: 30px;
                }
            }
        }

        .company-members{
            margin-bottom: 107px;

            @media (max-width: 992px) {
                margin-bottom: 0px;
            }
            
            h4{
                margin-bottom: 40px;

                @media (max-width: 992px) {
                    margin-bottom: 24px;
                    font-size: 24px;
                    line-height: 33px;
                }
            }

            table{
                width: 100%;

                tr{
                    border-bottom: 1px solid $gray-light-3;
                }

                thead{
                    @media (max-width: 992px) {
                        display: none;
                    }

                    tr{
                        th{
                            padding-bottom: 15px;
                            padding-left: 15px;
                            color: $cyan-dark;
                            white-space: nowrap;
                            font-size: 18px;
                            line-height: 18px;
                            font-weight: 600;
                        }
                    }
                }

                tbody{
                    tr{
                        @media (max-width: 992px) {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            padding: 23px 30px 23px 0px;
                        }
    
                        &:first-child{
                            @media (max-width: 992px) {
                                border-top: 1px solid $gray-light-3;
                            }
                        }

                        &:hover{
                            background-color: rgba($cyan, .1);

                            @media (max-width: 992px) {
                                background-color: $white;
                            }
                        }

                        td{
                            padding: 14px 0px 9px 15px;
                            font-size: 22px;
                            font-weight: 400;

                            @media (max-width: 992px) {
                                padding: 0;
                                font-size: 14px;
                                line-height: 14px;
                            }

                            .tag{
                                padding: 4px 15px;
                            }

                            &.email{
                                @media (max-width: 992px) {
                                    display: flex;
                                    flex-direction: column-reverse;
                                    align-items: flex-start;
                                }

                                span{
                                    display: inline-block;
                                    margin-right: 24px;
                                    margin-bottom: 5px;

                                    @media (max-width: 992px) {
                                        margin-right: 0;
                                        margin-bottom: 0;
                                        font-weight: 700;
                                        margin-bottom: 9px;
                                    }
                                }

                                .tag{
                                    @media (max-width: 992px) {
                                        margin-bottom: 10px;
                                    }
                                }
                            }

                            &.action{
                                position: relative;
                                text-align: center;

                                @media (max-width: 992px) {
                                    position: absolute;
                                    right: 0;
                                    top: calc(50% - 17px);
                                }

                                button{
                                    height: 35px;

                                    i{
                                        width: 32px;
                                        background-position: center;

                                        @media (max-width: 992px) {
                                            width: 20px;
                                        }
                                    }
                                }

                                ul.popup-menu{
                                    @media (max-width: 992px) {
                                        position: fixed;
                                        top: 0px;
                                        bottom: 0;
                                        width: 100vw;
                                        border: none;
                                        box-shadow: none;
                                        background: transparent;
                                        overflow: auto;
                                        transition: transform .8s cubic-bezier(0.42, 0, 0.15, 1.4);
                                        background: rgba(255, 255, 255, 0.85);
                                        backdrop-filter: blur(10px);
                                        -webkit-backdrop-filter: blur(10px);
                                        opacity: 1;
                                        z-index: 10;

                                        // Animations keyframes in dropdown.scss
                                        animation-name: popup-slideUp;
                                        animation-duration: 0.5s;
                                    }

                                    &.popup-slideDown{
                                        // Animations keyframes in dropdown.scss
                                        animation-name: popup-slideDown;
                                        animation-duration: 0.4s;
                                    }

                                    .content{
                                        @media (max-width: 992px) {
                                            display: flex;
                                            flex-direction: column;
                                            margin-bottom: 50px;
                                            height: 120%;
                                            padding: 120px 30px 0px;
                                        }

                                        .member-info{
                                            display: none;
    
                                            @media (max-width: 992px) {
                                                display: block;
                                                padding-bottom: 32px;
                                                margin-bottom: 3px;
                                                border-bottom: 1px solid $gray-light-3;
                                            }
    
                                            p{
                                                font-size: 14px;
                                                line-height: 14px;
    
                                                &.email{
                                                    margin-top: 22px;
                                                    margin-bottom: 9px;
                                                    font-weight: 700;
                                                }
                                            }
                                        }
    
                                        li{
                                            a{
                                                i{
                                                    display: none;
                                                }
                                            }

                                            @media (max-width: 992px) {
                                                a{
                                                    justify-content: space-between;
                                                    padding: 20px 0;
                                                    border-bottom: 1px solid $gray-light-3;
                                                    font-size: 16px;
                                                    line-height: 20px;
    
                                                    &:hover{
                                                        background-color: transparent;
                                                    }

                                                    i{
                                                        display: block;
                                                        margin-left: 20px;
                                                    }
                                                }
                                            }
                                        }
    
                                        .btn-close{
                                            display: none;
    
                                            @media (max-width: 992px) {
                                                display: block;
                                                position: absolute;
                                                top: 66px;
                                                
                                                i{
                                                    vertical-align: bottom;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__technologies{
        .my-page__header{
            margin-bottom: 130px;
            align-items: flex-end;

            @media (max-width: 992px) {
                margin-top: 22px;
                margin-bottom: 54px;
            }

            .title{
                @media (max-width: 992px) {
                    display: none;
                }
            }

            button{
                margin-top: 30px;
                
                @media (max-width: 992px) {
                    margin: 0 auto;
                    padding: 17px 26px;
                    margin-top: 0;
                }

                i{
                    width: 21px;
                    min-width: 21px;
                    height: 21px;
                }
            }
        }

        .technologies__filter{
            @media (max-width: 992px) {
                display: none;
            }

            .filter-dropdown{
                margin-bottom: 60px;
            }
        }


        .filter-results{
            margin-bottom: 50px;

            @media (max-width: 992px) {
                display: none;
            }
            
            ul{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                li{
                    margin-right: 30px;
                    margin-bottom: 15px;
                    font-size: 18px;
                    font-weight: 600;

                    &:last-child{
                        margin-right: 0;
                    }

                    span{
                        margin-left: 4px;
                    }
                }
            }
        }
    }

    &__tech-info{
        .my-page__header{
            padding-bottom: 48px;
            margin-bottom: 56px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                margin-bottom: 30px;
                padding-bottom: 0;
                border-bottom: none;
            }

            .title{
                @media (max-width: 992px) {
                    order: 1;
                }

                h2{
                    @media (max-width: 992px) {
                        font-size: 24px;
                        line-height: 35px;
                        letter-spacing: -0.5px;
                        font-weight: 400;
                    }
                }
            }

            .header-figure{
                display: flex;
    
                @media (max-width: 992px) {
                    margin-top: 0;
                    margin-bottom: 23px;
                    max-height: 120px;
                }
    
                img{
                    max-height: 120px;
                    object-fit: contain;
                    margin-top: auto;
                }
            }
        }


        .tech-info__details{
            .norwep-form{
                .input-group{
                    @media (max-width: 992px) {
                        margin-bottom: 20px;
                    }

                    label{
                        margin-bottom: 10px;

                        @media (max-width: 992px) {
                            margin-bottom: 4px;
                        }
                    }

                    .filter-dropdown ,
                    input ,
                    textarea ,
                    .instructions
                    {
                        display: none;
                    }

                    p{
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 35px;

                        @media (max-width: 992px) {
                            font-size: 18px;
                            line-height: 28px;
                        }
                    }

                    &--name{
                        display: none;
                    }

                    &--description{
                        margin: 50px 0;

                        @media (max-width: 992px) {
                            margin: 32px 0;
                        }

                        .char-count{
                            display: none;
                        }
                    }

                    &--trl{
                        margin-bottom: 50px;

                        p.trl-desc{
                            margin-top: 10px;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 26px;
                        }
                    }

                    &--references{
                        ul{
                            li{
                                margin-bottom: 14px;

                                @media (max-width: 992px) {
                                    margin-bottom: 4px;
                                }

                                &:last-of-type{
                                    margin-bottom: 0;
                                }

                                a{
                                    font-size: 24px;
                                    font-weight: 400;
                                    text-decoration: underline;

                                    @media (max-width: 992px) {
                                        font-size: 18px;
                                        line-height: 28px;
                                    }
                                }
                            }
                        }

                        .edit-references{
                            display: none;
                        }

                        button{
                            &.add-reference{
                                display: none;
                            }
                        }
                    }
                }
            }

            .tech-info__buttons{
                margin-top: 90px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                @media (max-width: 992px) {
                    margin-top: 53px;
                    flex-direction: column;
                    align-items: flex-start;
                }
                
                button{
                    margin-right: 48px;
                    margin-bottom: 30px;

                    &:last-of-type{
                        margin-right: 0;

                        @media (max-width: 992px) {
                            margin-bottom: 0;
                        }
                    }

                    &.btn-save{
                        display: none;
                    }

                    &.btn-delete-tech{
                        display: none;
                    }
                }
            }

            .register-block{
                position: relative;
                margin-top: 19px;
                margin-bottom: 19px;
                padding: 25px 215px 22px 28px;
                background-color: transparentize($cyan, 0.9);
                line-height: 26px;

                @media (max-width: 992px) {
                    padding: 16px;
                    line-height: 24px;
                }

                span{
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 400;

                    @media (max-width: 992px) {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    &::after{
                        content: ">";
                        margin-left: 6px;
                        margin-right: 3px;
                    }

                    &:last-of-type{
                        &::after{
                            display: none;
                        }
                    }
                }

                .btn-close{
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: calc(50% - 12.5px);
                    right: 33px;
                    width: 25px;
                    height: 25px;

                    @media (max-width: 992px) {
                        top: 16px;
                        right: 12px;
                    }
                }
            }
        }

        &.edit{
            .breadcrumbs__wrapper{
                @media (max-width: 992px) {
                    display: block;
                    margin-top: 0;
                }
            }

            .my-page__header{
                padding-bottom: 0;
                margin-bottom: 47px;
                border-bottom: none;

                @media (max-width: 992px) {
                    margin-bottom: 30px;
                }

                .title{
                    width: 100%;
                }

                .header-figure{
                    display: none;
                }
            }

            .tech-info__details{
                .norwep-form{
                    .input-group{
                        margin-bottom: 32px;

                        @media (max-width: 992px) {
                            margin-bottom: 16px;
                        }

                        .filter-dropdown{
                            display: block;
                            margin-top: -10px;

                            .custom-select{
                                .selected-item{
                                    @media (min-width: 992px) {
                                        font-size: 24px;
                                        font-weight: 400;
                                        line-height: 24px;
                                    }

                                    color: $gray-light-2;

                                    @media (max-width: 992px) {
                                        font-size: 18px;
                                    }

                                    &.hasSelected{
                                        color: $gray;
                                    }
                                }
                            }
                        }

                        .filter-dropdown ,
                        input ,
                        textarea ,
                        .instructions
                        {
                            display: block;
                        }

                        .instructions{
                            p{
                                display: block;
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 26px;
                                
                                @media (max-width: 992px) {
                                    margin-bottom: 24px;
                                    font-size: 16px;
                                    line-height: 24px;
                                }

                                &:last-of-type{
                                    @media (max-width: 992px) {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                            
                            ol{
                                list-style: auto;
                                padding-left: 20px;

                                @media (max-width: 992px) {
                                    margin-top: 8px;
                                }

                                li{
                                    font-size: 18px;
                                    font-weight: 400;
                                    line-height: 26px;

                                    @media (max-width: 992px) {
                                        font-size: 16px;
                                        line-height: 24px;
                                    }
                                }
                            }
                        }

                        p{
                            display: none;
                        }

                        &--name{
                            display: block;

                            @media (max-width: 992px) {
                                margin-top: 44px;
                            }
                        }

                        &--description{
                            margin-bottom: 74px;

                            @media (max-width: 992px) {
                                margin-bottom: 63px;
                            }
                            
                            .instructions{
                                margin-bottom: 40px;

                                @media (max-width: 992px) {
                                    margin-bottom: 25px;
                                }
                            }

                            p.char-count{
                                display: block;
                                margin-top: 20px;
                                font-size: 18px;
                                line-height: 26px;
                                font-weight: 400;

                                @media (max-width: 992px) {
                                    margin-top: 11px;
                                    font-size: 16px;
                                    line-height: 24px;
                                }
                            }
                        }

                        &--trl{
                            margin-bottom: 82px;

                            @media (max-width: 992px) {
                                margin-bottom: 38px;
                            }

                            .instructions{

                            }

                            .filter-dropdown{
                                max-width: 242px;
                                margin-top: 25px;
                                margin-bottom: 22px;

                                @media (max-width: 768px) {
                                    max-width: 100%;
                                    margin-top: 30px;
                                }
                            }
                        }

                        &--references{
                            @media (max-width: 992px) {
                                padding-bottom: 32px;
                                border-bottom: 1px solid $gray-light-3;
                            }

                            ul.link-list{
                                display: none;
                            }

                            ul.edit-references{
                                display: block;
                                margin-top: 23px;
                                border-top: 1px solid $gray-light-3;

                                li{
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding-top: 14px;
                                    padding-bottom: 30px;
                                    border-bottom: 1px solid $gray-light-3;

                                    @media (max-width: 992px) {
                                        flex-direction: column;
                                        align-items: flex-start;
                                        justify-content: flex-start;
                                        margin-bottom: 0;
                                        padding-top: 16px;
                                        padding-bottom: 16px;
                                    }

                                    &:first-child{
                                        padding-top: 30px;

                                        @media (max-width: 992px) {
                                            padding-top: 16px;
                                        }
                                    }

                                    .reference{
                                        padding-right: 20px;
                                        
                                        @media (max-width: 992px) {
                                            padding-right: 0;
                                            line-height: 18px;
                                        }

                                        h6{
                                            font-size: 24px;
                                            font-weight: 400;
                                            
                                            @media (max-width: 992px) {
                                                margin-bottom: 8px;
                                                font-size: 18px;
                                                line-height: 28px;
                                            }
                                        }

                                        a{
                                            font-size: 18px;

                                            @media (max-width: 992px) {
                                                font-size: 14px;
                                                line-height: 18px;
                                                font-weight: 500;
                                            }
                                        }
                                    }

                                    .buttons{
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: nowrap;
                                        white-space: nowrap;
                                        padding-top: 30px;

                                        @media (max-width: 992px) {
                                            padding-top: 12px;
                                        }

                                        button{
                                            font-size: 18px;
                                            line-height: 24px;
                                            font-weight: 400;
                                            text-decoration: underline;

                                            @media (max-width: 992px) {
                                                font-size: 16px;
                                                line-height: 24px;
                                                font-weight: 600;
                                            }
                                            
                                            &.btn-edit{
                                                margin-right: 76px;

                                                @media (max-width: 992px) {
                                                    margin-right: 80px;
                                                }
                                            }
                                        }
                                    }

                                    &.add-reference-inputs{
                                        display: none;
                                        width: 100%;
                                        margin-top: 16px;
                                        border-bottom: none;

                                        @media (max-width: 992px) {
                                            margin-top: 20px;
                                            padding-bottom: 0;
                                        }

                                        .input-group{
                                            margin-bottom: 24px;
                                        }

                                        .button__wrapper{
                                            display: flex;
                                            flex-direction: row;
                                            margin-top: 40px;
                                            padding-top: 28px;
                                            border-top: 1px solid $gray-light-3;

                                            @media (max-width: 992px) {
                                                padding-top: 0;
                                                margin-top: 38px;
                                                border-top: none;
                                            }

                                            .btn.save-reference{
                                                @media (max-width: 992px) {
                                                    font-size: 17px;
                                                    padding: 12px 16px;
                                                }
                                            }

                                            .btn-cancel{
                                                margin-left: 38px;
                                                font-size: 18px;
                                                text-decoration: underline;

                                                @media (max-width: 992px) {
                                                    margin-left: 71px;
                                                }
                                            }
                                
                                            .error-message{
                                                color: $gold-dark;
                                            }
                                        }
                                    }
                                }
                            }

                            .add-reference{
                                display: flex;
                                margin-top: 30px;

                                @media (max-width: 992px) {
                                    margin-top: 32px;
                                    padding: 12px 16px;
                                    font-size: 17px;
                                }
                            }
                        }
                    }

                    .register-overview{
                        margin-bottom: 89px;

                        @media (max-width: 992px) {
                            margin-bottom: 44px;
                        }
                        
                        > p{
                            font-size: 18px;
                            line-height: 26px;
                            font-weight: 400;

                            @media (max-width: 992px) {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }

                        .register-block{
                            background-color: $green-light-3;
            
                            @media (max-width: 992px) {
                                padding: 16px 52px 16px 16px;
                            }
                        }

                        &__alert{
                            display: flex;
                            flex-direction: row;
                            padding-left: 28px;

                            @media (max-width: 992px) {
                                display: block;
                                padding-left: 0px;
                                font-size: 16px;
                                line-height: 24px;
                            }

                            p{
                                color: $red;
                                font-size: 18px;
                                line-height: 26px;
                                font-weight: 400;

                                @media (max-width: 992px) {
                                    font-size: 16px;
                                    line-height: 24px;
                                }
                            }

                            button{
                                color: $red;
                                font-size: 18px;
                                line-height: 26px;
                                font-weight: 400;
                                text-decoration: underline;

                                @media (max-width: 992px) {
                                    margin-top: 16px;
                                    margin-bottom: 16px;
                                    font-size: 16px;
                                    line-height: 24px;
                                }

                                &:first-of-type{
                                    margin-left: 41px;

                                    @media (max-width: 992px) {
                                        margin-left: 0;
                                    }
                                }

                                &:last-of-type{
                                    margin-left: 35px;
                                }
                            }
                        }
                    }
                }

                .tech-info__buttons{
                    margin-top: 150px;

                    @media (max-width: 992px) {
                        margin-top: 53px;
                    }

                    .btn-edit{
                        display: none;
                    }

                    .btn-save{
                        display: flex;
                    }
                }
            }
        }
    }
}