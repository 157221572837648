.contact{

    &--us{
        .contact__header{
            margin-top: 136px;
            margin-bottom: 112px;
            
            @media (max-width: 992px) {
                margin-top: 32px;
                margin-bottom: 32px;
                padding-bottom: 32px;
                border-bottom: 1px solid $gray-light-3;
            }

            h1{
                @media (max-width: 992px) {
                    font-size: 36px;
                    line-height: 46px;
                }
            }
        }
    
        .contact__locations{
            margin-bottom: 64px;

            @media (max-width: 992px) {
                margin-bottom: 24px;
            }
    
            .location__wrapper{
                display: flex;
                flex-direction: row;
                margin-bottom: 64px;

                @media (max-width: 992px) {
                    margin-bottom: 32px;
                }

                .location{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding-bottom: 38px;
                    border-bottom: 1px solid $gray-light-3;

                    .location__name{
                        padding-bottom: 40px;
                        margin-bottom: 44px;
                        border-bottom: 1px solid $gray-light-3;

                        @media (max-width: 992px) {
                            margin-bottom: 32px;
                            padding-bottom: 0;
                            border-bottom: none;
                            font-size: 24px;
                            line-height: 34px;
                        }

                        .arrow-link{
                            @media (max-width: 992px) {
                                font-size: 24px;
                                line-height: 34px;
                            }

                            .icon{
                                margin-left: 16px;

                                @media (max-width: 992px) {
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
        
                    .location__info{
                        margin-bottom: 50px;

                        @media (max-width: 992px) {
                            margin-bottom: 32px;
                        }
                    }

                    .arrow-link{
                        margin-top: auto;

                        @media (max-width: 992px) {
                            font-size: 18px;
                            line-height: 24px;
                        }
                        
                        i{
                            @media (max-width: 992px) {
                                min-width: 24px;
                                width: 24px;
                                height: 16px;
                            }
                        }
                    }
                }
            }
        }
    
        .worldwide-locations{
            &__header{
                margin-bottom: 56px;

                @media (max-width: 992px) {
                    margin-bottom: 27px;
                }

                h3{
                    @media (max-width: 992px) {
                        font-size: 24px;
                        line-height: 34px;
                    }
                }
            }
    
            &__filter{
                margin-bottom: 48px;

                @media (max-width: 992px) {
                    display: none;
                }
            }
    
            &__list{
                margin-bottom: 190px;
                column-gap: 60px;
                column-count: 4;
                
                @media (max-width: 1200px) {
                    column-count: 3;
                }
                
                @media (max-width: 992px) {
                    column-count: 2;
                    margin-bottom: 56px;
                }
                
                @media (max-width: 768px) {
                    column-count: 1;
                    column-gap: 0;
                }
    
                .item{
                    height: 100%;
                    padding: 24px 0;
                    border-bottom: 1px solid $gray-light-3;
                    break-inside: avoid-column;

                    @media (max-width: 992px) {
                        padding: 11px 0;
                    }
    
                    .arrow-link{
                        justify-content: space-between;
                        width: 100%;

                        @media (max-width: 992px) {
                            font-size: 18px;
                        }

                        i{
                            @media (max-width: 992px) {
                                min-width: 26px;
                                width: 26px;
                                height: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    &--office{
        .contact__header{
            margin-top: 136px;
            margin-bottom: 44px;
            padding-bottom: 83px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                margin-top: 32px;
                margin-bottom: 32px;
                padding-bottom: 32px;
                border-bottom: 1px solid $gray-light-3;
            }

            h1{
                @media (max-width: 992px) {
                    font-size: 36px;
                    line-height: 46px;
                }
            }
        }
        
        .office-info{
            margin-bottom: 93px;
            
            @media (max-width: 992px) {
                margin-bottom: 24px;
            }
            
            &__content{
                padding-bottom: 32px;
                border-bottom: 1px solid $gray-light-3;

                .location__info{
                    @media (max-width: 992px) {
                        margin-bottom: 32px;
                        padding-bottom: 40px;
                        border-bottom: 1px solid $gray-light-3;
                    }
                }
            }
        }

        .office-employees{
            margin-bottom: 86px;
            
            header{
                margin-bottom: 73px;

                @media (max-width: 992px) {
                    margin-bottom: 30px;
                }

                h3{
                    @media (max-width: 992px) {
                        font-size: 24px;
                        line-height: 34px;
                        letter-spacing: -0.5px;
                    }
                }
            }

            .employee-card{
                @media (max-width: 992px) {
                    max-width: 315px;
                    margin-bottom: 54px;
                }

                a{
                    .img__wrapper{
                        @media (max-width: 992px) {
                            margin-bottom: 24px;
                            max-width: 200px;
                        }
                    }

                    .text-content{
                        p{
                            @media (max-width: 992px) {
                                font-size: 16px;
                                line-height: 26px;
                            }

                            &.employee-name{
                                @media (max-width: 992px) {
                                    margin-bottom: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--employee{
        .employee__breadcrumbs{
            @media (max-width: 992px) {
                order: 1;
            }
        }

        .employee__content{
            @media (max-width: 992px) {
                order: 3;
            }
        }

        .contact__header{
            margin-top: 136px;
            padding-bottom: 50px;
            margin-bottom: 50px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                margin-top: 32px;
                margin-bottom: 24px;
                padding-bottom: 32px;
            }
            
            h1{
                margin-bottom: 47px;

                @media (max-width: 992px) {
                    margin-bottom: 24px;
                    font-size: 36px;
                    line-height: 46px;
                    letter-spacing: -0.5px;
                }
            }

            .role{
                max-width: 683px;

                @media (max-width: 992px) {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }

        .location__info{
            padding-bottom: 50px;
            margin-bottom: 70px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                margin-bottom: 32px;
                padding-bottom: 24px;
            }
        }

        .employee__bio{
            margin-bottom: 163px;

            @media (max-width: 992px) {
                margin-bottom: 100px;
            }

            .article__htmlarea{
                >p{
                    @media (max-width: 992px) {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }
        }

        .employee__photo{
            margin-top: 60px;

            @media (max-width: 992px) {
                order: 2;
                margin-top: 28px;
            }

            .img__wrapper{
                max-width: 505px;
                margin-left: auto;

                @media (max-width: 992px) {
                    max-width: 315px;
                    margin-left: 0;
                }

                figure{
                    img{
                        @media (max-width: 992px) {
                            border-top-right-radius: 40px;
                        }
                    }
                }
            }
        }
    }
}

ul.location__info{
    li{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        margin-bottom: 20px;
        padding-left: 40px;
        font-size: 24px;
        font-weight: 400;
        line-height: 120%;

        &:last-child{
            margin-bottom: 0;
        }

        @media (max-width: 992px) {
            margin-bottom: 10px;
            padding-left: 34px;
            font-size: 16px;
            line-height: 26px;
        }

        > i{
            position: absolute;
            top: 6px;
            left: 0;
            width: 22px;
            min-width: 22px;
            height: 22px;
            margin-right: 20px;

            @media (max-width: 992px) {
                width: 16px;
                min-width: 16px;
                height: 16px;
            }
        }
    }
}