.breadcrumbs__wrapper{
    margin-top: 24px;
    
    @media (max-width: 992px) {
        margin-bottom: 32px;
    }

    ol.breadcrumbs{
        display: flex;
        flex-direction: row;

        li{
            white-space: nowrap;

            &::after{
                content: "";
                display: inline-block;
                width: 6px;
                height: 10px;
                margin-left: 16px;
                margin-right: 16px;
                background-image: url("./../images/icons/chevron-right-gray.svg");
                background-size: contain;
                background-repeat: no-repeat;

                @media (max-width: 992px) {
                    margin-left: 12px;
                    margin-right: 12px;
                }
            }

            &:last-child{
                &::after{
                    display: none;
                }
            }

            a{
                span{
                    color: $gray-light-1;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500;

                    @media (max-width: 992px) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}