.pagination{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button{
    display: flex;
    align-items: center;

    i{
      width: 25px;
      height: 25px;
    }

    &.prev{
      margin-right: 12px;
    }

    &.next{
      margin-left: 12px;
    }
  }

  input{
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    border: 1px solid $gray-light-3;
    border-radius: 5px;

    // Remove arrows from input NUMBER
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
  }

  // Remove arrows from input NUMBER (Firefox)
  input[type=number] {
    -moz-appearance:textfield;
  }

  .total{
    display: flex;
    align-items: center;
    margin-left: 23px;
    font-size: 16px;
    font-weight: 400;

    span{
      margin-left: 5px;
    }
  }
}