// Macro
.cooperating-partners{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -1px;
    padding: 40px 0;
    border-top: 1px solid $gray-light-3;
    border-bottom: 1px solid $gray-light-3;

    @media (max-width: 992px) {
        padding: 24px 0;
    }

    .info{
        .partner-name{
            @media (max-width: 992px) {
                font-size: 24px;
                line-height: 34px;
            }
        }

        .partner-link{
            display: block;
            margin-top: 20px;
            font-size: 22px;

            @media (max-width: 992px) {
                margin-top: 18px;
                font-size: 16px;
                line-height: 26px;
                white-space: nowrap;
            }

            i{
                width: 22px;
                height: 22px;
                min-width: 22px;
                margin-right: 20px;
                vertical-align: middle;
                
                @media (max-width: 992px) {
                    width: 18px;
                    height: 18px;
                    min-width: 18px;
                    margin-right: 10px;
                }
            }
        }

        .p1 + .partner-link{
            margin-top: 6px;

            @media (max-width: 992px) {
                margin-top: 24px;
            }
        }

        .p1{
            @media (max-width: 992px) {
                margin-top: 4px;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    figure{
        margin-left: auto;
        padding-left: 20px;

        @media (max-width: 992px) {
            display: none;
        }

        img{
            width: 200px;
            max-width: 200px;
            min-width: 200px;
            max-height: 83px;
            object-fit: contain;
            object-position: right;
        }
    }
}