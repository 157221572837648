.share {
	display: flex;
	align-items: center;
	gap: 30px;
	min-width: 235px;

	&__container {
		position: relative;

		.share-button {
			cursor: pointer;
			display: block;
			width: 40px;
			height: 40px;
			background-image: url("./../images/icons/share-button.svg");
			background-size: cover;
			background-position: center;
			z-index: 1;

			&[aria-expanded='true'] {
				opacity: 0;
				z-index: 0;
			}

			&:focus-visible {
				outline: 2px solid black;
			}
		}

		.list-wrapper {
			position: absolute;
			opacity: 0;
			top: 0;
			left: 0;
			transition: opacity 275ms ease-in-out;

			&::before {
				content: '';
				position: absolute;
				border-radius: 40px;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: -1;
				background: $nordlys-ok;
			}

			&.open {
				opacity: 1;
			}
		}

		.share-list {
			position: relative;
			height: 0px;
			opacity: 0;
			z-index: 0;

			top: 0px;
			left: 0px;

			border: 2px solid transparent;
			display: flex;
			flex-direction: column;
			border-radius: 40px;
			overflow: hidden;
			transition: height 275ms ease-in-out, opacity 500ms linear;

			&.open {
				height: 148px;
				opacity: 1;
				z-index: 1;

				.item a {
					pointer-events: all;
				}
			}

			.item a {
				display: block;
				pointer-events: none;
				width: 24px;
				height: 24px;
				padding: 18px;
				background-position: center;
				background-size: 24px;
				background-repeat: no-repeat;
				background-color: #fff;

				&#twitter {
					background-image: url("./../images/icons/twitter-icon.svg");
				}

				&#facebook {
					background-image: url("./../images/icons/facebook-icon.svg");
				}

				&#linkedin {
					background-image: url("./../images/icons/linkedin-icon.svg");
				}

				&#link {
					background-image: url("./../images/icons/link-gradient-sm.svg");
				}
			}

			@media (max-width: 992px) {
				flex-direction: row;
				height: 40px;
				width: 0;
				transition: width 275ms ease-in-out, opacity 500ms linear;

				&.open {
					height: 40px;
					width: 228px;
				}

				.item {
					a {
						background-size: 32px;
						width: 32px;
						height: 32px;
						padding: 18px 28px;
					}
				}
			}
		}
	}
}
