.opportunities-part{
    @media (max-width: 992px) {
        margin-top: 54px;
    }

    .opportunities__header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;

        @media (max-width: 992px) {
            margin-bottom: 24px;
        }

        h4{
            @media (max-width: 992px) {
                padding-right: 20px;
                font-size: 22px;
                font-weight: 500;
                line-height: normal;
            }
        }

        .arrow-link{
            @media (max-width: 992px) {
                font-size: 14px;
            }

            i{
                @media (max-width: 992px) {
                    min-width: 16px;
                    width: 16px;
                    height: 18px;
                    background-image: url("./../images/icons/arrow-right-green.svg");
                }
            }
        }
    }

    .opportunities__slider{
        position: relative;
        margin-left: calc((100vw - 1680px + 260px)/2);
        max-width: calc(1680px + ((100vw - 1680px - 260px)/2));
        margin-bottom: 124px;

        @media (max-width: 1680px) {
            max-width: calc(100vw - 140px);
            margin-left: 140px;
        }

        @media (max-width: 1200px) {
            max-width: calc(100vw - 30px);
            margin-left: 30px;
        }

        @media (max-width: 992px) {
            margin-bottom: 80px;
        }

        .slick-list{
            padding:0 4% 0 0;
            
            @media (max-width: 768px) {
                padding:0 8% 0 0;
            }

            .slick-track{
                display: flex;
                flex-direction: row;
                margin: 0;
            }
        }

        .slide{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: auto;
            margin-right: 60px;

            @media (max-width: 992px) {
                margin-right: 20px;
            }

            &::after{
                content: "";
                display: block;
                width: 100%;
                height: 10px;
                margin-top: 40px;
                background: $grad-blue;

                @media (max-width: 992px) {
                    margin-top: 0;
                    height: 7px;
                }
            }

            ul.tags{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                
                @media (max-width: 992px) {
                    margin-bottom: 13px;
                    font-size: 11px;
                }
                
                li{
                    margin-right: 10px;
                    margin-bottom: 20px;

                    @media (max-width: 992px) {
                        margin-bottom: 10px;
                    }
                }
            }

            h6.opportunity-name{
                margin-bottom: 20px;
                hyphens: none;                        
                -moz-hyphens: none !important;
                -ms-hyphens: none !important;
                -webkit-hyphens: none !important;

                @media (max-width: 992px) {
                    margin-bottom: 13px;
                    font-size: 18px;
                    line-height: 21px;
                }
            }

            p{
                margin-bottom: 20px;
                font-size: 22px;
                font-weight: 400;
                line-height: 28px;

                @media (max-width: 992px) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            a.arrow-link{
                margin-top: auto;

                @media (max-width: 992px) {
                    margin-bottom: 30px;
                    font-size: 14px;

                    i{
                        @media (max-width: 992px) {
                            min-width: 18px;
                            width: 18px;
                            height: 18px;
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }

    &.notSlider{
        .opportunities__slider{
            display: flex;
            flex-direction: row;
            max-width: 1680px;
            padding-left: 140px;
            padding-right: 140px;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: 1200px) {
                padding-left: 30px;
                padding-right: 30px;
            }

            @media (max-width: 768px) {
                flex-direction: column;
            }

            .slide{
                width: 33%;

                @media (max-width: 1200px) {
                    margin-right: 20px;
                }

                @media (max-width: 768px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 38px;
                }

                &:last-child{
                    margin-right: 0;

                    @media (max-width: 768px) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &--image{
        .opportunities__header{
            flex-wrap: wrap;
            align-items: baseline;
            padding-bottom: 32px;
            border-bottom: 1px solid $gray-light-3;

            h4{
                width: 50%;

                @media (max-width: 992px) {
                    width: 70%;
                    padding-right: 20px;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: normal;
                    order: 1;
                }
            }

            p{
                width: 82%;
                margin-top: 40px;
                padding-right: 20px;

                @media (max-width: 992px) {
                    order: 3;
                    width: 100%;
                    margin-top: 25px;
                    font-size: 16px;
                    line-height: 26px;
                }
            }
                        
            .arrow-link{
                @media (max-width: 992px) {
                    width: 30%;
                    justify-content: flex-end;
                    order: 2;
                }
            }
        }

        .opportunities__slider{
            .slide{
                .img__wrapper{
                    width: 100%;
                    margin-bottom: 50px;

                    @media (max-width: 992px) {
                        margin-bottom: 24px;
                    }

                    figure{
                        img{
                            border-radius: 0 20px 20px 0;
                        }
                    }
                }
            }
        }
    }
}