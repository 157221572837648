.pdf-download-btn {
	// margin-top: 40px;
	// margin-bottom: 40px;
	width: 100%;
	position: relative;
	z-index: 0;

	button {
		position: relative;
		color: $gray;
		background-color: $green-light-1;
		border-radius: 40px;
		padding: 17px;
		z-index: 1;
		overflow: hidden;
		width: 100%;

		&::before {
			content: '';
			display: block;
			background-color: $green-light-1;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 100%;
			z-index: -1;

			transition: right 3000ms linear;
		}

		&.downloading {
			background-color: $green-light-3;

			&::before {
				display: block;
				right: 10%;
			}
		}

		&.download-complete {
			background-color: $green-light-1;

			&::before {
				display: none;
			}
		}
	}
}
