// Technologies and solutions landing page
.technologies-solutions-all{
    .page__header{
        margin-top: 136px;
        margin-bottom: 136px;

        @media (max-width: 992px) {
            margin-top: 0;
            margin-bottom: 64px;
        }

        h1{
            margin-bottom: 58px;

            @media (max-width: 992px) {
                margin-bottom: 32px;
                font-size: 36px;
                line-height: 46px;
            }
        }

        .ingress{
            margin-bottom: 60px;

            @media (max-width: 992px) {
                margin-bottom: 35px;
            }
        }
    }

    .tech-list{
		margin-bottom: 183px;

        @media (max-width: 992px) {
            margin-bottom: 100px;
        }
        
		&__item {
			display: flex;
			align-items: flex-start;
			padding-block: 54px;
			border-bottom: 1px solid #C4C4C4;

            @media (max-width: 992px) {
				padding-block: 30px;
            }

			.tech-image {
				width: 17.4%;

                @media (max-width: 992px) {
					width: 21.2%;
                }

				@media (max-width: 425px) {
					width: 29.2%;
            }

            figure{
					height: 150px;

					@media (max-width: 768px) {
						height: 90px;
                }

                img{
						height: 100%;
						width: auto;
					}
                }
            }

			.tech-text {
				width: 72.1%;

                h4{
					margin-bottom: 24px;

                    @media (max-width: 992px) {
                        margin-bottom: 12px;
					}

					@media (max-width: 768px) {
                        font-size: 24px;
                        line-height: 35px;
                    }
                }

                p{
					@media (max-width: 768px) {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }

				a:not(.mobile) {
					display: inline-block;
					font-size: 25px;
					line-height: 29px;
					margin-top: 24px;

                    @media (max-width: 992px) {
						margin-top: 12px;
					}

					@media (max-width: 768px) {
						display: none;
					}

					i {
						position: relative;
						margin-left: 15px;
						top: 4px;
						transition: 200ms transform ease-in-out;
					}

					&:hover {
						i {
							transform: translateX(10px);
						}
					}
				}

				&>a.mobile {

					&>i.icon {
						display: none;

						@media (max-width: 768px) {
							display: inline-block;
							margin-top: 12px;
							transition: 200ms transform ease-in-out;
						}
					}

					&:hover {
						i {
							transform: translateX(10px);
						}
                    }
                }
            }
        }
    }
}

.tag-header{
    position: relative;

    h2{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (max-width: 992px) {
            align-items: flex-start;
            font-size: 24px;
            line-height: 35px;
            letter-spacing: -0.5px;
        }

        span{
            padding-right: 30px;

            @media (max-width: 992px) {
                padding-right: 20px;
                margin-bottom: 12px;
            }
        }

        .tag{
            margin-top: 16px;
            margin-bottom: auto;
            letter-spacing: normal;

            @media (max-width: 992px) {
                margin-top: 4px;
                font-size: 13px;
                padding: 5px 15px;
            }
        }
    }
}

// Find Technology and Solution
.find-tech-page{
    margin-bottom: 185px;

    @media (max-width: 992px) {
        margin-bottom: 80px;
    }

    .page__header{
        margin-top: 136px;
        margin-bottom: 95px;

        @media (max-width: 992px) {
            margin-top: 0px;
            margin-bottom: 32px;
            padding-bottom: 32px;
            border-bottom: 1px solid $gray-light-3;
        }
    
        h1{
            margin-bottom: 80px;

            @media (max-width: 992px) {
                margin-bottom: 32px;
                font-size: 36px;
                line-height: 46px;
            }
        }
    }

    .filter__wrapper{
        .filter-result{
			// margin-top: 40px;

            @media (max-width: 992px) {
                display: none;
            }

            p{
                color: $gray-light-1;
                font-size: 28px;
                line-height: 32px;
            }
        }

        .filter-dropdown{
            .custom-select{
                .all-items{
                    width: 100%;
                }
            }
        }

        .filter-view{
			// margin-top: 40px;
			// margin-bottom: 80px;
		}

		.pdf-button-mobile {
			@media (min-width: 993px) {
				display: none;
			}

			margin-top: 45px;
		}

		.result-wrapper {
			align-items: center;
            margin-top: 40px;
            margin-bottom: 80px;

			@media (max-width: 992px) {
				display: none;
			}

			// & > * {

			// 	@media (min-width: 1201px) {
			// 		&:first-child {
			// 			padding-left: 0 !important;
			// 		}
			// 		&:last-child {
			// 			padding-right: 0 !important;
			// 		}
			// 	}
			// }
        }
    }
}

// Technology and Solutions Area
.tech-area-page{
    margin-bottom: 185px;

    @media (max-width: 992px) {
        margin-bottom: 80px;
    }

    .page__header{
        margin-top: 136px;
        margin-bottom: 64px;

        @media (max-width: 992px) {
            margin-top: 0px;
            margin-bottom: 32px;
        }
    
        h1{
            padding-bottom: 80px;

            @media (max-width: 992px) {
                padding-bottom: 40px;
                font-size: 36px;
                line-height: 46px;
                letter-spacing: -0.5px;
            }
        }

        .ingress{
            padding-top: 40px;
            border-top: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                padding-top: 32px;
            }
        }
    }

    .partner-capabilities{
        margin-bottom: 94px;

        @media (max-width: 992px) {
            margin-bottom: 40px;
        }

        .accordion-group{
            .accordion{
                padding-top: 95px;
                padding-bottom: 95px;

                @media (max-width: 992px) {
                    padding: 32px 0;
                }

                >button{
                    top: 105px;

                    @media (max-width: 992px) {
                        top: 38px;
                    }
                }
                
                .accordion__content{
                    .tag-header{
                        .close-btn{
                            position: absolute;
                            top: 0;
                            right: 0;
            
                            i{
                                width: 48px;
                                height: 48px;
                            }
                        }
                    }
                }

                .hidden-content{
                    margin-top: 78px;

                    @media (max-width: 992px) {
                        margin-top: 40px;
                    }
                }
            }
        }
    }

    .tech-filter__header{
        h2{
            margin-bottom: 55px;
        }

        .search-form__wrapper{
            margin-bottom: 55px;
        }
    }

    .filter__wrapper{
        .filter-result{
            margin-top: 40px;

            @media (max-width: 992px) {
                display: none;
            }

            p{
                color: $gray-light-1;
                font-size: 28px;
                line-height: 32px;
            }
        }

				.filter-download {
					margin-top: 40px;

					@media (max-width: 992px) {
							display: none;
					}
				}

        .filter-view{
            margin-top: 40px;
            margin-bottom: 80px;
        }

				.pdf-button-mobile {
					@media (min-width: 993px) {
						display: none;
					}
				}
    }

    .technologies__cards{
        margin-bottom: 137px;

        @media (max-width: 992px) {
            margin-bottom: 64px;
        }
    }

    .related-capabilities{
        .tag-header{
            margin-bottom: 60px;
        }

        .accordion-group{
            .accordion{
                @media (max-width: 992px) {
                    padding: 32px 0;
                }

                > button{
                    @media (max-width: 992px) {
                        top: 38px;
                    }
                }

                &__content{
                    .text{
                        h5{
                            @media (max-width: 992px) {
                                font-size: 24px;
                                line-height: 35px;
                                letter-spacing: -0.5px;
                            }
                        }
                    }
                }

                .hidden-content{
                    .content-line{
                        margin-bottom: 37px;

                        &:last-child{
                            margin-bottom: 0;
                        }
    
                        .download-button{
                            display: flex;
                            justify-content: flex-end;

                            @media (max-width: 992px) {
                                margin-top: 32px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Technology and Solutions PAGE
.tech-solutions-page{
    margin-bottom: 146px;

    @media (max-width: 992px) {
        margin-bottom: 80px;
    }

    .page__header{
        margin-top: 136px;
        margin-bottom: 100px;

        @media (max-width: 992px) {
            margin-top: 24px;
            margin-bottom: 73px;
        }
    
        h1{
            margin-bottom: 50px;

            @media (max-width: 992px) {
                font-size: 36px;
                line-height: 46px;
                letter-spacing: -0.5px;
            }
        }

        .tags{
            margin-bottom: 60px;

            @media (max-width: 992px) {
                margin-bottom: 40px;
            }

            .tag{
                margin-right: 48px;
                margin-bottom: 10px;
            }
        }

        .trl{
            p{
                color: $gray-light-1;
                font-size: 16px;
                line-height: 24px;
                text-align: right;

                @media (max-width: 992px) {
                    text-align: left;
                }
            }
  
            .rangeslider{
                height: 4px;
                margin-top: 24px;
                box-shadow: none;
                background-color: $so-so-gray;
                pointer-events: none;

                @media (max-width: 992px) {
                    margin-top: 36px;
                }
        
                .rangeslider__fill{
                    background: linear-gradient(146.95deg, #3D88B4 14.21%, #4BE29A 86.87%);
                }
        
                .rangeslider__handle{
                    top: -22px;
                    width: 44px;
                    height: 44px;
                    box-shadow: none;
                    border: none;
                    background: linear-gradient(146.95deg, #3D88B4 14.21%, #4BE29A 86.87%);
        
                    .val{
                        margin-top: 8px;
                        margin-right: 2px;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 28px;
                        text-align: center;
                        color: $white;
                    }
        
                    &::after{
                        display: none;
                    }
                }
            }
        }
    }

    &__content{
        margin-bottom: 130px;

        @media (max-width: 992px) {
            margin-bottom: 92px;
        }

        .article__htmlarea{
            > h3{
                margin-bottom: 48px;
                
                @media (max-width: 992px) {
                    margin-bottom: 12px;
                }
            }
        }

        .aside-info{
            width: 89%;
            margin-left: auto;
            padding-left: 60px;
            border-left: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                width: 100%;
                margin-top: 8px;
                padding-top: 33px;
                padding-left: 0;
                border-left: none;
                border-top: 1px solid $gray-light-3;
            }

            .logo{
                margin-bottom: 70px;
                text-align: center;

                @media (max-width: 992px) {
                    margin-bottom: 44px;
                    text-align: left;
                }

                img{
                    width: 100%;
                    max-height: 180px;
                    max-width: 300px;
                    object-fit: contain;

                    @media (max-width: 992px) {
                        max-width: 200px;
                        max-height: 150px;
                    }
                }
            }

            .contact-card{
                margin-bottom: 50px;
            }

            .tags{
                margin-bottom: 47px;

                h6{
                    margin-bottom: 24px;

                    @media (max-width: 992px) {
                        font-size: 24px;
                        line-height: 35px;
                        letter-spacing: -0.5px;
                    }
                }

                .tag{
                    margin-right: 10px;
                    margin-bottom: 10px;
                    padding: 10px 20px;
                }
            }

            .references{
                display: flex;
                flex-direction: column;

                h6{
                    margin-bottom: 24px;

                    @media (max-width: 992px) {
                        font-size: 24px;
                        line-height: 35px;
                        letter-spacing: -0.5px;
                    }
                }

                .arrow-link{
                    margin-bottom: 16px;
                    display: inline;

                    @media (max-width: 992px) {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .icon{
                        display: inline-block;
                    }
                }
            }
        }
    }

    .other-techs{
        &__header{
            display: flex;
            flex-direction: row;
            align-items: baseline;
            margin-bottom: 67px;
            padding-bottom: 38px;
            border-bottom: 1px solid $gray-light-3;

            @media (max-width: 992px) {
                margin-bottom: 30px;
                padding-bottom: 30px;
            }
            
            h3{
                padding-right: 30px;

                @media (max-width: 992px) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            .arrow-link{
                margin-left: auto;
                white-space: nowrap;

                @media (max-width: 992px) {
                    font-size: 14px;
                }

                .icon{
                    @media (max-width: 992px) {
                        width: 22px;
                        min-width: 22px;
                        height: 22px;
                        background-image: url('./../images/icons/arrow-right-green-sm.svg');
                    }
                }
            }
        }
    }
}
