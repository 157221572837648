.search-banner-part{
  margin-top: 20px;
  margin-bottom: 96px;

  @media (max-width: 992px) {
    margin-bottom: 53px;
  }

  .content{
    position: relative;
    padding: 140px 70px 108px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    background-image: url('./../images/image-2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media (max-width: 992px) {
      padding: 64px 20px 47px 24px;
    }

    &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(97.77deg, rgba(10, 49, 85, 0.3) 34.59%, rgba(0, 0, 0, 0) 90.66%);
      mix-blend-mode: normal;
      border-radius: 0px 40px 40px 0px;
      z-index: 0;
    }

    video{
      display: none;
    }

    h1{
      position: relative;
      max-width: 973px;
      margin-bottom: 83px;
      color: $white;
      hyphens: none;                        
      -moz-hyphens: none !important;
      -ms-hyphens: none !important;
      -webkit-hyphens: none !important;

      @media (max-width: 992px) {
        max-width: 530px;
        margin-bottom: 38px;
        font-size: 40px;
      }
    }
    
    form{
      max-width: 753px;
    }
  }

  &--video{
    @media (min-width: 992px) {
      .content{
        background-image: none;
        overflow: hidden;
  
        video{
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.filled-fade-in{
  background-color: transparent;
  animation: filled-fade-in 1.2s;
  animation-fill-mode: forwards;

  img , video{
    opacity: 0;
    animation: simple-fade-in 2s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards !important;
  }
}

@keyframes filled-fade-in{
  0%{
    background-color: transparent;
    opacity: 0;
  }
  15%{
    background-color: $gray-light-2;
  }
  100%{
    opacity: 1;
  }
}