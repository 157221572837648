.employee-card{
    margin-bottom: 80px;
    a{
        display: block;
        
        .img__wrapper{
            margin-bottom: 30px;
    
            figure{
                img{
                    border-top-right-radius: 40px;
                }
            }
        }
    
        .text-content{
            p{
                font-size: 18px;
                line-height: 24px;
                font-weight: 400;
    
                &.employee-name{
                    font-weight: 600;
                }
    
                &.employee-role{
                    margin-bottom: 15px;
                }
            }
    
    
        }
    }

}